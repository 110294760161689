import { Route } from "react-router-dom";
import React from 'react';
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import App from "../../../App";

let  PrivateRoute = ({ children, ...props }) => {
    const {user, auth} = props;
    const isAuth = () => {
     
        return auth(user);
    }
    return (
        <Route
            {...props}
            render={({ location }) =>
                isAuth() ? (
                    children
                ) : (
                    <Redirect to={{
                        pathname: '/login',
                        state: {from: location}
                    }}  />
                )
            }
        />
    );
}

const mapStateToProps = (state) => {
    return {user: state.user}
}


PrivateRoute = connect(mapStateToProps,null)(PrivateRoute);

export default PrivateRoute;
