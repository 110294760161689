import {CLOSE_ALERT, CLOSE_DIALOG, OPEN_ALERT} from "../../../types/types";


export const closeAlertAction = (id) => {
    return {
        type: CLOSE_ALERT,
        payload: {
            id
        }
    }
}
export const openAlertAction = (payload) => {
    if (payload.id === undefined) {
        payload.id = JSON.stringify(payload);
    }
    return {
        type: OPEN_ALERT,
        payload
    }
}
