import React from "react";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { FormGroup } from "@mui/material";

export default function CheckboxField({
                                          label, arrayindex, index,checkedValue=() => true, id, uncheckedValue = () => false,
                                          field: {name, onChange, onBlur},
                                          form:{values, initialValues, errors, touched, setFieldValue}, ...props}) {
    const getValue2 = () => {
        if (arrayindex) {
            const res = values[arrayindex][index];
            if (res) {
                return (res.privacyClauseValue.value !== "Off");
            }
            return false;
        }
        
        return values[name];
    }

    const getClausulaKauto = () => {
        if(name == "clauses[25]"){
            return (
                <div>
                {label}
                <a href="https://www.iberdrola.es/informacion/politica-privacidad">https://www.iberdrola.es/informacion/politica-privacidad</a>
                </div>
            );
        }
    }
  
    return (
        <FormGroup>
            <FormControlLabel label={label}  control={<Checkbox name={name} checked={getValue2()} onChange={(e, value) => {
                    if (value === true) {
                        setFieldValue(name, checkedValue());
                        return;
                    }
                    setFieldValue(name, uncheckedValue());
                }
            }/> } />
            
        </FormGroup>

        )
}
