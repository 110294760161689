import React, {useContext} from "react";
import "./Header.scss";
import {connect} from "react-redux";
import {Icon} from "../../comun/Icon/Icon";
import {menuHideDispatcher, menuShowDispatcher} from "../../../dispatcher/commonDispatcher";
import {getBase64ImageOrDefaultUrl} from "../../../util/utilCampaign";
import {CampaignContext} from "../../comun/CampaignStyleContext/CampaignStyleContext";

let Header = ({campaign, ...props})  => {
    const clickMenu = () => {
        props.onClickMenu(props.menuOpened);
    }
    const theme = useContext(CampaignContext);
    if(campaign.name === "KonectaDental"){
        return (
            <header className="header"></header>
        )
    } else{
        return (
            <header className="header">
                <Icon className="header-icon" onClick={clickMenu}>menu</Icon>
            <img src={getBase64ImageOrDefaultUrl('logo', campaign, process.env.PUBLIC_URL + "/logo.png")}
                className="header-logo"  alt="Logo KAUTO Ibedrola FL"/>
            </header>
        )
    }
}

const mapPropsToState = state => {
    if (state.app.menu !== undefined) {
        return {menuOpened: state.app.menu.open, campaign: state.campaign}
    }
    return {
        menuOpened:false,
        campaign: state.campaign
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onClickMenu: (status) => {
            if (status === true) {
                dispatch(menuHideDispatcher({}));
                return;
            }

            dispatch(menuShowDispatcher({}));
        },

    }
}

Header = connect(mapPropsToState, mapDispatchToProps)(Header);


export default Header;
