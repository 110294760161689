import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import {configureStore} from "./configureStore";
import {BrowserRouter} from "react-router-dom";
import axios from "axios";
import {ResponseException} from "./exceptions/ResponseException";
import {CampaignStyleContext} from "./components/comun/CampaignStyleContext/CampaignStyleContext";

const store = configureStore();

axios.interceptors.response.use((response) => {
    if (response.data) {
        if (response.data.code > 299) {
            throw new ResponseException(response.data.code, response.data.data, response.data.errorMessage);
        }
    }
    return response;
}, function (error) {
    return Promise.reject(error.response);
});
ReactDOM.render(
  <Provider store={store}>
      <BrowserRouter>
          <CampaignStyleContext>
          <App  />
          </CampaignStyleContext>
      </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
