import {loadingDispatcher} from "../../comun/Loading/loadingAction";
import axios from "axios";
import {userAction} from "../Users/UserAction";
import {openAlertAction} from "../../comun/AlertMessage/AlertAction";
import {CREATE_LEAD, EDIT_CITY, LOAD_CITIES} from "../../../types/types";
import {envLoader} from "../../../env/envLoader";


const baseUrl = envLoader().base_url;
export const loadCityAction = values => {
    return {
        type: LOAD_CITIES,
        payload: values
    }
}


export const createCityAction = values => {
    return {
        type: LOAD_CITIES,
        payload: values
    }
}

export const editCityAction = values => {
    return {
        type: EDIT_CITY,
        payload: values
    }
}

export const filterCities = async(values) => {
    return await axios.post(`${baseUrl}city/filter`, values).then(res => res.data.data);
}

export const filterMisAcciones = async(values) => {
    return await axios.post(`${baseUrl}city/filter2`, values).then(res => res.data.data);
}

export const getCities = async (values) => {
    return async (dispatch) =>  {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
        return await filterCities(values)
            .then( response => {
                dispatch(loadCityAction(response));
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));
            }).catch(reason => {
                console.log(reason);
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true}));
                dispatch(openAlertAction({
                    id: 'error-register-lead', severity: 'error',
                    message: "Hubo un error al intentar cargar las ciudades, por favor inténtalo de nuevo", title:'Error'
                }));
            })
    }
}

export const createCity = async (values) => {
    return async (dispatch) =>  {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
        return await axios.post(`${baseUrl}city/create`, values)
            .then( async response => {
                dispatch(createCityAction(response.data.data));
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));
                await (await getCities({page: 0, size: 10}))(dispatch);
            }).catch(reason => {
                console.log(reason);
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true}));
                dispatch(openAlertAction({
                    id: 'error-register-lead', severity: 'error',
                    message: "Hubo un error al intentar crear la ciudad", title:'Error'
                }));
            })
    }
}
export const editCity = async (values) => {
    return async (dispatch) =>  {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
        return await axios.post(`${baseUrl}city/edit`, values)
            .then( async response => {
                dispatch(editCityAction(response.data.data));
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));
                await (await getCities({page: 0, size: 10}))(dispatch);
            }).catch(reason => {
                console.log(reason);
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true}));
                dispatch(openAlertAction({
                    id: 'error-register-lead', severity: 'error',
                    message: "Hubo un error al intentar crear la ciudad", title:'Error'
                }));
            })
    }
}
export const deleteCity = (id) => {
    return async (dispatch) =>  {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
        return await axios.get(`${baseUrl}city/${id}/delete`)
            .then( async response => {
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));
                await (await getCities({page: 0, size: 10}))(dispatch);
            }).catch(reason => {
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true}));
                dispatch(openAlertAction({
                    id: 'error-delete-city', severity: 'error',
                    message: "Hubo un error al intentar borrar la ciudad", title:'Error'
                }));
            })
    }
}

export const assignTeamLeaderCity = (values) => {
    return async (dispatch) =>  {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
        return await axios.get(`${baseUrl}city/${values.city}/assign-team-leader/${values.user}`).then(res => res.data.data)
            .then( response => {
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));
                return response;
            }).catch(reason => {
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true}));
                dispatch(openAlertAction({
                    id: 'error-asign-city-user', severity: 'error',
                    message: "Hubo un error al intentar asignar el jefe de equipo a la ciudad", title:'Error'
                }));
                throw reason;
            })
    }
}
