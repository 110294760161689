import enviromentDev from './env.dev.json';
import enviromentProd from './env.prod.json';
import enviromentPreProd from './env.preprod.json';
export const envLoader = () => {
    const env = process.env.REACT_APP_STAGE;
    if (env === 'dev') {
        return enviromentDev;
    }
    if (env === 'preprod') {
        return enviromentPreProd;
    }
    if (env === 'prod') {
        return enviromentProd;
    }
    return enviromentDev;
}
