import React from "react";
import Button from "@material-ui/core/Button";
import { verifyCardbonoImage } from "../LeadAction";
import imageCompression from 'browser-image-compression';


export default ({form: {values, setFieldValue}, variant = 'outlined', label, errorFunction, field: {onChange, name, onBlur}, ...custom}) => {

    const handleImageChange = async (e) => {
        if(e.target.files.length !== 0){
            errorFunction("", true)
            let valor = e.target.files[0]
            console.log(valor)
            console.log(valor.size )
            const fileReader = new FileReader();
            console.log(valor.size /1024 /1024)
            if(valor.size /1024 /1024 > 1){
                const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true
                }
                valor = await imageCompression(valor, options);
                
            }

            fileReader.onload = () => {
                if (fileReader.readyState === 2) {    

                    let rawBase64 = fileReader.result.split(",")[1]

                    const str = new Buffer.from(rawBase64, 'binary').toString('base64');
                    var myblob = new Blob([str], {
                        type: 'text/plain'
                    }); 
                    verifyCardbonoImage(myblob).then( (data) =>{
                        if (data.length === undefined | data.length === 0){
                            errorFunction("La imagen no se procesó correctamente, se debe enfocar el código desde cerca", false)
                        }else{
                            setFieldValue(name, data)
                            errorFunction("", false)
                        }
                    }).catch(error => errorFunction(error, false));
                }
            };
            fileReader.readAsDataURL(valor);
        }else{
            setFieldValue(name, "")
            errorFunction("", false)
        }
    }   

    return (
        <Button
            variant='contained'
            component='label'>
            <input
                name='button'
                accept='image/*;capture=camera'
                id='contained-button-file'
                type='file'
                onChange={handleImageChange}
            />
        </Button>
    )

}
