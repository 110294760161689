import React, {useState} from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import {connect} from "react-redux";
import {Field, withFormik} from "formik";
import * as Yup from "yup";
import {createZone, editZone} from "../ZoneAction";
import DialogContent from "@material-ui/core/DialogContent";
import MaterialTextField from "../../../comun/Forms/MaterialTextField";
import Button from "@material-ui/core/Button";
import "./CreateZone.scss";
import {closeDialogAction} from "../../../comun/DialogManager/DialogManagerAction";
import Spinner from "../../../comun/Spinner/Spinner";
import {AutocompleteUser} from "../../../comun/AutocompleteUser/AutocompleteUser";
import {filterTeamLeaders} from "../../TeamLeader/TeamLeaderAction";
import {getRoleForm, hasRole} from "../../../../auth/util";
import { Create } from "@mui/icons-material";



export let CreateZone = ({setFieldValue, user, isSubmitting, handleSubmit, dialogData, isValid, ...props}) => {
    const [loaded, setLoaded] = useState(0);
    const loadParams = () => {
        const loadFilter = {};
        if (!hasRole(user, 'ADMINISTRATOR')) {
            loadFilter[getRoleForm(user)] = user.id;
        }
        return loadFilter;
    }
    React.useEffect(  () => {
        const load = async () => {
            for(let key in dialogData){
                if (dialogData.hasOwnProperty(key)){
                    let aux =dialogData[key];
                    if (aux === null) {
                        aux = '';
                    }
                    setFieldValue(key, aux);
                }
            }
            setLoaded( 2);
        };
        load();
    }, [setLoaded, dialogData, setFieldValue]);
    if (loaded < 2 ) {
        return (<div style={{minWidth: '30vw', minHeight:'20vh'}}><h1>Cargando</h1><Spinner /></div>)
    }
    return (
        <div>
            {dialogData.editting === true ?   <DialogTitle>Editar Zona</DialogTitle> :
                <DialogTitle>Añadir Zona</DialogTitle>}
            <DialogContent>
                <form onSubmit={handleSubmit}>
                        <Field name="name"
                               label="Nombre" className="form-control" component={MaterialTextField} variant="outlined"/>
                        <Button className="form-control"
                                variant="contained" color="secondary" type="submit" disabled={isSubmitting || !isValid}
                        >Enviar</Button>
                </form>
            </DialogContent>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        onZoneRegister: async vals => {
            await (await createZone(vals))(dispatch)
        },
        onDialogClose: () => {
            dispatch(closeDialogAction({id: "create-zone-dialog"}));
        },
        onZoneEdit:  async vals => {
            await (await editZone(vals))(dispatch)
        },
    }
}


const schema = Yup.object().shape({
    name: Yup.string().required('Obligatorio'),
});

CreateZone = withFormik(
    {
        validationSchema: schema,
        mapPropsToValues: (props) => {

            return {
                name: ''
            }
        },
        handleSubmit: async (vals, props) => {
            props.setSubmitting(false);
            const data = Object.assign({}, vals);
            vals.id = props.props.dialogData.id;
            if (props.props.dialogData.editting) {
                await props.props.onZoneEdit(data).then((res) => {
                    props.resetForm();
                    props.props.onDialogClose();
                });
                return;
            }
            await props.props.onZoneRegister(data).then((res) => {
                props.resetForm();
                props.props.onDialogClose();
            });
        },
        displayName: 'Crear Zona',
    }
)(CreateZone);

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

CreateZone = connect(mapStateToProps, mapDispatchToProps)(CreateZone);

