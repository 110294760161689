import React, {useState, Component, useEffect, useContext} from "react";
import './Home.scss'
import {Button} from "@material-ui/core";
import {NavLink} from "react-router-dom";
import {Grid} from "../../comun/Grid/Grid";
import HasRole from "../../../auth/components/HasRole/HasRole";
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import ReactHtmlParser from 'react-html-parser';
import axios from "axios";
import {envLoader} from "../../../env/envLoader";
import {hasRole} from "../../../auth/util";
import {connect} from "react-redux";
import {loadingDispatcher} from "../../comun/Loading/loadingAction";
import {openAlertAction} from "../../comun/AlertMessage/AlertAction";
import {CampaignContext} from "../../comun/CampaignStyleContext/CampaignStyleContext";
import {getValueOrDefault} from "../../../util/utilCampaign";

const baseUrl = envLoader().base_url;

let Home = ({user, ...props}) => {

    const [showEditor, setShowEditor] = useState(false);
    const showEditorAction = () => setShowEditor(!showEditor);
    const [currentHomeInfo, setcurrentHomeInfo] = useState('');
    const themeCampaign = useContext(CampaignContext);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [inactiveButton, setInacTiveButtons] = useState(true);
    async function getHomePage()  {
        props.dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
        const currentHomeInfo = await axios.get(`${baseUrl}home/get-home-page`)
            .then( response => {
                props.dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));
                 console.log(response.data.data);
                return response.data.data;               
            }).catch(reason => {
                // props.dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true}));
                // props.dispatch(openAlertAction({
                //     id: 'error-load-home-info', severity: 'error',
                //     message: "Hubo un error al intentar cargar la información de la pantalla principal, por favor inténtalo de nuevo", title:'Error'
                // }));
                // throw reason;
            });
        if (!currentHomeInfo) {
            return;
        }
        if (hasRole(user, 'ADMIN') || hasRole(user, 'FL_MANAGER')) {
            const blocksFromHTML = convertFromHTML(currentHomeInfo);
            const content = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );
            setEditorState(EditorState.createWithContent(content));     
        }         
        setcurrentHomeInfo(currentHomeInfo);    
    }

    useEffect(() => {
        if (!user) {
            return;
        }
        console.log(user);
        console.log(props.user);
        getHomePage();
    }, []);
    
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        setcurrentHomeInfo(draftToHtml(convertToRaw(editorState.getCurrentContent())));
        setInacTiveButtons(false);
    }

    async function saveHomePage(homePageContent){
        props.dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
        const currentHomeInfo = await axios.post(`${baseUrl}home/edit-home-page`, homePageContent, {
            headers: {
              'content-type': 'text/html'
            }
          })
            .then( response => {
                props.dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));
                return response.data.data;
            }).catch(reason => {
                props.dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true}));
                props.dispatch(openAlertAction({
                    id: 'error-save-home-info', severity: 'error',
                    message: "Hubo un error al guardar la información de la pantalla principal, por favor inténtalo de nuevo", title:'Error'
                }));
                throw reason;
            })
        
    }

    const saveEditorContent = () => {
        showEditorAction();
        const homePageContent = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        saveHomePage(homePageContent);
        setInacTiveButtons(true);
    }

    const discardEditorContent = () => {
        getHomePage();
        showEditorAction();
        setInacTiveButtons(true);
    }

    const campaingType = getValueOrDefault('campaign.type', user.campaign, 'event');
    //const campaingName = getValueOrDefault('campaign.name', user.campaign, 'event');
    const campaingName = user.campaign.name;
    
    console.log("home.js --> campaingName = "+campaingType);
    console.log("home.js --> campaingName = "+campaingName);
    console.log("El user en home:");
    console.log(user.campaign);
    console.log(user.code);
    

    return (
        <div className="box">
            <h1>Inicio</h1>
            <Grid  columns="max-content max-content max-content" columnGap="30px" mobileColumns="1fr">
                
                {campaingName != "Vivanta" && campaingName != "Vitaldent" && campaingName != "KonectaDental" && campaingName != "Sicor" ?
                <HasRole role="TEAM_LEADER,COMMERCIAL_PROMOTER,ADMIN">
                {campaingType === "kcrm" ? 
                    <NavLink to="/register-lead-adamo">
                    <Button size="medium" variant="contained"
                            color="secondary">Ir a Añadir Lead Adamo</Button>
                    </NavLink>
                    :
                                        
                    <NavLink to="/register-lead">
                    <Button size="medium" variant="contained"
                            color="secondary">Ir a Añadir Lead</Button>
                    </NavLink>
                }
                {campaingType === "kcrm" ? 
                null
                :
                <HasRole role="TEAM_LEADER,COMMERCIAL_PROMOTER,ADMIN">
	                <NavLink to="/stats/summary">
	                    <Button size="medium" variant="contained"
	                            color="secondary">Ir a Estadísticas</Button>
	                </NavLink>
                </HasRole>
                }
                </HasRole>
                :
                null
                }
                
                {campaingName ==="Vivanta" || campaingName === "Vitaldent" || campaingName === "KonectaDental" ?
                <HasRole role="TEAM_LEADER,COMMERCIAL_PROMOTER,ADMIN">
                   {/*
                   <NavLink to="/register-lead-dental">
                    <Button size="medium" variant="contained"
                            color="secondary">Ir a Añadir Lead</Button>
                    </NavLink>
                    */}
                    
                    <NavLink 
                                                                    to={{
                                                                        pathname:"/register-lead-dental/" +  `${ user.code}`,
                                                                        aboutProps:{
                                                                        gestor: `${ user.code}`
                                                                            
                                                                        }
                                                                    }}
                                                                    exact
                                                                    >
                                                                    {" "}
                                                                   <Button size="medium" variant="contained"
                                                                      color="secondary">Ir a Añadir Lead</Button>
                            </NavLink>
                </HasRole>
                :
                null
                }



                {campaingName ==="Sicor" ?
                <HasRole role="TEAM_LEADER,COMMERCIAL_PROMOTER,ADMIN">
                   {/*
                   <NavLink to="/register-lead-dental">
                    <Button size="medium" variant="contained"
                            color="secondary">Ir a Añadir Lead</Button>
                    </NavLink>
                    */}
                    
                    <NavLink 
                                                                    to={{
                                                                        pathname:"/register-lead-sicor/" +  `${ user.code}`,
                                                                        state:{
                                                                            gestor: `${ user.code}`
                                                                            
                                                                        }
                                                                    }}
                                                                    exact
                                                                    >
                                                                    {" "}
                                                                   <Button size="medium" variant="contained"
                                                                      color="secondary">Ir a Añadir Lead</Button>
                            </NavLink>
                </HasRole>
                :
                null
                }
                
                {campaingName === "Vivanta" || campaingName === "Vitaldent" || campaingName === "KonectaDental" ?
                <HasRole role="TEAM_LEADER,COMMERCIAL_PROMOTER,ADMIN">
	                <NavLink to="/stats/summary">
	                    <Button size="medium" variant="contained"
	                            color="secondary">Ir a Estadísticas</Button>
	                </NavLink>
                </HasRole>
                :
                null
                }
                
                
                
                <HasRole role="FL_MANAGER,AREA_COORDINATOR,ADMIN">
                
                 {campaingName === "Vivanta" || campaingName === "Vitaldent" || campaingName === "KonectaDental" || campaingName === "Sicor" ?
                <NavLink to="/my-leads">
                    <Button size="medium" variant="contained"
                            color="secondary">Gestionar Leads</Button>
                </NavLink>
                :
                <NavLink to="/manage-leads">
                    <Button size="medium" variant="contained"
                            color="secondary">Gestionar Leads</Button>
                </NavLink>
                }
                
                {campaingName === "Vivanta" || campaingName === "Vitaldent" || campaingName === "KonectaDental" || campaingName === "Sicor" ?
                (campaingName === "Sicor"?
                <NavLink to="/standsSicor">
                    <Button size="medium" variant="contained"
                            color="secondary">Gestionar Centros Comerciales</Button>
                </NavLink>
                :
                <NavLink to="/standsDental">
                    <Button size="medium" variant="contained"
                            color="secondary">Gestionar Acciones</Button>
                </NavLink>
                ):
                <NavLink to="/stands">
                    <Button size="medium" variant="contained"
                            color="secondary">Gestionar Stands</Button>
                </NavLink>
                }
                
                {campaingName === "Vivanta" || campaingName === "Vitaldent" || campaingName === "KonectaDental" || campaingName === "Sicor" ?
                (campaingName === "Sicor"?
                <NavLink to="/citiesSicor">
                <Button size="medium" variant="contained"
                        color="secondary">Gestionar Ciudades</Button>
                </NavLink>
                :
                <NavLink to="/citiesDental">
                    <Button size="medium" variant="contained"
                            color="secondary">Gestionar Ciudades</Button>
                </NavLink>
                ):
                <NavLink to="/cities">
                    <Button size="medium" variant="contained"
                            color="secondary">Gestionar Ciudades</Button>
                </NavLink>
                }
                
                {campaingName === "Iberdrola" ?
                <NavLink to="/zones">
                <Button size="medium" variant="contained"
                        color="secondary">Gestionar Zonas</Button>
                </NavLink>
                :
                null
                }

                </HasRole>
            </Grid>
            <div className="editorButtons">
                <HasRole role="FL_MANAGER, ADMIN">
                    <Button size="medium" variant="contained" style={themeCampaign.button.secondary}
                             onClick = {showEditorAction}>Editar</Button>
                    {showEditor ? <Button size="medium" variant="contained"
                            className="discardButton" onClick = {discardEditorContent} style={{opacity: inactiveButton ?  0.4: 1 }} disabled={inactiveButton}>Descartar Cambios</Button>: null}        
                    {showEditor ? <Button size="medium" variant="contained"
                            className="saveButton" onClick = {saveEditorContent} style={{opacity: inactiveButton ?  0.4: 1 }} disabled={inactiveButton}>Guardar</Button>: null}
                    { showEditor ? <Editor editorState={editorState} toolbarClassName="toolbar" wrapperClassName="home-info-editor-container" editorClassName="editor" onEditorStateChange={onEditorStateChange}/> : null}              
                </HasRole>
            </div>
            <div className="home-info">
                <div> { ReactHtmlParser (currentHomeInfo) } </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default Home = connect(mapStateToProps, null)(Home);

