
export const LOGIN = 'LOGIN';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const SET_CAMPAIGN = 'SET_CAMPAIGN';

export const LOGIN_ERROR ='LOGIN_ERROR';

export const LOADING = 'LOADING';

export const USER_LOGGED = 'USER_LOGGED';

export const USER_LOGOUT = 'USER_LOGOUT';

export const APP_LOADED = 'APP_LOADED';

export const MENU_SHOW ='MENU_SHOW';

export const MENU_HIDE = 'MENU_HIDE';

export const USER_REGISTER ='USER_REGISTER';

export const EDIT_USER ='EDIT_USER';

export const UPDATE_PROFILE ='UPDATE_PROFILE';

export const OPEN_DIALOG ='OPEN_DIALOG';

export const CLOSE_DIALOG ='CLOSE_DIALOG';

export const REMOVE_DIALOG = 'REMOVE_DIALOG';

export const SELECT_ASIGN_USER= 'SELECT_ASIGN_USER';

export const ASIGN_TEAM_LEADER_USER= 'ASIGN_TEAM_LEADER_USER';

export const CREATE_LEAD = 'CREATE_LEAD';

export const LOAD_LEADS = 'LOAD_LEADS';

export const OPEN_ALERT = 'OPEN_ALERT';

export const CLOSE_ALERT = 'CLOSE_ALERT';

export const LOAD_CITIES = 'LOAD_CITIES';

export const CREATE_CITY = 'CREATE_CITY';

export const EDIT_CITY = 'EDIT_CITY';

export const LOAD_ZONES = 'LOAD_ZONES';

export const CREATE_ZONE = 'CREATE_ZONE';

export const EDIT_ZONE = 'EDIT_ZONE';


export const LOAD_STAND = 'LOAD_STAND';

export const CREATE_STAND = 'CREATE_STAND';

export const EDIT_STAND = 'EDIT_STAND';
