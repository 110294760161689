import React from "react";
import {KeyboardDatePicker} from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import { es } from 'date-fns/locale'

export default ({form: {values, setFieldValue}, variant = 'outlined', label, field: {onChange, name, onBlur}, ...custom}) => {
    const handleDateChange = (e) => {
        setFieldValue(name, e);
    };
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
            <KeyboardDatePicker
            margin="normal"
            variant={variant}
            label={label}
            value={values[name]}
            onChange={handleDateChange}
            format="dd-MM-yyyy"
            KeyboardButtonProps={{
                'aria-label': 'Cambiar día',
            }}/>
        </MuiPickersUtilsProvider>
    )

}
