import React, {useState} from "react";
import {Icon} from "../Icon/Icon";
import './DateRangeWeekPicker.scss';
import {Grid} from "../Grid/Grid";
import moment from "moment";
import 'moment/locale/es';
import {Calendar} from "../Calendar/Calendar";
import {Button, IconButton} from "@material-ui/core";
import {date} from "yup";


const isBefore = (day, day2) => {
    return day.moment < day2.moment;
}

const isAfter = (day, day2) => {
    return day.moment > day2.moment;
}
const sameDay = (day, day2) => {
    return day.day === day2.day && day.month === day2.month && day.year === day2.year;
}
export let DateRangePicker = ({first, last, onClose= () => {}, onChange= () => {},...props}) => {
    moment.locale('es');
    const [dateView, setDateView] = useState(moment(new Date()));
    const [firstInterval, setFirstInterval] = useState(first);
    const [lastInterval, setLastInterval] = useState(last);
    const [hovered, setHovered] = useState(undefined);
    const changeMonth = (interval) => {
        setDateView(moment(dateView.add(interval, 'M')));
    }
    const onDayOver = (day) => {
        setHovered(day);
    }
    const onDayOut = (day) => {
        setHovered(undefined);
    }
    const reset = () => {
        setFirstInterval(undefined);
        setLastInterval(undefined);
        onChange(undefined, undefined);


    }
    const dayClass = (d) => {
        let res = '';
        if (firstInterval !== undefined) {
            const isSelected = (sameDay(d, firstInterval)) ? 'selected' : null;
            if (isSelected) {
                return isSelected;
            }
        }
        if (lastInterval !== undefined) {
            const isSelected = (sameDay(d, lastInterval)) ? 'selected' : null;
            if (isSelected) {
                return isSelected;
            }
        }
        if (lastInterval !== undefined && firstInterval !== undefined) {
            if (d.moment > firstInterval.moment && d.moment < lastInterval.moment) {
                return 'selected-range';
            }
        }
        if (hovered !== undefined && firstInterval !== undefined && sameDay(firstInterval, lastInterval)) {
            if (d.moment > firstInterval.moment && d.moment < hovered.moment) {
                res+= ' hovered-range';
            }
        }
        if (hovered !== undefined && firstInterval !== undefined && !sameDay(firstInterval, lastInterval)) {
            if (d.moment > firstInterval.moment && d.moment < hovered.moment) {
                res += ' hovered-range';
            }
        }
        return res;

    }
    const selectDay = (day) => {
        if (firstInterval === undefined) {
            setFirstInterval(day);
            setLastInterval(day);
            onChange(day, day);
            return;
        }
        if (isBefore(day, firstInterval)) {
            setFirstInterval(day);
            setLastInterval(day);
            onChange(day, day);
            return;
        }
        if (sameDay(day, firstInterval)) {
            setFirstInterval(undefined);
            setLastInterval(undefined);
            onChange(firstInterval, lastInterval);
            return;
        }
        if (isAfter(day, firstInterval)) {
            setLastInterval(day);
            onChange(firstInterval, day);
            return;
        }
        if (lastInterval === undefined) {
            setLastInterval(day);
            onChange(firstInterval, day);
            return;
        }
        if (sameDay(day, lastInterval)) {
            setFirstInterval(undefined);
            setLastInterval(undefined);
            onChange(undefined, undefined);
            return;
        }
    }
    const res =
        <div>
            <div className="date-range-week-picker-container">
                <div className="date-range-week-picker-month">
                    <div  className="date-range-week-picker-header">
                        <div>
                            <IconButton onClick={() => changeMonth(-1)}><Icon>keyboard_arrow_left</Icon></IconButton>
                        </div>
                        <span>
                       {dateView.format('MMMM')} {dateView.format('YYYY')}
                    </span>
                        <div>
                            <IconButton onClick={() => changeMonth(1)}><Icon>keyboard_arrow_right</Icon></IconButton>
                        </div>
                    </div>
                    <div className="date-range-week-picker-calendar">
                        <Calendar date={dateView} onDayClick={selectDay} dayClass={dayClass} onDayOver={onDayOver}
                                  onDayOverOut={onDayOut} />
                    </div>

                </div>
                <Grid columns={3}>
                    <div></div>
                    <Button  size="medium"
                             color="secondary" type="submit" onClick={() =>
                    {reset()}}>Resetear</Button>
                    <Button  size="medium"
                            variant="contained" color="secondary" type="submit" onClick={() =>
                    {onClose(firstInterval, lastInterval)}}>Aceptar</Button>
                </Grid>
            </div>
        </div>;
    return (res);
}
