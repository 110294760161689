import React, {useEffect, useState} from "react";
import {connect} from "react-redux";

import {updateConnectionTime} from "../../sections/Users/UserAction";


export let UpdateConnectionTime = ({user, ...props}) => {
    const [intervalTimer,setIntervalTimer] = useState(undefined);
    useEffect(() => {
            if (!intervalTimer && user) {
                setIntervalTimer(setInterval(() => {
                    if (!user) {
                        clearInterval(intervalTimer);
                        setInterval(undefined);
                        return;
                    }
                    updateConnectionTime().then(() => {});
                }, 1000 * 60))
            } else {
                clearInterval(intervalTimer);
                setInterval(undefined);
            }
        }
    , [user])
    return (<React.Fragment>
        {null}
    </React.Fragment>);
}


const mapPropsToState = state => {
    return {
        user: state.user,
    }
}

UpdateConnectionTime = connect(mapPropsToState, null)(UpdateConnectionTime);
