import React from "react";


export class ErrorBoundary extends React.Component {
    state = {
        error: false
    }

    componentDidCatch(error, info) {
        console.info(info);
        console.log('ey');
        this.setState({
            error: true
        })
    }
    static getDerivedStateFromError() {
        return { error: true };
    }
    render() {
        if (this.state.error === true) {
            return (<React.Fragment>
                <h1>Ups...Ocurrió un Error</h1>
                <p>Sentimos lo ocurrido, por favor refresca la página.</p>
            </React.Fragment>);
        }
        return  <React.Fragment>
            {this.props.children}
        </React.Fragment>
    }

}
