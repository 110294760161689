import React from "react";
import "./Login.scss"
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {isAuth} from "../../auth";
import {LoginForm} from "./LoginForm";

let Login = (props) => {
    const {user} = props;
    if (isAuth(user)) {
        return (
            <Redirect to="/" />
        )
    }
    return (
        <div className="login-container">
            <div className="login-main">
                <div className="login-main-logos">
                    <img  src={process.env.PUBLIC_URL + "/logo.png"} className="login-header-logo"  alt="Logo Kauto Iberdrola FL"/>
                </div>

                <LoginForm/>
            </div>
        </div>
    )
}


const mapPropsToState = state => {
    return {error: state.login.error, user: state.user}
}

Login = connect(mapPropsToState,null)(Login);
export default Login;
