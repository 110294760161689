import React, {useCallback, useEffect, useState} from "react";
import {connect} from "react-redux";
import {openDialogAction} from "../../comun/DialogManager/DialogManagerAction";
import Divider from "@material-ui/core/Divider";
import {Icon} from "../../comun/Icon/Icon";
import Button from "@material-ui/core/Button";
import {List, ListActions, ListAvatar, ListContent} from "../../comun/List/List";
import {Grid} from "../../comun/Grid/Grid";
import Avatar from "@material-ui/core/Avatar";
import TouchRipple from "@material-ui/core/ButtonBase/TouchRipple";
import Pagination from "@material-ui/lab/Pagination";
import Chip from "@material-ui/core/Chip";
import {RegisterUser} from "../RegisterUser/RegisterUser";
import {editUser} from "../Users/UserAction";
import './ManageUsers.scss';
import {setActivate} from "../UpdateProfile/UpdateProfileAction";
import HasRole from "../../../auth/components/HasRole/HasRole";
import {NavLink} from "react-router-dom";
import {AssignTeamLeader} from "../CommercialPromoter/AssignTeamLeader/AssignTeamLeader";
import {AssignStandDental} from "../CommercialPromoter/AssignStandDental";
import {ManageUserFilters} from "./ManageUsersFilter";
import {AssignTeamLeaderCity} from "../TeamLeader/AssignTeamLeader";

export let ManageUserDental = ({dialogData, assignCity = false, assignAction, assignStand = false, assignText, assignData, onActivate, onDialogOpen, action, loadFilter= {}, title ='', ...props}) => {
    const getInitialFilter = () => {
        let res = {page: 0, size: 10};
        if (dialogData !== undefined) {
            res = Object.assign(dialogData, res);
            if (dialogData.action) {
                action = dialogData.action;
            }
            if (dialogData.title) {
                title = dialogData.title;
            }
        }

        return res;
    }
    const [users, setUsers] = useState({content: [], totalPages: 0});
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState(getInitialFilter());

    const handleFilters = (vals) => {
        let data = Object.assign({}, vals);
        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                data[key] = (data[key] === '') ? null : data[key];
            }
        }
        data = changeFilter(data);
        data.page = 0;
        setPage(1);
        setFilter(data);
    }
    const openEditDialog = (user, callback) => {
        onDialogOpen({
            component: RegisterUser, data: {user: user, editting: true, dispatchAction: editUser},
            opened: true, id: "edit-user-dialog", onClose: () => {
                load(filter)
            }
        });
    }
    const openAssignComponent = (user) => {
        assignAction(user, () => load(filter));
    }
    const openAssignCityComponent = (user) => {

        onDialogOpen({
            component: AssignTeamLeaderCity, data: {user: user},
            opened: true, id: "assign-user-city-dialog",
            onClose: () => load(filter)
        });
    }
    const openAssignStandComponent = (user) => {
        onDialogOpen({
            component: AssignStandDental, data: {user: user},
            opened: true, id: "assign-user-stand-dialog",
            onClose: () => load(filter)
        });
    }
    const activateUser = async user => {
        await onActivate(user);
        load(filter);
    }
    const load = async (filter) => {
        const data = Object.assign(loadFilter, filter);
        await props.onDispatchAction(action, data).then(res => {
            setUsers(res);
        })
    }
    const changeFilter =(data) => {
        return Object.assign({}, filter, data);
    };
    const changPage = (event, page) => {
        setPage(page);
        setFilter(changeFilter({page: page - 1}));
    }
    const getSupervisor = (user) => {
        if (user.parent !== null){
            return  <React.Fragment>
                <div className="list-content-list-item">
                    <h4 className="list-content-list-item-header">Supervisor</h4>
                    <small className="list-content-list-item-subtitle">{user.parent.name}</small>
                </div>
                <div className="list-content-list-item">
                    <h4 className="list-content-list-item-header">Código Supervisor</h4>
                    <small className="list-content-list-item-subtitle">{user.parent.code}</small>
                </div>
            </React.Fragment>
        }
        return null;
    }
    const renderCity = (user) => {
        if (!user.cities) {
            return null;
        }
        if (user.cities.length > 0) {
            return       <div className="list-content-list-item">
                <h4 className="list-content-list-item-header">Ciudad</h4>
                <small className="list-content-list-item-subtitle">{user.cities[0].name}</small>
            </div>
        }
    }
    const renderStand = (user) => {
        if (user.stand !== null){
            return       <div className="list-content-list-item">
                <h4 className="list-content-list-item-header">Acción</h4>
                <small className="list-content-list-item-subtitle">{user.stand.mall}</small>
            </div>
        }
        return null;
    }
    const renderActivated = user => {
        return (user.status === 'ACTIVATED') ?
            <Chip className="user-activated-chip" aria-label="Activado" alt="Activado"
                  size="small"
                  label="Activado"
            /> : <Chip className="user-deactivated-chip" aria-label="Desactivado" alt="Desactivado"
                       size="small"
                       label="Desactivado"
            />;
    }

    useEffect(() => {
        load(filter);
    }, [filter]);
    return (
        <div className="box">
            <h1>{title}</h1>
            <Grid columns="7fr 1fr" mobileColumns="1fr">
                <div>
                    <ManageUserFilters onSubmit={handleFilters} />
                </div>
                <Pagination count={users.totalPages} variant="outlined" page={page} shape="rounded" onChange={changPage}/>
            </Grid>
            {(users.content.length === 0) ? <p>No hay ningun usuario para mostrar</p> : null}
            {users.content.map((user) => {
                return (
                    <React.Fragment key={user.id}>
                        <List>
                            <TouchRipple/>
                            <ListAvatar>
                                <Avatar className="list-avatar-container">
                                    <Icon alt="Icono de Lead" style={{fontSize: "1.6em", color: '#666'}}>person</Icon>
                                </Avatar>
                            </ListAvatar>
                            <ListContent>
                                <h3 className="list-header">{renderActivated(user)} {user.name}</h3>
                                <div className="list-content-list">
                                    <Grid columns="repeat(3, fit-content(200px))"  mobileColumns="1fr 1fr"
                                          className="list-content-list-columns">
                                        <div className="list-content-list-item">
                                            <h4 className="list-content-list-item-header">Código</h4>
                                            <small className="list-content-list-item-subtitle">{user.code}</small>
                                        </div>
                                        {/*<div className="list-content-list-item">*/}
                                        {/*    <h4 className="list-content-list-item-header">Código m4</h4>*/}
                                        {/*    <small className="list-content-list-item-subtitle">  {user.m4}</small>*/}
                                        {/*</div>*/}
                                        {/*<div className="list-content-list-item">*/}
                                        {/*    <h4 className="list-content-list-item-header">Email</h4>*/}
                                        {/*    <small className="list-content-list-item-subtitle">{user.email}</small>*/}
                                        {/*</div>*/}
                                        {/*<div className="list-content-list-item">*/}
                                        {/*    <h4 className="list-content-list-item-header">Teléfono</h4>*/}
                                        {/*    <small className="list-content-list-item-subtitle">{user.phone}</small>*/}
                                        {/*</div>*/}
                                        {/*<div className="list-content-list-item">*/}
                                        {/*    <h4 className="list-content-list-item-header">Email</h4>*/}
                                        {/*    <small className="list-content-list-item-subtitle">{user.email}</small>*/}
                                        {/*</div>*/}
                                        {/*<div className="list-content-list-item">*/}
                                        {/*    <h4 className="list-content-list-item-header">Documento</h4>*/}
                                        {/*    <small className="list-content-list-item-subtitle">{user.dni}</small>*/}
                                        {/*</div>*/}
                                        {getSupervisor(user)}
                                        {renderStand(user)}
                                        {renderCity(user)}

                                    </Grid>
                                </div>
                            </ListContent>
                            <ListActions>
                                <HasRole role="AREA_COORDINATOR,FL_MANAGER,ADMIN">
                                <div>
                                    <Button onClick={() => {
                                        openEditDialog(user)
                                    }}>
                                        <Icon>edit</Icon> Editar
                                    </Button>
                                </div>
                                {assignAction ?
                                    <div>
                                        <Button onClick={() => {openAssignComponent(user)}}>
                                            <Icon>
                                                group_add
                                            </Icon> {assignText}

                                        </Button>
                                    </div>
                                    : null}
                                    {assignStand === true ?
                                        <div>
                                            <Button onClick={() => {openAssignStandComponent(user)}}>
                                                <Icon>
                                                    <Icon>add_business</Icon>
                                                </Icon> Asignar Acción

                                            </Button>
                                        </div>
                                        : null}
                                    {assignCity === true ?
                                        <div>
                                            <Button onClick={() => {openAssignCityComponent(user)}}>
                                                <Icon>
                                                    <Icon>location_city</Icon>
                                                </Icon> Asignar Ciudad

                                            </Button>
                                        </div>
                                        : null}
                                        <div>
                                            <Button onClick={() => {activateUser(user)}}>
                                                <Icon>
                                                    {user.status === 'ACTIVATED' ? 'close' : 'check'}
                                                </Icon> {user.status === 'ACTIVATED' ? 'DESACTIVAR' : 'ACTIVAR'}

                                            </Button>
                                        </div>
                                    </HasRole>
                            </ListActions>
                        </List>
                        <Divider style={{marginTop: '7px', marginBottom: '7px'}}/>
                    </React.Fragment>
                )
            })}
        </div>
    )
}


const mapDispatchToProps = dispatch => {
    return {
        onDialogOpen: data => {
            dispatch(openDialogAction(data));
        },
        onDispatchAction: async (action, data) => {
            return await action(data)(dispatch)
        },
        onActivate: async (user) => {
            return await setActivate(user)(dispatch);
        }
    }
}

ManageUserDental = connect(null, mapDispatchToProps)(ManageUserDental);
