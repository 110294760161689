import React, {useCallback, useEffect, useState} from "react";
import {connect} from "react-redux";
import {deleteCity, getCities} from "../CityAction";
import Pagination from "@material-ui/lab/Pagination";
import Button from "@material-ui/core/Button";
import {Icon} from "../../../comun/Icon/Icon";
import Divider from "@material-ui/core/Divider";
import {openDialogAction} from "../../../comun/DialogManager/DialogManagerAction";
import {CreateCity} from "../CreateCity/CreateCity";
import {Grid} from "../../../comun/Grid/Grid";
import "./CityList.scss";
import {List, ListActions, ListAvatar, ListContent} from "../../../comun/List/List";
import Avatar from "@material-ui/core/Avatar";
import {StandListDental} from "../../Stand/StandList/StandListDental";
import HasRole from "../../../../auth/components/HasRole/HasRole";


export let CityListDental = ({onLoadCity, onDeleteCity, cities, listTitle="Ciudades", dialogData, onDialogOpen, ...props}) => {
    const [page, setPage] = useState(1);
    const getInitialFilter = () => {
        let res = {page: 0, size: 10, cleaned: true};
        if (dialogData !== undefined) {
            res = Object.assign(dialogData, res);
        }

        return res;
    }
    const changeFilter = useCallback((data) => {
        return Object.assign({}, filter, data);
    }, []);
    const changPage = (event, page) => {
        setPage(page);
        setFilter(changeFilter({page: page- 1}));
        //Cambiar de página
    }
    const [filter, setFilter] = useState(getInitialFilter());
    useEffect(() => {
        onLoadCity(filter);
    }, [onLoadCity, filter]);
    const createCity = () => {
        onDialogOpen({component: CreateCity, data: {editting: false}, opened: true, id: "create-city-dialog"});
    };
    const editCity = (city) => {
        onDialogOpen({component: CreateCity,  data: {editting: true, id: city, ...city}, opened: true, id: "create-city-dialog"});
    };
    const getStands = (city) => {
        onDialogOpen({component: StandListDental,  data: {city: city.id}, opened: true, id: "list-stands-dialog"});
    }
    const renderTeamLeader = (city) => {
        if (city.teamLeader === null) {
            return null;
        }
        return    <div className="list-content-list-item">
            <h4 className="list-content-list-item-header">Jefe de Equipo</h4>
            <small className="list-content-list-item-subtitle">{city.teamLeader.name}</small>
        </div>

    }
    return (
        <div className="box">
            <h1>{listTitle}</h1>
            <HasRole role="AREA_COORDINATOR,FL_MANAGER,ADMIN">
            <Button variant="contained" color="secondary" style={{margin: "7px"}} onClick={createCity}>Añadir Ciudad</Button>
            </HasRole>
            {(cities.content.length === 0) ? <p>No hay ninguna ciudad para mostarr</p> : null}
            <Pagination count={cities.totalPages} variant="outlined"
                        shape="rounded" page={page} onChange={changPage} />
                {cities.content.map((city) => {
                    return (
                        <React.Fragment key={city.id}>
                        <List>
                            <ListAvatar>
                                <Avatar className="list-avatar-container">
                                    <Icon style={{fontSize: "1.6em", color: '#666'}}>location_city</Icon>
                                </Avatar>
                            </ListAvatar>
                            <ListContent>
                                <h3>{city.name}</h3>
                                <div className="list-content-list">
                                <Grid columns="repeat(3, fit-content(200px))"  mobileColumns="1fr 1fr" className="list-content-list-columns">
                                    <div className="list-content-list-item">
                                        <h4 className="list-content-list-item-header">Localidad</h4>
                                        <small className="list-content-list-item-subtitle">{city.locality}</small>
                                    </div>
                                    <div className="list-content-list-item">
                                        <h4 className="list-content-list-item-header">Provincia</h4>
                                        <small className="list-content-list-item-subtitle">{city.province}</small>
                                    </div>
                                    {renderTeamLeader(city)}
                                </Grid>
                                </div>
                            </ListContent>
                        <ListActions>
                            <HasRole role="AREA_COORDINATOR,FL_MANAGER,ADMIN">
                            <div>
                                <Button onClick={() => {
                                    editCity(city);
                                }}>
                                    <Icon>edit</Icon> EDITAR
                                </Button>
                            </div>
                            </HasRole>
                            <div>
                                <Button onClick={() => getStands(city)}>
                                    <Icon>add_business</Icon> ACCIONES
                                </Button>
                            </div>
                            <HasRole role="AREA_COORDINATOR,FL_MANAGER,ADMIN">
                            <div>
                                <Button onClick={() => onDeleteCity(city.id)}>
                                    <Icon>delete</Icon> BORRAR
                                </Button>
                            </div>
                            </HasRole>
                        </ListActions>
                      </List>
                    <Divider />
                        </React.Fragment>
                    )
                })}
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadCity: async data => {
            await (await getCities(data))(dispatch)
        },
        onDialogOpen: data => {
            dispatch(openDialogAction(data));
        },
        onDeleteCity: async data => {
            return await deleteCity(data)(dispatch);
        }
    }
}
const mapPropsToState = state => {
    return {
        cities: state.city,
    }
}

CityListDental = connect(mapPropsToState, mapDispatchToProps)(CityListDental);
