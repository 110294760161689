import moment from "moment";

export const getLocaleWeekDayName = (dayNumber) => {
    dayNumber = getLocaleWeekDay(dayNumber);
    return moment.weekdays(true)[dayNumber];
}

export const getLocaleWeekDay = (dayNumber) => {
    if (dayNumber === 0) {
        return 6;
    }
    return dayNumber - 1;
}
