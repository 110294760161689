import {applyMiddleware, createStore} from "redux";
import logger from "./middleware/logger";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./reducers/RootReducer";
const middlewares = applyMiddleware(logger, thunkMiddleware)

export const configureStore = () => {
    return createStore(rootReducer, middlewares)

}
