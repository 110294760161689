import axios from "axios";
import {envLoader} from "../../../env/envLoader";
import {loadingDispatcher} from "../../comun/Loading/loadingAction";
import {USER_REGISTER} from "../../../types/types";
import {openAlertAction} from "../../comun/AlertMessage/AlertAction";


const baseUrl = envLoader().base_url;

export const userAction = values => {
    return {
        type: USER_REGISTER,
        payload: values
    }
}
export const editUserAction = values => {
    return {
        type: USER_REGISTER,
        payload: values
    }
}

export const documentExists =  async (document) => {
    return await axios.get(`${baseUrl}user/document/${document}/exists`);
}
export const updateConnectionTime =  async () => {
    return await axios.get(`${baseUrl}user/update-connection-time`);
}


export const registerUser = (values) => {
    return async (dispatch) => {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
        return await axios.post(`${baseUrl}user/register`, values)
            .then( response => {
                dispatch(userAction(response.data.data));
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));
                dispatch(openAlertAction({
                    id: 'register-user', severity: 'success', message: "Usuario creado con exito", title: 'Éxito'
                }));
            }).catch(reason => {
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true}));
                dispatch(openAlertAction({
                    id: 'error-register-user', severity: 'error',
                    message: "Hubo un error al intentar crear el usuario, por favor inténtelo de nuevo", title:'Error'
                }));
            })
    }
}

export const editUser = (values) => {
    return async (dispatch) => {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
        return await axios.post(`${baseUrl}user/edit`, values)
            .then( response => {
                dispatch(userAction(response.data.data));
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));
                dispatch(openAlertAction({
                    id: 'edit-user', severity: 'success', message: "Usuario editado con exito", title: 'Éxito'
                }));
            }).catch(reason => {
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true}));
                dispatch(openAlertAction({
                    id: 'error-edit-user', severity: 'error',
                    message: "Hubo un error al intentar editar el usuario, por favor inténtelo de nuevo", title:'Error'
                }));
            })
    }
}

export const assignUser = (values) => {
    return async (dispatch) =>  {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
        return await axios.get(`${baseUrl}user/${values.user}/assign/${values.parent}`).then(res => res.data.data)
            .then( response => {
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));
                return response;
            }).catch(reason => {
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true}));
                dispatch(openAlertAction({
                    id: 'error-register-lead', severity: 'error',
                    message: "Hubo un error al intentar cargar los usuarios, por favor inténtalo de nuevo", title:'Error'
                }));
                throw reason;
            })
    }
}
