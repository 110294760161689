import React from "react";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import {getIn} from "formik";


export default function RenderTextField({form, errorText, variant, label, field: {onChange, name, onBlur}, onChangeField,...custom}) {
    const fieldError = getIn(form.errors, name);
    const showError = getIn(form.touched, name) && !!fieldError;
    let value = '';
    if (form.initialValues[name] !== undefined) {
        value = (form.values[name].length === 0) ? form.initialValues[name]
            : form.values[name];
    }

    return (
        <FormControl variant={variant} error={showError} required={custom.required}>
            <InputLabel>{label}</InputLabel>
            <Select

                value={value}
                name={name}
                onChange={(e) => {
                    onChange(e)
                    if (onChangeField) {
                        onChangeField(e);
                    }
                }}
                onBlur={onBlur}
                {...custom}>
                {custom.children}
            </Select>
            {form.errors[name] ? <FormHelperText>
                {errorText}
            </FormHelperText> : null}

        </FormControl>
    )
}
