import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import Pagination from "@material-ui/lab/Pagination";
import {List, ListActions, ListAvatar, ListContent} from "../../../comun/List/List";
import Avatar from "@material-ui/core/Avatar";
import {Icon} from "../../../comun/Icon/Icon";
import Divider from "@material-ui/core/Divider";
import {openDialogAction} from "../../../comun/DialogManager/DialogManagerAction";
import {connect} from "react-redux";
import Chip from "@material-ui/core/Chip";
import './LeadList.scss';
import TouchRipple from "@material-ui/core/ButtonBase/TouchRipple";
import moment from "moment";
import {Grid} from "../../../comun/Grid/Grid";
import "./LeadList.scss";
import {LeadDetails} from "../LeadDetails/LeadDetails";
import {LeadDetailsAdamo} from "../LeadDetails/LeadDetailsAdamo";
import {LeadDetailsDental} from "../LeadDetails/LeadDetailsDental";
import {LeadDetailsSicor} from "../LeadDetails/LeadDetailsSicor";
import {AssignStandLead} from "../AssignStandLead/AssignStandLead";
import {AssignStandLeadDental} from "../AssignStandLead/AssignStandLeadDental";
import {AssignStandLeadSicor} from "../AssignStandLead/AssignStandLeadSicor";
import HasRole from "../../../../auth/components/HasRole/HasRole";
import {getValueOrDefault} from "../../../../util/utilCampaign";
import userEvent from "@testing-library/user-event";

export let LeadList = ({onLoadLead, loading, onDialogOpen, LeadFilterComponent = null, listTitle="Mis Leads", initFilter = {}, callback,
                           leads, user, ...props}) => {
    const [page, setPage] = useState(1);
    const getInitialFilter = () => {
        let res = {page: 0, size: 10};
        res = Object.assign(initFilter, res);

        return res;
    }
    const [leadFilter, setLeadFilter] = useState(getInitialFilter());
    const openLeadDialog = (lead) => {
        let campaingType = getValueOrDefault('campaign.type', user.campaign, 'event');
        let campaingName = user.campaign.name;
        ;
        if(campaingType === "kcrm"){
	        if (campaingName === "Vivanta" || campaingName === "Vitaldent" || campaingName === "KonectaDental"){
		        onDialogOpen({component: LeadDetailsDental,  data: {lead: lead}, opened: true, id: "lead-details-dialog"});
	        }else if (campaingName === "Sicor"){
                onDialogOpen({component: LeadDetailsSicor,  data: {lead: lead}, opened: true, id: "lead-details-dialog"});
            }else{
		        onDialogOpen({component: LeadDetailsAdamo,  data: {lead: lead}, opened: true, id: "lead-details-dialog"});
	        }
            
        }else{
	        if (campaingName === "Vivanta" || campaingName === "Vitaldent" || campaingName === "KonectaDental"){
		        onDialogOpen({component: LeadDetailsDental,  data: {lead: lead}, opened: true, id: "lead-details-dialog"});
	        }else if (campaingName === "Sicor"){
                onDialogOpen({component: LeadDetailsSicor,  data: {lead: lead}, opened: true, id: "lead-details-dialog"});
            }else{
		        onDialogOpen({component: LeadDetails,  data: {lead: lead}, opened: true, id: "lead-details-dialog"});
	        }
            
        }
    }

    const openAssingStandComponent = (lead) =>{
        onDialogOpen({component: AssignStandLead,  data: {lead: lead}, opened: true, id: "assign-lead-stand-dialog", onClose: () => onLoadLead(leadFilter, callback)});
    }
    
    const openAssingStandComponentDental = (lead) =>{
        onDialogOpen({component: AssignStandLeadDental,  data: {lead: lead}, opened: true, id: "assign-lead-stand-dialog", onClose: () => onLoadLead(leadFilter, callback)});
    }

    const openAssingStandComponentSicor = (lead) =>{
        onDialogOpen({component: AssignStandLeadSicor,  data: {lead: lead}, opened: true, id: "assign-lead-stand-dialog", onClose: () => onLoadLead(leadFilter, callback)});
    }
    
    const changPage = (event, page) => {
        setPage(page);
        setLeadFilter(changeLeadFilter({page: page - 1}));
    }
    const changeLeadFilter = (data) => {
        return Object.assign({}, leadFilter, data);
    };
    useEffect(() => {
        onLoadLead(leadFilter, callback);
    }, [onLoadLead, leadFilter, callback]);

    const renderSale = (lead) => {
        return (lead.sale === true) ? <Chip className="lead-sale-chip" aria-label="Venta" alt="Venta"
                                            size="small"
                                            label="Venta"
        /> : null;
    }
    const renderTeamLeader = (lead) => {
        return (lead.user !== null) ?
            <div className="list-content-list-item">
                <h4 className="list-content-list-item-header">Comercial Encargado</h4>
                <small className="list-content-list-item-subtitle">{lead.user.name}</small>
            </div>
            : null;
    }
    const renderCommercialPromoter = (lead) => {
          if (lead.user !== null) {
              if (lead.user.parent !== null) {
                  return <div className="list-content-list-item">
                      <h4 className="list-content-list-item-header">Jefe de equipo</h4>
                      <small className="list-content-list-item-subtitle">{lead.user.parent.name}</small>
                  </div>
              }
          }
          return null;
    }
    const renderStand = (lead) => {
        return (lead.stand !== null) ?  <div className="list-content-list-item">
            {user.campaign.name === "Vivanta" || user.campaign.name === "Vitaldent" ||user.campaign.name === "KonectaDental" || user.campaign.name === "Sicor" ?
            (user.campaign.name === "Sicor"?
            <h4 className="list-content-list-item-header">Centro</h4>
            :
            <h4 className="list-content-list-item-header">Acción</h4>
            ):
            <h4 className="list-content-list-item-header">Stand</h4>
            }
            
            
            <small className="list-content-list-item-subtitle">{lead.stand.mall}</small>
        </div> : null;
    };
    const handleFilters = vals => {
        let data = Object.assign({}, vals);
        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                data[key] = (data[key] === '') ? null : data[key];
            }
        }
        data = changeLeadFilter(data);
        data.page = 0;
        setPage(1);
        setLeadFilter(data);
    }
    return (
        <div className="box">
            <h1>{listTitle}</h1>
            {!LeadFilterComponent ? null :
                    <div>
                        <LeadFilterComponent onSubmit={handleFilters} manage={true}/>
                    </div>
            }
            <Pagination count={leads.totalPages} variant="outlined" className="filter-leads-pagination"
                        shape="rounded" page={page} onChange={changPage}/>
            {(leads.content.length === 0) ?
                <p>No hay ningun lead para mostrar</p>
                : null}

            {leads.content.map((lead) => {
                return (
                    <React.Fragment key={lead.id}>
                        <List>
                            <TouchRipple/>
                            <ListAvatar>
                                <Avatar className="list-avatar-container">
                                    <Icon alt="Icono de Lead"
                                          style={{fontSize: "1.6em", color: '#666'}}>business_center</Icon>
                                </Avatar>
                            </ListAvatar>
                            <ListContent>
                                <h3 className="list-header">{renderSale(lead)} {lead.name} {lead.surname1} {lead.surname2}
                                </h3>
                                <div className="list-content-list">
                                    <Grid columns="repeat(3, fit-content(200px))" mobileColumns="1fr 1fr" className="list-content-list-columns">
                                        {user.campaign.name === "Vivanta" || user.campaign.name === "Vitaldent" ||user.campaign.name === "KonectaDental" || user.campaign.name === "Sicor" ?
                                        null
                                        :
                                        <div className="list-content-list-item">
                                            <h4 className="list-content-list-item-header">Compañia</h4>
                                            <small className="list-content-list-item-subtitle">{lead.company}</small>
                                        </div>
                                        }
                                        
                                        <div className="list-content-list-item">
                                            <h4 className="list-content-list-item-header">Fecha de Alta</h4>
                                            <small className="list-content-list-item-subtitle">{moment(lead.signUpDate,
                                                "DD-MM-YYYY").format("DD-MM-YYYY")}</small>
                                        </div>
                                        {renderStand(lead)}
                                        {renderTeamLeader(lead)}
                                        {renderCommercialPromoter((lead))}
                                    </Grid>
                                </div>
                            </ListContent>
                            <ListActions>
                                <HasRole role="FL_MANAGER,ADMIN,AREA_COORDINATOR,CLINICA">
                                <div>
                                    <Button onClick={() => {
                                        openLeadDialog(lead);
                                    }}>
                                        <Icon>add</Icon> Detalles
                                    </Button>
                                </div>
                                </HasRole>
                                {!lead.stand ?  
                                
                                <HasRole role="FL_MANAGER,ADMIN,ADMIN,AREA_COORDINATOR">
                                    {user.campaign.name === "Vivanta" || user.campaign.name === "Vitaldent" ||user.campaign.name === "KonectaDental" || user.campaign.name === "Sicor" ?
                                    (user.campaign.name === "Sicor"?
                                    <div>
                                        <Button onClick={() => {
                                            openAssingStandComponentSicor(lead);
                                        }}>
                                            
                                            <Icon>add_business</Icon> Asignar Centro Comercial
                                        </Button>
                                    </div>
                                    :
                                    <div>
                                        <Button onClick={() => {
                                            openAssingStandComponentDental(lead);
                                        }}>
                                            
                                            <Icon>add_business</Icon> Asignar Acción
                                        </Button>
                                    </div>
                                    ):
                                    <div>
                                        <Button onClick={() => {
                                            openAssingStandComponent(lead);
                                        }}>
                                            
                                            <Icon>add_business</Icon> Asignar Stand
                                        </Button>
                                    </div>
                                    }
                                </HasRole>
                                
                                 
                                : 
                                null
                                }

                            </ListActions>
                        </List>
                        <Divider style={{marginTop: '7px', marginBottom: '7px'}}/>
                    </React.Fragment>
                )
            })}
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadLead: async (data, callback) => {
            await (await callback(data))(dispatch)
        },
        onDialogOpen: data => {
            dispatch(openDialogAction(data));
        },
    }
}
const mapPropsToState = state => {
    return {
        leads: state.lead,
        user: state.user,
        loading: state.loading
    }
}

LeadList = connect(mapPropsToState, mapDispatchToProps)(LeadList);
// LeadList = withErrorBoundary(LeadList);
