

export const palette = {
    primary: '#f5c067',
    secondary: '#062653',
    secondary_light: '#2ba3ce',
    primary_secondary: '#4f4046',
    light: '#FFF',
    dark: '#000'
}
