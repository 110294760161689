import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import {connect} from "react-redux";
import {Field, withFormik} from "formik";
import Button from "@material-ui/core/Button";
import * as Yup from "yup";
import {assignLeadStand, filterStands} from "../../Stand/StandAction";
import {AutocompleteUser} from "../../../comun/AutocompleteUser/AutocompleteUser";
import {closeDialogAction} from "../../../comun/DialogManager/DialogManagerAction";


export let AssignStandLead = ({setFieldValue, lead, isSubmitting, handleSubmit, isValid, dialogData, ...props}) => {
    return (
        <React.Fragment>
            <DialogTitle>Asignar Stand {dialogData.lead.name}</DialogTitle>
            <form onSubmit={handleSubmit}>
                <Field name="stand"
                       label="Asigna Stand"
                       component={AutocompleteUser}
                       labelAttr="mall"
                       load={filterStands}
                       loadParams={() => {
                           return {cleaned: true}
                       }}
                       variant="outlined"
                       className="form-control"
                />
                <Button className="form-control"
                        variant="contained" color="secondary" type="submit" disabled={isSubmitting || !isValid}>
                    Asignar</Button>
            </form>
        </React.Fragment>
    )
}

const schema = Yup.object().shape({
    lead: Yup.number(),
    stand: Yup.object().required("Obligatorio")
});


AssignStandLead = withFormik(
    {
        validationSchema: schema,
        mapPropsToValues: (props) => {
            let stand = props.dialogData.lead.stand;
            if (stand === null) {
                stand = {id: 0, name: 'Ninguno'};
            }
            return {
                stand: stand,
                lead: props.dialogData.lead.id,
            }
        },
        handleSubmit: async (vals, props) => {
            props.setSubmitting(false);
            const data = Object.assign({}, vals);
            if (data.stand) {
                if (data.stand.id !== 0) {
                    data.stand = data.stand.id;
                } else {
                    data.stand = null;
                }

            }
            try {
                const res = await props.props.onAssignStandLead(data);
                props.resetForm();
                props.props.onDialogClose();
            } catch (e) {

            }

        },
        displayName: 'Asignar Stand',
    }
)(AssignStandLead);

const mapDispatchToProps = dispatch => {
    return {
        onAssignStandLead: async vals => {
            return await assignLeadStand(vals)(dispatch)
        },
        onDialogClose: () => {
            dispatch(closeDialogAction({id: "assign-lead-stand-dialog"}));
        },
    }
}


AssignStandLead = connect(null, mapDispatchToProps)(AssignStandLead);


