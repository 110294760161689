import {connect} from "react-redux";
import {closeDialogAction, removeDialogAction} from "./DialogManagerAction";
import React, {useEffect, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import {Icon} from "../Icon/Icon";
import './DialogManager.scss';
import {withStyles} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    container: {
        width: '700px'
    }
});

let DialogManager = ({classes, ...props}) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = {
        root: {
            flexGrow: 1,
            backgroundColor: '#6D6E70',
            width: '100%'
        }
    };
    useEffect(() => {
        props.dialogs.filter(dialog => dialog !== undefined).filter(dialog => dialog.closed === true)
            .forEach((dialog, key) => {
                if (dialog.closed === true) {
                    props.onRemoveDialog(dialog);
                }
            });
    }, [props.dialogs]);

    const listDialogs = props.dialogs.filter(data => data !== undefined).filter(data => data.component !== undefined).map(data => {
        const Component = data.component;
        if (Component === undefined) {
            return null;
        }
        return (
            <Dialog key={data.id} onClose={() => {
                props.onDialogClose(data)
            }} open={data.opened} fullScreen={fullScreen}
                    maxWidth="lg" style={styles}>
                <DialogTitle disableTypography className={classes.root}>
                    <Typography variant="h6">
                        <Component dialogData={data.data}/>
                    </Typography>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={() => {
                        props.onDialogClose(data)
                    }}>
                        <Icon style={{fontSize: "1.3em"}}>close</Icon>
                    </IconButton>
                </DialogTitle>

            </Dialog>
        )
    })
    return (
        <div>
            {listDialogs}
        </div>
    )
}
const mapPropsToState = state => {
    return {dialogs: Object.values(state.dialogs)}
}
const mapDispatchToProps = dispatch => {
    return {
        onDialogClose: (data) => {
            dispatch(closeDialogAction({id: data.id}));
        },
        onRemoveDialog: (data) => {
            dispatch(removeDialogAction({id: data.id}));
            if (data.onClose) {
                console.log('Hola');
                data.onClose(data.value);
            }
        }
    }
}

DialogManager = connect(mapPropsToState, mapDispatchToProps)(DialogManager);
DialogManager = withStyles(styles)(DialogManager);
export default DialogManager;
