import React from "react";
import {Grid} from "../../../comun/Grid/Grid";
import {connect} from "react-redux";
import "./SuperiorInfo.scss";
import {Table, TableBody, TableRow} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";

export let SuperInfo = ({user, ...props}) => {
    const parent = user.parent;
    return (
        <div className="box">
            <h1>Información de mi superior</h1>

            <Table className="summary-stats-table">
                <TableBody>
                    <TableRow>
                        <TableCell component="th">Nombre</TableCell>
                        <TableCell>{parent.name}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell  component="th">Teléfono</TableCell>
                        <TableCell>{parent.phone}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell  component="th">Email</TableCell>
                        <TableCell>{parent.email}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>

        </div>
    )
}

const mapStateToProps = (state) => {
    return {user: state.user};
}

SuperInfo = connect(mapStateToProps)(SuperInfo);
