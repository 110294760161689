import React from "react";
import {required, validatePhone, validateUsername,} from "../../../validation/basicValidation";
import MaterialTextField from "../../comun/Forms/MaterialTextField";
import MenuItem from "@material-ui/core/MenuItem";
import SelectField from "../../comun/Forms/SelectField";
import Button from "@material-ui/core/Button";
import './RegisterUser.scss'
import {registerUser} from "../Users/UserAction";
import {connect} from "react-redux";
import {Field, withFormik} from "formik";
import * as Yup from "yup";
import Divider from "@material-ui/core/Divider";
import {closeDialogAction, openDialogAction} from "../../comun/DialogManager/DialogManagerAction";
import HasRole from "../../../auth/components/HasRole/HasRole";
import {Grid} from "../../comun/Grid/Grid";
import {cache} from "../../../util/utilCache";

const mapDispatchToProps = (dispatch, ownprops) => {
    const res = {
        onPostRegister: async data => {
            return await registerUser(data)(dispatch);
        },
        onAssign: data => {
            dispatch(openDialogAction(data));
        },
        onDialogClose: data => {
            dispatch(closeDialogAction({id: "edit-user-dialog"}))
        }
    };
    if (ownprops.dialogData) {
        if (ownprops.dialogData.dispatchAction) {
            res.onPostRegister = async data => {
                return await ownprops.dialogData.dispatchAction(data)(dispatch);
            }
        }
    }
    return res;
}


export let RegisterUserSicor = ({onPostRegister, isSubmitting, form, dialogData, setFieldValue, isValid, values, ...props}) => {
    return (
        <div className="box ">
            <form onSubmit={props.handleSubmit}>
                <Grid columns={1} className="register-user-form-block">
                    <h1>Datos de Usuario</h1>
                    <Divider/>
                    <Field name="username" component={MaterialTextField} validate={[required]}
                           label="Nombre de Usuario"/>
                    {dialogData ? <small>Si la contraseña se deja vacia no se actualizará</small> : null}
                    <Field name="password" component={MaterialTextField} type="password"
                           label="Contraseña"/>
                    <Field name="confirmPassword" component={MaterialTextField} type="password"
                           label="Confirmar Contraseña"/>
                    <HasRole role="AREA_COORDINATOR">
                        <Field name="rol" disabled={!!dialogData}
                               label="Selecciona el Rol"
                               variant="outlined"
                               component={SelectField}>
                            <MenuItem value="COMMERCIAL_PROMOTER">Promotor Comercial</MenuItem>
                            <MenuItem value="TEAM_LEADER">Jefe de Equipo</MenuItem>
                        </Field>
                    </HasRole>
                    <HasRole role="FL_MANAGER">
                        <Field name="rol" disabled={!!dialogData}
                               variant="outlined"
                               label="Selecciona el Rol"
                               component={SelectField}
                               placeholder="Selecciona el Rol">
                            <MenuItem value="COMMERCIAL_PROMOTER">Promotor Comercial</MenuItem>
                            <MenuItem value="TEAM_LEADER">Jefe de Equipo</MenuItem>
                            <MenuItem value="AREA_COORDINATOR">Coordinador de zona</MenuItem>
                            <MenuItem value="FL_MANAGER">Responsable FL</MenuItem>                            
                        </Field>
                    </HasRole>
                    <HasRole role="ADMIN">
                        <Field name="rol" disabled={!!dialogData}
                               variant="outlined"
                               label="Selecciona el Rol"
                               component={SelectField}
                               placeholder="Selecciona el Rol"

                        >
                            <MenuItem value="COMMERCIAL_PROMOTER">Promotor Comercial</MenuItem>
                            <MenuItem value="TEAM_LEADER">Jefe de Equipo</MenuItem>
                            <MenuItem value="AREA_COORDINATOR">Coordinador de zona</MenuItem>
                            <MenuItem value="FL_MANAGER">Responsable FL</MenuItem>
                            <MenuItem value="ADMIN">Administrador</MenuItem>                            
                        </Field>
                    </HasRole>
                    <h1>Datos Personales y Profesionales</h1>
                    <Divider/>
                    <Field name="name" component={MaterialTextField} type="text"
                           label="Nombre y Apellidos"/>
                    <div>
                        <Field name="m4" component={MaterialTextField} type="text"
                               label="Código M4"/>
                    </div>
                    <div>
                        <Field name="phone" component={MaterialTextField} type="text"
                               label="Teléfono"/>
                    </div>
                    <div>
                        <Field name="email" component={MaterialTextField} type="text"
                               label="Email"/>
                    </div>
                    <div>
                        <Field name="dni" component={MaterialTextField} type="text"
                               label="DNI"/>
                    </div>
                    <div>
                        <Field name="size"
                               label="Talla"
                               component={SelectField}
                               placeholder="Selecciona una Talla"
                               errorText="Escoge una talla">
                            <MenuItem value="XS">XS</MenuItem>
                            <MenuItem value="S">S</MenuItem>
                            <MenuItem value="M">M</MenuItem>
                            <MenuItem value="L">L</MenuItem>
                            <MenuItem value="XL">XL</MenuItem>
                            <MenuItem value="XXL">XXL</MenuItem>
                        </Field>
                    </div>
                </Grid>
                <Button className="register-user-button" size="medium" disabled={isSubmitting || !isValid}
                        variant="contained" color="secondary" type="submit">Dar de Alta</Button>
            </form>
        </div>
    )
}

RegisterUserSicor = withFormik(
    {
        mapPropsToValues: (props) => {
            if (props.dialogData) {
                if (props.dialogData.user) {
                    const data = props.dialogData.user;
                    return {
                        username: data.username,
                        name: data.name,
                        password: '',
                        confirmPassword: '',
                        id: data.id,
                        phone: data.phone,
                        email: data.email,
                        code: data.code,
                        m4: data.m4,
                        size: data.size,
                        dni: data.dni,
                        rol: props.dialogData.user.authorities[0].authority
                    }
                }
            }
            return {
                username: '', password: '', confirmPassword: '', name: '', m4: '', phone: '', email: '',
                code: '', size: 'M', rol: 'COMMERCIAL_PROMOTER', id: null, dni: ''
            }
        },
        validateOnChange: false,
        handleSubmit: async (values, {props, setSubmitting, resetForm}) => {
            try {
                props.onPostRegister(values).then(() => {
                    if (props.dialogData) {
                        props.onDialogClose();
                        props.dialogData.user = values;
                    }
                    resetForm();
                });
            } catch (e) {

            }
        },
        validationSchema: (props) => {
            const schema = {
                username: Yup.string().required('Obligatorio').test('valid-username', 'El nombre de usuario ya está en uso', (value) => {
                    return cache('register-user-validate-user', value, () => validateUsername(value));
                }),
                password: Yup.string().required('Obligatorio'),
                confirmPassword: Yup.string().required('Obligatorio'),
                name: Yup.string().required('Obligatorio'),
                code: Yup.string(),
                m4: Yup.string().required('Obligatorio'),
                email: Yup.string().required('Obligatorio').email('Dirección Email incorrecta'),
                phone: Yup.string().required('Obligatorio').test('is-phone', "No es un número de teléfono válido", value => validatePhone(value)),
                size: Yup.string().required('Obligatorio'),
                rol: Yup.string().required('Obligatorio')
            };
            if (props.dialogData) {
                if (props.dialogData.editting) {
                    schema.password = undefined;
                    schema.confirmPassword = undefined;
                    schema.username = Yup.string().required('Obligatorio')
                }
            }
            return Yup.object().shape(schema)
        },
        displayName: 'Registrar Usuario',
    }
)(RegisterUserSicor);
RegisterUserSicor = connect(null, mapDispatchToProps)(RegisterUserSicor)
