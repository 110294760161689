import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import {connect} from "react-redux";
import {Field, withFormik} from "formik";
import {AutocompleteUser} from "../../../comun/AutocompleteUser/AutocompleteUser";
import {filterTeamLeaders} from "../../TeamLeader/TeamLeaderAction";
import Button from "@material-ui/core/Button";
import * as Yup from "yup";
import {assignUser} from "../../Users/UserAction";
import {getRoleForm} from "../../../../auth/util";
import {closeDialogAction} from "../../../comun/DialogManager/DialogManagerAction";
import {filterAreaCoordinators} from "../../AreaCoordinator/AreaCoordinatorActions";


export let AssignAreaCoordinator = ({setFieldValue, user, isSubmitting, handleSubmit, isValid, dialogData, ...props}) => {
    return (
        <React.Fragment>
            <DialogTitle>Asignar Supervisor {dialogData.user.name}</DialogTitle>
            <form onSubmit={handleSubmit}>
                <Field name="parent"
                       label="Asigna Coordinador de Zona"
                       component={AutocompleteUser}
                       load={filterAreaCoordinators}
                       variant="outlined"
                       className="form-control"
                />
                <Button className="form-control"
                        variant="contained" color="secondary" type="submit" disabled={isSubmitting || !isValid}>
                    Asignar</Button>
            </form>
        </React.Fragment>
    )
}
const schema = Yup.object().shape({
    user: Yup.number(),
    parent: Yup.object().required("Obligatorio")
});


AssignAreaCoordinator = withFormik(
    {
        validationSchema: schema,
        mapPropsToValues: (props) => {
            let parent = props.dialogData.parent;
            if (parent === null) {
                parent = {id: 0, name: 'Ninguno'};
            }
            return {
                parent: parent,
                user: props.dialogData.user.id,
            }
        },
        handleSubmit: async (vals, props) => {
            props.setSubmitting(false);
            const data = Object.assign({}, vals);
            if (data.parent) {
                if (data.parent.id !== 0) {
                    data.parent = data.parent.id;
                } else {
                    data.parent = null;
                }

            }
            try {
                const res = await props.props.onAssignUser(data);
                props.resetForm();
                props.props.onDialogClose();
            } catch (e) {

            }

        },
        displayName: 'Asignar',
    }
)(AssignAreaCoordinator);

const mapDispatchToProps = dispatch => {
    return {
        onAssignUser: async vals => {
            return await assignUser(vals)(dispatch)
        },
        onDialogClose: () => {
            dispatch(closeDialogAction({id: "assign-user-dialog"}));
        },
    }
}
const mapStateToProps = (state) => {
    return {user: state.user};
}

AssignAreaCoordinator = connect(mapStateToProps, mapDispatchToProps)(AssignAreaCoordinator);
