import React, {useState} from "react";
import {closeDialogAction} from "../../../comun/DialogManager/DialogManagerAction";
import {createStand, editStand, getStandsStatus} from "../StandAction";
import * as Yup from "yup";
import Spinner from "../../../comun/Spinner/Spinner";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Field, withFormik} from "formik";
import MaterialTextField from "../../../comun/Forms/MaterialTextField";
import {AutocompleteUser} from "../../../comun/AutocompleteUser/AutocompleteUser";
import Button from "@material-ui/core/Button";
import {filterCities} from "../../City/CityAction";
import {connect} from "react-redux";
import DatePickerField from "../../../comun/Forms/DatePickerField";
import SelectField from "../../../comun/Forms/SelectField";
import MenuItem from "@material-ui/core/MenuItem";
import {getRoleForm, hasRole} from "../../../../auth/util";

import {validateAction} from "../../../../validation/basicValidation";

export let CreateStandDental = ({setFieldValue, user, isSubmitting, handleSubmit, dialogData, isValid, ...props}) => {
    const [loaded, setLoaded] = useState(0);
    const [statuses, setStatuses] = useState([]);
    const [actionStand,setActionStand] = useState(false);
    
    const campaingName = user.campaign.name;
	//console.log(user.campaign);
    
    const loadParams = () => {
        const loadFilter = {};
        if (!hasRole(user, 'ADMINISTRATOR')) {
            loadFilter[getRoleForm(user)] = user.id;
        }
        return loadFilter;
    }
  
    
    const getTipoAccion = () => {
        return(
            [
	           {
	                "id": 1,
	                "tipo": "STAND",
	                "nombre": "Stand"
	                
	            },
		        {
	                "id": 2,
	                "tipo": "DINAMI",
	                "nombre": "Dinamización"
	                
	            },
	            
	            {
	                "id": 3,
	                "tipo": "OTRO",
	                "nombre": "Otro"
	               
	            }
            ]
        )
    };        
    
    React.useEffect(  () => {
        const load = async () => {
            const stauses = await getStandsStatus();
            setStatuses(stauses);
            for(let key in dialogData){
                if (dialogData.hasOwnProperty(key)){
                    let aux =dialogData[key];
                    if (aux === null) {
                        aux = '';
                    }
                    setFieldValue(key, aux);
                   
                }
            }
            setLoaded( 2);
        };
        load();
    }, [setLoaded, dialogData, setFieldValue, setStatuses]);
    if (loaded < 2 ) {
        return (<div style={{minWidth: '30vw', minHeight:'20vh'}}><h1>Cargando</h1><Spinner /></div>)
    }
    return (
        <div>
            {dialogData.editting === true ?   <DialogTitle>Editar Ciudad</DialogTitle> :
                <DialogTitle>Añadir Acción</DialogTitle>}
            <DialogContent>
                <form onSubmit={handleSubmit}>
                    <h4>Datos de la acción</h4>
                    <Field name="mall"
                           label="Nombre de la clínica" className="form-control" component={MaterialTextField} variant="outlined"/>
                    <Field name="code"
                           label="Código" className="form-control" component={MaterialTextField} variant="outlined"/>
                    <Field name="openDate"
                           label="Fecha de apertura" className="form-control"
                           component={DatePickerField} variant="outlined"/>
                    <Field name="city"
                           label="Selecciona una Ciudad"
                           component={AutocompleteUser}
                           load={filterCities}
                           variant="outlined"
                           className="form-control"
                    />
                    
                    {campaingName === "Vivanta" || campaingName === "Vitaldent"  || campaingName === "KonectaDental" ?
                    <Field name="action" variant="outlined" required
                           label="Tipo de acción"
                           component={SelectField}
                           errortext="Escoge un tipo de accion" 
                           onChangeField={() => {
                               setFieldValue('miAction', '');                                                                                           
                           }}
                    >
                        {getTipoAccion().map((acc) => {
                            
                            return <MenuItem key={acc.id}
                                             value={acc.tipo}>{acc.nombre}</MenuItem>
                            
                        })}
                    </Field>
                    :
                    null
                    }
                    
                    <Field name="status"
                           label="Estado de la acción"
                           component={SelectField}
                           variant="outlined"
                           className="form-control">
                        {
                            statuses.map((status) => {
                                return <MenuItem key={status.id} value={status.status}>{status.showStatus}</MenuItem>
                            })
                        }
                    </Field>
                    
                    <Button className="form-control"
                            variant="contained" color="secondary" type="submit" disabled={isSubmitting || !isValid}
                    >Enviar</Button>
                </form>
            </DialogContent>
        </div>
    )
}




const schema = Yup.object().shape({
    mall: Yup.string().required('Obligatorio'),
    code: Yup.string().required('Obligatorio'),
    openDate: Yup.date().required('Obligatorio'),
    status: Yup.string().required('Obligatorio'),
    action: Yup.string().required('Obligatorio')
     //.test('is-stand', "No se envía si la acción no es stand", value => validateAction(value))
     ,
    city: Yup.object().required('Obligatorio'),
    user: Yup.object().nullable(),    
});


CreateStandDental = withFormik(
    {
        validationSchema: schema,
        mapPropsToValues: (props) => {

            return {
                mall: '', 
                code: '', 
                openDate: new Date(), 
                user: {id: 0, name: 'Ninguno'},
                status: '', 
                action: '',
                city: {id: 0, name: 'Ninguno'},
            }
        },
       
        handleSubmit: async (vals, props) => {
            props.setSubmitting(false);
            const data = Object.assign({}, vals);
            vals.id = props.props.dialogData.id;
            if (data.user) {
                if (data.user.id !== 0) {
                    data.user = data.user.id;
                } else {
                    data.user = null;
                }

            }
            if (data.city) {
                if (data.city.id !== 0) {
                    data.city = data.city.id;
                } else {
                    data.city = null;
                }

            }
            if (props.props.dialogData.editting) {
                await props.props.onStandEdit(data).then((res) => {
                    props.resetForm();
                    props.props.onDialogClose();
                });
                return;
            }
            try{
                const res = await props.props.onStandRegister(data);
                props.resetForm();
                props.props.onDialogClose();
            } catch (e) {

            }

        },
        displayName: 'Crear Ciudad',
    }
)(CreateStandDental);

const mapDispatchToProps = dispatch => {
    return {
        onStandRegister: async vals => {
             return await createStand(vals)(dispatch)
        },
        onDialogClose: () => {
            dispatch(closeDialogAction({id: "create-stand-dialog"}));
        },
        onStandEdit:  async vals => {
            return await editStand(vals)(dispatch)
        },
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

CreateStandDental = connect(mapStateToProps, mapDispatchToProps)(CreateStandDental);

