import axios from "axios";
import {envLoader} from "../../../env/envLoader";
import {loadingDispatcher} from "../../comun/Loading/loadingAction";
import {openAlertAction} from "../../comun/AlertMessage/AlertAction";
import {CREATE_LEAD, LOAD_LEADS} from "../../../types/types";

const baseUrl = envLoader().base_url;

export const sendSmsConfirmation = async (data) => {
    return await axios.post(`${baseUrl}sms/send`, data).then(res => res.data.data);
}

export const confirmSms = async (data) => {
    return await axios.post(`${baseUrl}sms/confirm`, data).then(res => res.data.data);
}

export const sendSmsConfirmationEvent = (values) => {
    return async (dispatch) =>  {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false, id:'send-sms'}));
        return await sendSmsConfirmation(values)
            .then( response => {
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true, id:'send-sms'}));
                dispatch(openAlertAction({
                    id: 'send-sms', severity: 'success', message: "Se ha enviao el SMS de confirmación", title: 'SMS enviado'
                }));
                return response;
            }).catch(reason => {
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true, id:'send-sms'}));
                dispatch(openAlertAction({
                    id: 'error-send-sms', severity: 'error',
                    message: "Hubo un error al enviar el sms de confirmación, por favor inténtalo de nuevo", title:'Error'
                }));
                throw reason;
            })
    }
}

export const confirmSmsEvent = (values) => {
    return async (dispatch) =>  {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false, id:'confirm-sms'}));
        return await confirmSms(values)
            .then( response => {
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true, id:'confirm-sms'}));
                if (response === true) {
                    dispatch(openAlertAction({
                        id: 'confirm-sms', severity: 'success', message: "SMS confirmado", title: 'SMS confirmado'
                    }));
                } else {
                    dispatch(openAlertAction({
                        id: 'confirm-sms', severity: 'error', message: "El código de confirmación del teléfono es erroneo",
                        title: 'Código de confirmación incorrecto'
                    }));
                }
                return response;
            }).catch(reason => {
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true, id:'confirm-sms'}));
                dispatch(openAlertAction({
                    id: 'confirm-sms', severity: 'error', message: "Hugo un problema al intentar confirmar el teléfono, " +
                        "por favor inténtalo de nuevo", title: 'Error'
                }));
                throw new Error (reason);
            })
    }
}

