import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import {connect} from "react-redux";
import {Field, withFormik} from "formik";
import Button from "@material-ui/core/Button";
import * as Yup from "yup";
import {AutocompleteUser} from "../../comun/AutocompleteUser/AutocompleteUser";
import {closeDialogAction} from "../../comun/DialogManager/DialogManagerAction";
import {assignTeamLeaderCity, filterCities} from "../City/CityAction";


export let AssignTeamLeaderCity = ({setFieldValue, user, isSubmitting, handleSubmit, isValid, dialogData, ...props}) => {
    return (
        <React.Fragment>
            <DialogTitle>Asignar Ciudad a {dialogData.user.name}</DialogTitle>
            <form onSubmit={handleSubmit}>
                <Field name="city"
                       label="Asigna Ciudad"
                       component={AutocompleteUser}
                       labelAttr="name"
                       load={filterCities}
                       variant="outlined"
                       className="form-control"
                />
                <Button className="form-control"
                        variant="contained" color="secondary" type="submit" disabled={isSubmitting || !isValid}>
                    Asignar</Button>
            </form>
        </React.Fragment>
    )
}
const schema = Yup.object().shape({
    user: Yup.number(),
    city: Yup.object().required("Obligatorio")
});


AssignTeamLeaderCity = withFormik(
    {
        validationSchema: schema,
        mapPropsToValues: (props) => {
            let cities = props.dialogData.user.cities;
            if (cities.length === 0) {
                cities = [{id: 0, mall: 'Ninguno'}];
            }
            return {
                city: cities[0],
                user: props.dialogData.user.id,
            }
        },
        handleSubmit: async (vals, props) => {
            props.setSubmitting(false);
            const data = Object.assign({}, vals);
            if (data.city) {
                if (data.city.id !== 0) {
                    data.city = data.city.id;
                } else {
                    data.city = null;
                }

            }
            try {
                const res = await props.props.onAssignCity(data);
                props.resetForm();
                props.props.onDialogClose();
            } catch (e) {

            }

        },
        displayName: 'Asignar Ciudad',
    }
)(AssignTeamLeaderCity);

const mapDispatchToProps = dispatch => {
    return {
        onAssignCity: async vals => {
            return await assignTeamLeaderCity(vals)(dispatch)
        },
        onDialogClose: () => {
            dispatch(closeDialogAction({id: "assign-user-city-dialog"}));
        },
    }
}


AssignTeamLeaderCity = connect(null, mapDispatchToProps)(AssignTeamLeaderCity);
