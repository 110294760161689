import React from "react";
import {LeadList} from "../LeadList/LeadList";
import {getLeads} from "../LeadAction";
import {connect} from "react-redux";
import {MyLeadListFilters} from "../LeadListFilters/MyLeadListFilters";

export let MyLeads = ({...props}) => {
    return (
        <LeadList callback={getLeads} initFilter={{commercialPromoter: props.user.id}} LeadFilterComponent={MyLeadListFilters} />
    )
}
const mapPropsToState = state => {
    return {
        leads: state.lead,
        user: state.user
    }
}

MyLeads = connect(mapPropsToState, null)(MyLeads);
