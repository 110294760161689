import {CLOSE_ALERT, OPEN_ALERT} from "../types/types";


export  default function AlertReducer (state  = {},  action) {
    let res;
    let id = undefined;
    switch (action.type) {
        case OPEN_ALERT:
            res =  Object.assign({}, state);
            id = action.payload.id;
            res[id] = action.payload;
            res[id].opened = true;
            return res;
        case CLOSE_ALERT:
            res =  Object.assign({}, state);
            id = action.payload.id;
            console.log(res, state, id);
            res[id].opened = false;
            console.log(res);
            return res;
        default:
            return state;
    }
}
