import {envLoader} from "../../../env/envLoader";
import axios from "axios";
import {loadingDispatcher} from "../../comun/Loading/loadingAction";
import {openAlertAction} from "../../comun/AlertMessage/AlertAction";
import {loadStandAction} from "../Stand/StandAction";

const baseUrl = envLoader().base_url;

export const filterCommercialPromoters = async (data) => {
    return await axios.post(`${baseUrl}commercial_promoter/filter`, data).then(res => res.data.data);
}

export const filterClinica = async (data) => {
    return await axios.post(`${baseUrl}commercial_promoter/filterClinica`, data).then(res => res.data.data);
}

export const getCommercialPromoters  = (values) => {
    return async (dispatch) =>  {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
        return await filterCommercialPromoters(values)
            .then( response => {
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));
                return response;
            }).catch(reason => {
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true}));
                dispatch(openAlertAction({
                    id: 'error-register-lead', severity: 'error',
                    message: "Hubo un error al intentar cargar los usuarios, por favor inténtalo de nuevo", title:'Error'
                }));
                return reason;
            })
    }
}

export const getClinica  = (values) => {
    return async (dispatch) =>  {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
        return await filterClinica(values)
            .then( response => {
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));
                return response;
            }).catch(reason => {
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true}));
                dispatch(openAlertAction({
                    id: 'error-register-lead', severity: 'error',
                    message: "Hubo un error al intentar cargar los usuarios, por favor inténtalo de nuevo", title:'Error'
                }));
                return reason;
            })
    }
}
