import React from "react";
import {ManageUser} from "../ManageUsers/ManageUsers";
import {connect} from "react-redux";
import {openDialogAction} from "../../comun/DialogManager/DialogManagerAction";
import {getAreaCoordinators} from "./AreaCoordinatorActions";


export let ManageAreaCoordinator = ({dialogData, onDialogOpen, user, ...props}) => {
    return (
        <ManageUser title="Gestionar Coordinadores de Area"  action={getAreaCoordinators} />
    )
}


const mapDispatchToProps = dispatch => {
    return {
        onDialogOpen: data => {
            dispatch(openDialogAction(data));
        },
    }
}


const mapStateToProps = (state) => {
    return {user: state.user};
}
ManageAreaCoordinator = connect(mapStateToProps, mapDispatchToProps)(ManageAreaCoordinator);


