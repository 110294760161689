import {SET_CAMPAIGN} from "../types/types";


export  default function campaignReducer (state  = {},  action) {
    let res;
    switch (action.type) {
        case SET_CAMPAIGN:
            res =  Object.assign({}, state, action.payload);
            return res;
        default:
            return state;
    }

}



