import React from "react";
import "./Grid.scss";


export let Grid = ({columns="1fr", mobileColumns, rowGap="15px", className, columnGap= "4%", ...props}) => {
    let columnsDecl = columns;
    if (typeof columns === "number") {
        columnsDecl = "";
        for (let i = 0; i < columns; i++) {
            columnsDecl = `${columnsDecl}1fr `;
        }
    }
    const minMatch = window.matchMedia('(min-width: 280px)');
    const maxMatch = window.matchMedia('(max-width: 1280px)');
    const style = {
        gridTemplateColumns: columnsDecl,
        gridColumnGap: columnGap,
        gridRowGap: rowGap
    }
    if (mobileColumns) {
        if (minMatch.matches && maxMatch.matches) {
            style.gridTemplateColumns = mobileColumns;
        }
    }
    return (
        // eslint-disable-next-line no-useless-concat
        <div className={'grid' + " "+className} style={style} {...props}>
            {props.children}
        </div>
    )
}
