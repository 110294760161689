import authorities from './authorities.json';
import {capitalize} from "../util/utilString";

export const parseJwtToken =  (token)  => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};


export const isAccessTokenExpired = (token) => {
    const data = parseJwtToken(token);

    const currDate = Math.floor(Date.now() / 1000);

    return currDate > data.exp;
}

export const getAuthoritiesShowText = (user) => {
    const result = [];
    if (!user.authorities) {
        return result;
    }
    user.authorities.forEach((authority) => {
        if (authorities[authority.authority]) {
            result.push(authorities[authority.authority].name);
        }
        result.push(authority.authority);
    })

    return result;
}

export const hasRole = (user, role) => {
    return getAuthoritiesShowText(user).indexOf(role) !== -1;
}

export const hasAnyRole = (user, roles) => {
    let result = false;
    const authorities = getAuthoritiesShowText(user);
    roles.forEach((r) => {
        if (authorities.indexOf(r) !== -1) {
            result = true;
            return false;
        }
    })
    return result;
}

export const getRoleForm = (user) => {
    const words = user.authorities[0].authority.split("_");
    let res = words[0].toLowerCase();
    if (words[1]) {
        res = res.concat(capitalize(words[1].toLowerCase()));
    }
    return res;
}
