import React from "react";
import "./Footer.scss";


export function Footer(props) {
    return (
        <footer className="footer">
            <ul>
                <li>Aviso Legal</li>
                <li>Política de Cookies</li>
                <li>Konecta 2020</li>
            </ul>
        </footer>
    )
}
