import {APP_LOADED, MENU_HIDE, MENU_SHOW} from "../types/types";


export const appLoadedDispatcher = (data) => {
    return {
        type: APP_LOADED,
        payload: data
    }
}
export const menuShowDispatcher = (payload) => {
    return {
        type: MENU_SHOW,
        payload
    }
}
export const menuHideDispatcher = (payload) => {
    return {
        type: MENU_HIDE,
        payload
    }
}
