import React from "react";
import MaterialTextField from "../../comun/Forms/MaterialTextField";
import {required} from "../../../validation/basicValidation";
import './UpdateProfile.scss';
import Button from "@material-ui/core/Button";
import {confirmPasswordValidation} from "../../../auth/auth";
import {connect} from "react-redux";
import {updateProfile} from "./UpdateProfileAction";
import {Field, withFormik} from "formik";
import * as Yup from "yup";


export  let UpdateProfile = (props)  => {
    const {handleSubmit, isSubmitting, isValid} = props;
    return (
        <div className="box">
            <h1 >Actualiza tu perfil</h1>
            <form  onSubmit={handleSubmit}>
                <div>
                    <Field className="form-control" name="oldpassword" component={MaterialTextField}   type="password" label="Contraseña actual"  />
                </div>
                <div>
                    <Field className="form-control"  name="password" component={MaterialTextField}    type="password" label="Contraseña"  />
                </div>
                <div>
                    <Field className="form-control"  name="confirmPassword" component={MaterialTextField}    type="password" label="Repite contraseña"  />
                </div>
                <div>
                    <Button type="submit" disabled={isSubmitting || (isValid === false)}  variant="contained" color="secondary">Cambiar Contraseña</Button>
                </div>
            </form>
        </div>
    )
}


UpdateProfile = withFormik({
    mapPropsToValues: () => {
        return {
            confirmPassword: '', password: '', oldpassword: ''
        }
    },
    handleSubmit: (values, props) => {
        props.props.onUpdateProfile({userId: props.props.user.id, ...values});
        props.setSubmitting(false);
        props.resetForm();
    },
    validationSchema: Yup.object().shape({
        oldpassword: Yup.string().required('Obligatorio'),
        confirmPassword: Yup.string().required('Obligatorio').oneOf([Yup.ref('password'), null], "Las contraseñas deben coincidir"),
        password: Yup.string().required('Obligatorio'),
    }),
})(UpdateProfile);

const mapDispatchToProps = dispatch => {
    return {
        onUpdateProfile: data => {
            updateProfile(data)(dispatch)
        }
    }
}

const mapPropsToState = state => {
    return {
        user: state.user,
    }
}

UpdateProfile = connect(mapPropsToState, mapDispatchToProps)(UpdateProfile);
export default UpdateProfile;
