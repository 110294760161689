import {CLOSE_DIALOG, LOGIN_SUCCESS, OPEN_DIALOG, REMOVE_DIALOG} from "../../../types/types";



export const removeDialogAction = data => {
    return {
        type: REMOVE_DIALOG,
        payload: data
    }
}
export const closeDialogAction = (data) => {
    return {
        type: CLOSE_DIALOG,
        payload: data
    }
}

export const openDialogAction = (data) => {
    return {
        type: OPEN_DIALOG,
        payload: data
    }
}
