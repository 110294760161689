import {APP_LOADED, MENU_HIDE, MENU_SHOW} from "../types/types";


export default function AppReducer (state = {menu: {open:false}}, action) {
    let result;
    switch (action.type) {
        case APP_LOADED:
            result =  Object.assign({}, state, action.payload);
            return result;
        case MENU_SHOW:
            result = Object.assign({} , state, action.payload);
            result.menu = {open: true};

            return result;
        case MENU_HIDE:
            result = Object.assign({} , state, action.payload);
            result.menu = {open: false};

            return result;

        default:
            return state;
    }
}
