import {USER_LOGGED, USER_LOGOUT} from "../types/types";
import axios from "axios";
import {loadingDispatcher, setLoading} from "../components/comun/Loading/loadingAction";
import {envLoader} from "../env/envLoader";
import {appLoadedDispatcher} from "../dispatcher/commonDispatcher";
import {setCampaign} from "./components/Login/LoginAction";
import {log} from "../util/utilLog";

const baseUrl = envLoader().base_url;

export const isAuth = (user) => {
    if (user === undefined) {
        return false;
    }

    return Object.keys(user).length > 0;
}

export const dispatchLogout = (data) => {
    return {
        type: USER_LOGOUT,
        payload: data
    }
}
export const dispatchValidateToken = (data) => {
    return {
        type: USER_LOGGED,
        payload: data
    }
}

export function userReducer(state = {}, action) {
    switch (action.type) {
        case USER_LOGGED:
            return Object.assign({}, state, action.payload);
        case USER_LOGOUT:
            return {};
        default:
            return state;
    }
}

export const storeAccessToken = (accessToken) => {
    localStorage.setItem("access_token", accessToken);
}

export const validateToken = (token) => {
    return  axios.get(`${baseUrl}auth/validate_token?access_token=${token}`, {withCredentials: true});
}
export const validateTokenAction = (token) => {
    return async (dispatch) => {
        return await validateToken(token)
            .then(response => {
                log(`Usuario ${response.data.data.id} cargado: ${JSON.stringify(response).length}`, "info");
                axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
                storeAccessToken(token);
                dispatch(appLoadedDispatcher({ loaded: true }));
                if (response.data.data.campaign) {
                    dispatch(setCampaign(response.data.data.campaign));
                }
                dispatch(dispatchValidateToken(response.data.data));
                dispatch(loadingDispatcher({ isLoading: false, isError: false, isLoaded: true }));
            }).catch(error => {
                dispatch(loadingDispatcher({ isLoading: false, isError: true, isLoaded: true }));
                localStorage.removeItem('access_token');
                refreshAccessTokenAction()(dispatch);
            }).finally(() => {

            })
    }
}
export const refreshAccessTokenAction =  () => {
    return async (dispatch)  =>  {
        return await axios.get(`${baseUrl}auth/refresh_access_token`, {withCredentials: true})
            .then(response => {
                validateTokenAction(response.data.data.access_token)(dispatch);
            }).catch(error => {
                dispatch(appLoadedDispatcher({loaded: true}));
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true}));
            })
    }
}

export const signoff = (callback) => {
    return (dispatch) => {
        localStorage.removeItem("access_token");
        setLoading({isLoading: true, isError: false, isLoaded: false})(dispatch);
        return axios.get(`${baseUrl}auth/signoff`, {withCredentials: true})
            .finally(() => {
                setLoading({isLoading: false, isError: false, isLoaded: true})(dispatch);
                dispatch(dispatchLogout(undefined));
                window.location.reload() //a modificar
            });
    }
}

export const confirmPasswordValidation = vals => {
    return (vals.password === vals.confirmPassword);
}

export const requestEmail =  (email) => {
    return async (dispatch)  =>  {
        return await axios.get(`${baseUrl}user/recover-password?email=${email}`, {withCredentials: true})
            .then(response => {
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));
                return true;
            }).catch(error => {
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true}));
                throw error;
            })
    }
}
