import {loadingDispatcher} from "../components/comun/Loading/loadingAction";
import {openAlertAction} from "../components/comun/AlertMessage/AlertAction";


export const call = (promise, id, errorMessage) => {
    return (dispatch) =>  {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false, id:'get-leads'}));
        return  promise()
            .then( response => {
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true, id:id}));
            }).catch(reason => {
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true, id:id}));
                if (errorMessage) {
                    dispatch(openAlertAction({
                        id: id, severity: 'error',
                        message: errorMessage, title:'Error'
                    }));
                }

            })
    }
}
