import {default as AlertUi} from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import {connect} from "react-redux";
import React from "react";
import './Alert.scss';
import Button from "@material-ui/core/Button";
import {loadingDispatcher} from "../Loading/loadingAction";
import Snackbar from "@material-ui/core/Snackbar";
import {closeAlertAction} from "./AlertAction";
import {Icon} from "../Icon/Icon";

let Alert = ({alert, ...props}) => {
    const closeAlert = (alert) => {
        props.closeAlert(alert);
    }
    return (
        <div>
            {Object.values(alert).map(a => {
                    return <Snackbar key={a.id} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                     autoHideDuration={6000} open={a.opened} onClose={() => {closeAlert(a)}}
                                     action={<Button color="inherit" size="small">CERRAR</Button>
                    }>
                        <AlertUi severity={a.severity} variant="filled" onClose={() => {closeAlert(a)}}
                                 action={<Button color="inherit" size="medium" onClick={() => {closeAlert(a)}}><Icon>close</Icon></Button>}>
                            <AlertTitle>{a.title}</AlertTitle>
                            {a.message}
                        </AlertUi>
                    </Snackbar>
            }
              )}
        </div>
    )
}
const mapPropsToState = state => {
    return {
        alert: state.alert,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        closeAlert: (alert) => {
            dispatch(closeAlertAction(alert.id));
        },
    }
}

Alert = connect(mapPropsToState, mapDispatchToProps)(Alert);

export default Alert;
