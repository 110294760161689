import {CREATE_LEAD, LOAD_LEADS} from "../types/types";

export  default function LeadReducer (state  = {content: [], totalPages: 1}, action) {
    let res;
    switch (action.type) {
        case LOAD_LEADS:
            res =  Object.assign({}, state, action.payload);
            return res;
        default:
            return state;
    }
}
