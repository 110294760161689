import {envLoader} from "../../../env/envLoader";
import axios from "axios";
import {LOGIN_ERROR, LOGIN_SUCCESS, SET_CAMPAIGN} from "../../../types/types";
import {loadingDispatcher} from "../../../components/comun/Loading/loadingAction";
import {dispatchValidateToken, validateTokenAction} from "../../auth";


const baseUrl = envLoader().base_url;

export const setCampaign = (data) => {
    return {
        type: SET_CAMPAIGN,
        payload: data
    }
}
export const loginSuccessAction = (data) => {
    return {
        type: LOGIN_SUCCESS,
        payload: data
    }
}
export const loginErrorAction = (data) => {
    return {
        type: LOGIN_ERROR,
        payload: data
    }
}




export const login = ({username, password}) => {
    return async (dispatch) => {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
        return await axios.post(`${baseUrl}login`, {username, password}, {withCredentials: true})
            .then(response => {
                dispatch(loginSuccessAction(response.data));
                validateTokenAction(response.data.access_token)(dispatch);
            }).catch(error => {
                console.log(error);
                dispatch(loginErrorAction(error))
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true}));
            })
    }
}
