import React, {useContext, useEffect} from 'react';
import './App.scss';
import {Footer} from "./components/structural/Footer/Footer";
import { createTheme } from '@material-ui/core/styles';

import {palette} from "./variable/palette";
import {ThemeProvider} from '@material-ui/styles';
import Loading from "./components/comun/Loading/Loading";
import {connect} from "react-redux";
import {refreshAccessTokenAction, validateTokenAction} from "./auth/auth";

import {isAccessTokenExpired} from "./auth/util";
import UnloadedApp from "./components/sections/UnloadedApp/UnloadedApp";
import {appLoadedDispatcher} from "./dispatcher/commonDispatcher";

import Alert from "./components/comun/AlertMessage/Alert";

import DialogManager from "./components/comun/DialogManager/DialogManager";

import {esES} from "@material-ui/core/locale";

import {Routes} from "./Routes";
import {CampaignContext} from "./components/comun/CampaignStyleContext/CampaignStyleContext";


let App = ({onConnect, campaign, onAccessTokenExpired, ...props}) => {
    let accessToken = localStorage.getItem('access_token');
    const themeCampaign = useContext(CampaignContext);
    const theme = createTheme({
        palette: {
            primary: {
                // light: will be calculated from palette.primary.main,
                main: themeCampaign.button.secondary.background,
                dark:  themeCampaign.button.secondary.background,
                light: themeCampaign.button.secondary.background,
                // dark: will be calculated from palette.primary.main,
                // contrastText: will be calculated to contrast with palette.primary.main
            },
            secondary: {
                light: palette.primary,
                main: themeCampaign.button.neutral.background,
                dark:  themeCampaign.button.neutral.hover,
                // dark: will be calculated from palette.secondary.main,
                contrastText: palette.light,
                text: palette.light
            },
            // Used by `getContrastText()` to maximize the contrast between
            // the background and the text.
            tonalOffset: 0.6,
        },
    }, esES);

    useEffect(() => {
        if (onConnect && accessToken !== null) {
            if (isAccessTokenExpired(accessToken)) {
                localStorage.removeItem('access_token');
                onAccessTokenExpired();
            } else {
                onConnect(accessToken);
            }
        } else {
            props.onAppLoaded();
        }
    }, [accessToken, onConnect, onAccessTokenExpired]);
    useEffect(() => {
        let at = localStorage.getItem('access_token');
        if (at) {
            console.log(isAccessTokenExpired(at));
            setInterval(() => {
                if (isAccessTokenExpired(at)) {
                    localStorage.removeItem('access_token');
                    onAccessTokenExpired();
                }
            }, 10000)
        }
    }, [])
    if (props.isAppLoaded === undefined) {
        return (
            <div className="App">
                <UnloadedApp/>
            </div>
        );
    }
    return (

        <ThemeProvider theme={theme}>
            <div className="App" style={{background: themeCampaign.background}}>
                <Routes/>
                <Footer/>
            </div>
            {(props.isLoading === undefined || props.isLoading === false) ? null : <Loading/>}
            <Alert/>
            <DialogManager/>

        </ThemeProvider>

    );
}

const mapDispatchToProps = dispatch => {
    return {
        onConnect: async (accessToken) => {
            await validateTokenAction(accessToken)(dispatch)
        },
        onAppLoaded: () => {
            dispatch(appLoadedDispatcher({loaded: true}));
        },
        onAccessTokenExpired: async () => {
            await refreshAccessTokenAction()(dispatch);
        }
    }
}
const mapPropsToState = state => {
    return {
        isAppLoaded: state.app.loaded,
        isLoading: state.loading.isLoading,
        campaign: state.campaign
    }
}


App = connect(mapPropsToState, mapDispatchToProps)(App);
export default App;
