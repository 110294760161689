import {validNif} from "../util/utilValid";
import axios from "axios";
import {envLoader} from "../env/envLoader";

const baseUrl = envLoader().base_url;

export const minLength = (length) => {
    return (value) => {
        if (typeof value === 'string') {
            return value.length < length ? `Tiene que tener al menos ${length} carácteres` : undefined;
        }
        return undefined;
    }
}

export const validateAction = value => {
    if (value === 'STAND') {
        return true;
    }
    
}


export const validatePhone = value => {
    if (value === '' || value === undefined) {
        return true;
    }
    const regex = /^([6,7,8,9]{1})\d{8}$/i;
    return regex.test(value);
}
export const validateSurname2 = value => {
    if (value === '' || value === undefined) {
        return true;
    }
    const regex = /^(?=.{3,18}$)[a-zñA-ZÑÁÉÍÓÚáéíóú](\s?[a-zñA-ZÑÁÉÍÓÚáéíóú])*$/;
    return regex.test(value);
}
export const validateEmail = value => {
    const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    return regex.test(value) ? null : 'El formato del email es inválido. Ejemplo: ejemplo@ejemplo.com';
}

export const required = value =>  {
    return (value && value.length > 0) ? undefined : 'Obligatorio'
}

export const maxLength = length => {
    return value => {
        if (typeof value === 'string') {
            return value.length > length ? `Tiene que tener menos de ${length} carácteres` : undefined;
        }
        return undefined;
    }
}

export const validateNif = value => {
    return validNif(value) ? null: 'El NIF no es válido';
}

export const validateUsername = value => {
    return axios.get(`${baseUrl}user/find?username=${value}`)
        .then(response => {
            return response.data.data === null;

        });
}

export const validateStandCode = value => {

    const regex = /^_\d{4}$/;

    return regex.test(value);
};

export const validatePassword = value => {

    if (value === '' || value === undefined) {
        return true;
    }

    const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@#$%^&+=!])(?=.{8,})/;
    return regex.test(value);

}

export const validateConfirmPassword = (value, value2) => {
        
        if(value=== value2) {
            return true;
        
        }else {
            return false;
        } 
}


export const validateCity = value => {

    if(value === undefined) {
        return false;
    
    }else{
        return true;
    }
}