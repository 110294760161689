import React, {useEffect, useRef, useState} from "react";
import './RegisterLead.scss';
import * as Yup from 'yup';
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import {getRegisterLeadConfigDispatch, registerLead} from "../LeadAction";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import {Field, withFormik} from "formik";
import {connect} from "react-redux";
import {Grid} from "../../../comun/Grid/Grid";
import MaterialTextField from "../../../comun/Forms/MaterialTextField";
import CheckboxField from "../../../comun/Forms/CheckboxField";
import CheckboxField2 from "../../../comun/Forms/CheckboxField2";
import {validatePhone,validateSurname2} from "../../../../validation/basicValidation";
import SelectField from "../../../comun/Forms/SelectField";
import {AutocompleteUser} from "../../../comun/AutocompleteUser/AutocompleteUser";
import {hasAnyRole, hasRole} from "../../../../auth/util";
import {filterStands} from "../../Stand/StandAction";
import {documentExists} from "../../Users/UserAction";
import {call} from "../../../../util/utilXhr";
import SignatureCanvas from "react-signature-canvas";
import {validCif, validNif, validNifNoAdamo} from "../../../../util/utilValid";
import {existsKeyInCampaign, getValueOrDefault} from "../../../../util/utilCampaign";
import {log} from "../../../../util/utilLog";
import {generateUuid} from "../../../../util/utilUuid";
import {confirmSmsEvent, sendSmsConfirmationEvent} from "../SmsAction";
import ButtonField from "../LeadFormComponents/ButtonField";
import { Typography, LinearProgress, Popover } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { set } from "date-fns";





const getPhoneConfirmedEnabled = (campaign, user) => {
    let phoneConfirmationEnabled = getValueOrDefault('lead.register.sms_confirmation.enable', campaign, 'false');
    phoneConfirmationEnabled = (phoneConfirmationEnabled === 'true');
    let roles = getValueOrDefault('lead.register.sms_confirmation.roles_excluded', campaign, '');
    roles = roles.split(',');
    return phoneConfirmationEnabled && !hasAnyRole(user, roles);
}

const getClause = (campaign) => {
    let clauseText = getValueOrDefault('lead.register.clause_Iberdrola', campaign, '');
    return clauseText;
}

const getCheckedValue = (clause) => {
    return clause.privacyClausePrivacyClauseValues.find(a => a.privacyClauseValue.value === "SI");
}
const getUnCheckedValue = (clause) => {
    return clause.privacyClausePrivacyClauseValues.find(a => a.privacyClauseValue.value === "Off");
}
const getCheckedValueInterested = (check) => {
    return check.interestedCheckInterestedCheckValues.find(a => a.interestedCheckValue.value === "SI");
}
const getUnCheckedValueInterested = (check) => {
    return check.interestedCheckInterestedCheckValues.find(a => a.interestedCheckValue.value === "NO");
}
export let RegisterLead = ({user, onRegisterLeadConfig, ...props}) => {

    const [documents, setDocuments] = useState([]);
    const [clauses, setClauses] = useState([]);
    const [checks, setChecks] = useState([]);
    React.useEffect(() => {
        onRegisterLeadConfig().then((res) => {
            setDocuments(res.documentTypeList);
            setClauses(res.privacyClauses);
            setChecks(res.interestedChecks);
        })
    }, []);
    return (
        <RegisterLeadForm documents={documents} clauses={clauses} defaultClauses={[...clauses]} checks={checks} defaultChecks={[...checks]}/>
    )
}

const mapRegisterLeadDispatchToProps = dispatch => {
    return {
        onRegisterLeadConfig: async () => {
            return await getRegisterLeadConfigDispatch()(dispatch);
        }
    }
}
RegisterLead = connect(null, mapRegisterLeadDispatchToProps)(RegisterLead);

export let RegisterLeadForm = ({
                                   onCheckDocument,
                                   onConfirmSms,
                                   campaign,
                                   clauses,
                                   checks,
                                   user,
                                   documents,
                                   getFieldValue,
                                   setFieldValue,
                                   setFieldError,
                                   isSubmitting,
                                   onSendSms,
                                   handleSubmit,
                                   values,
                                   defaultClauses,
                                   defaultChecks,
                                   setErrors,
                                   isValid,
                                   ...props
                               }) => {
    const getStandFilterParams = () => {
        const res = {};

        let key = 'teamLeader';
        if (hasRole(user, 'COMMERCIAL_PROMOTER')) {
            key = 'commercialPromoter';
        }
        res[key] = user.id;
        res.cleaned = true;
        return res;
    }
    const submit = (vals) => {
        log(`Empezando a registrar Lead de ${user.id}`, "info");
        if (canvasRef.isEmpty()) {
            setCanvasEmpty(true);
            vals.preventDefault();
            return false;
        } else {
            setCanvasEmpty(false);
            setFieldValue('sign', canvasRef.getTrimmedCanvas()
                .toDataURL('image/png').replace('data:image/png;base64,', ''))
            try {
                canvasRef.clear();
                handleSubmit(vals);
                if (Object.keys(values.clauses).length === 4) {
                    return;
                }
                setSmsconfirmed(false);
                canvasRef.clear();
            } catch (e) {
                console.log('Error' + e)

            }

        }
    }
    const [canvasWidth, setCanvasWidth] = useState(100);
    const [canvasHeight, setCanvasHeight] = useState(56);
    const [canvasEmpty, setCanvasEmpty] = useState(false);
    const [canvasRef, setCanvasRef] = useState();
    const [smsCodeError, setSmsCodeError] = useState(false);
    const [smsConfirmed, setSmsconfirmed] = useState(false);
    const [cardbonoError, setCardbonoError] = useState(false);
    const [isLoadingImage, setIsLoadingImage] = useState(false);
    const [showingHelp, setShowingHelp] = useState(false);
    const [required, setRequired] = useState(false);


    const showcardbonoError = (error, loadingImage) => {
        setCardbonoError(error);
        setIsLoadingImage(loadingImage)
    }

    const getCheckedValueInterestedAll = (checks) => {
        let valores = []
        checks.map(check =>{
           
            valores.push(check.interestedCheckInterestedCheckValues.find(a => a.interestedCheckValue.value === "SI"))
        })
        console.log(valores)
        return valores;
    }
    
    const getUnCheckedValueInterestedAll = (checks) => {
        let valores = []
        checks.map(check =>{
            valores.push(check.interestedCheckInterestedCheckValues.find(a => a.interestedCheckValue.value === "NO"))
        })
        return valores;
    }

    const documentRef = useRef(null);
    React.useEffect(() => {
        let franja = getFranjas();
        if (franja.length === 0) {
            return;
        }
        franja = franja.find((f) => f.franja === values.franjas);
        setFieldValue('contactHourLast', franja.horaHasta);
        setFieldValue('contactHour', franja.horaDesde);
    }, [values.franjas])
    const resetCanvas = () => {
        canvasRef.clear();
    }

    const validateDocument = (value) => {
        return onCheckDocument(value, setFieldError);
    };
    React.useEffect(() => {
        setTimeout(() => {
            const elem = document.getElementById("canvas-container");
            if (elem !== null) {
                const width = Math.min(500, elem.getBoundingClientRect().width - 10);
                const heigth = Math.min(((width * 9) / 16), 200);
                setCanvasWidth(width);
                setCanvasHeight(heigth);
            }

        }, 100);
        if (Object.keys(values.clauses).length === 4) {
            return;
        }
        const negativeDefaultClauses = {};
        clauses.forEach(clause => {
            negativeDefaultClauses[clause.id] = getUnCheckedValue(clause);
        });
        setFieldValue('clauses', negativeDefaultClauses);
    }, [clauses]);

    React.useEffect(() => {
        setTimeout(() => {
            const elem = document.getElementById("canvas-container");
            if (elem !== null) {
                const width = Math.min(500, elem.getBoundingClientRect().width - 10);
                const heigth = Math.min(((width * 9) / 16), 200);
                setCanvasWidth(width);
                setCanvasHeight(heigth);
            }

        }, 100);
        if (Object.keys(values.checks).length === 4) {
            return;
        }
        const defaultChecksInterested = {};
        checks.forEach(check => {
            if (check.id == 4){
                defaultChecksInterested[check.id] = getCheckedValueInterested(check);
               
            }else{
                defaultChecksInterested[check.id] = getUnCheckedValueInterested(check);
            }
        });
        setFieldValue('checks', defaultChecksInterested);
    }, [checks]);

    useEffect(() => {
        if (values.hash === '') {
            setFieldValue('hash', generateUuid());
        }
    }, []);
    const getFranjas = () => {
        if (existsKeyInCampaign('create_lead.franjas', campaign)) {
            return JSON.parse(getValueOrDefault('create_lead.franjas', campaign));
        }
        return [];
    }
    const clearContactHourLast = (e) => {
        setFieldValue('contactHourLast', '');
    }

    const getSingleHome = (campaign) => {
        let options = getValueOrDefault('single_family_home', campaign, '');
    
        return options;
    }


    const getBonusCard = () => {
        if (existsKeyInCampaign('create_lead.target_bonus.data', campaign)) {
            let data = getValueOrDefault('create_lead.target_bonus.data', campaign, '{}');
            data = JSON.parse(data);
            return Object.values(data);
        }
        return [];
    }
    const sendSms = () => {
        onSendSms({hash: values.hash, phone: values.phone}).then(a => {
            setFieldValue('smsSended', true);
        }).catch(e => {

        })
    }

    const confirmSmsAction = () => {
        const code = values.confirmPhone;
        onConfirmSms({code, hash: values.hash}).then(res => {
            if (res === true) {
                setFieldValue('smsConfirmed', true);
                setSmsconfirmed(true);
                setSmsCodeError(false);
            } else {
                setSmsconfirmed(false);
                setSmsCodeError(true);
            }
        }).catch(e => {

        })
    }
    let phoneConfirmationEnabled = getPhoneConfirmedEnabled(campaign, user);
    const getStyleConfirmPhone = () => {
        return (values.smsSended === true && !values.smsConfirmed) ? {display: 'block'} : {display: 'none'};
    }
    const isValidPhoneConfirmed = () => {
        if (phoneConfirmationEnabled === true) {
            return smsConfirmed && isValid;
        }
        return isValid;
    }

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const openPopover = Boolean(anchorEl);
    const popoverId = openPopover ? 'simple-popover' : undefined;
    const expresiones = {
        apellido2: /^[a-zA-Z]$/
    }


    const singleHome = getSingleHome(campaign).split(",");

        
    return (
        <div className="box">
            <h1>Alta de Lead</h1>
            <Divider/>
            <form onSubmit={submit}>
                <Grid className="register-lead-form-block" columns={1} columnGap="0" rowGap="15px">
                    <h2>Datos Personales</h2>
                    <Divider/>
                    <Field name="name" required
                           label="Nombre" component={MaterialTextField} variant="outlined"/>
                    <Field name="surname1" component={MaterialTextField}
                           label="Primer Apellido" variant="outlined"/>
                    <Field name="surname2" required component={MaterialTextField}
                           label="Segundo Apellido"  variant="outlined"/>
                    <Field name="documentType" variant="outlined" required
                           label="Selecciona el Tipo de Documento"
                           component={SelectField}
                           errortext="Escoge un tipo de Documento" labelId="register-lead-document-type-label"
                           onChangeField={() => {
                               setFieldValue('document', '');
                           }}
                    >
                        {documents.map((documentType) => {
                            if( documentType.name == 'CIF' || documentType.name == 'NIF' || documentType.name == 'NIE'){
                            return <MenuItem key={documentType.name}
                                             value={documentType.idEvent}>{documentType.showName}</MenuItem>
                            }
                        })}
                    </Field>
                    <Field name="document" disabled={values.documentType === undefined || values.documentType === ''}
                           required variant="outlined"
                           component={MaterialTextField} type="text"
                           innerRef={documentRef}
                           label="Documento"
                    />
                    <Field name="codigoPostal" required
                           label="Código Postal" component={MaterialTextField} variant="outlined"/>
                    
                    <Field name="singleFamilyHome" variant="outlined"
                        required
                           label="Vivienda Unifamiliar"
                           component={SelectField}
                           errortext="Seleccione una opcion" labelId="register-lead-single-family-home-label"
                    >
                        {singleHome.map((option, index) => {
                            const value = option === 'Si' ? true : false;
                            return (
                                <MenuItem key={index} value={value}>
                                    {option}
                                </MenuItem>
                            );
                        })}
                    </Field>
                    <h2>Datos de Contacto</h2>
                    <Divider/>
                    {phoneConfirmationEnabled ?
                        <div className={'phone-field'}>
                            <Field name="phone" disabled={values.smsSended ? true : false} required variant="outlined"
                                   component={MaterialTextField} type="telefono"
                                   label="Teléfono" helperText="Se tendrá que confirmar por SMS el teléfono"/>
                            {values.smsSended === false ?
                                <React.Fragment>
                                    <div>
                                        <Button disabled={props.errors.phone ? true : false} onClick={sendSms}
                                                variant="contained" color="secondary" type="button">
                                            Enviar SMS
                                        </Button>
                                    </div>
                                </React.Fragment>
                                : null}
                            <React.Fragment>
                                <div style={getStyleConfirmPhone()}>
                                    <Field name="confirmPhone" required variant="outlined" component={MaterialTextField}
                                           type="telefono"
                                           label="Código de confirmación de teléfono"/>
                                    <a onClick={sendSms} className="cursor">
                                        Volver a enviar SMS
                                    </a>
                                </div>
                                <div><Button variant="contained" color="secondary" type="button"
                                             onClick={confirmSmsAction} style={getStyleConfirmPhone()}>
                                    Confirmar
                                </Button>
                                    {smsCodeError ? <div className="error-form">El código no es correcto</div> : null}
                                </div>
                            </React.Fragment>

                        </div>
                        : <Field name="phone" required variant="outlined" component={MaterialTextField} type="telefono"
                                 label="Teléfono" helperText="Debes er un número de teléfono válido"/>}
                    <Field name="email" required={required}  variant="outlined" component={MaterialTextField} type="email"
                           label="Email"/>
                    <h2>Interesado en:</h2>
                    <Divider/>
                    <FormGroup>
                        
                        {checks.map((check, index) => {
                                return (
                                    
                                    <Field 
                                           key={index}
                                           name={`checks[${check.id}]`}
                                           component={CheckboxField2}
                                           arrayindex="checks"
                                           index={check.id}
                                           label={check.description} 
                                           requiredFunction={setRequired}
                                           checkedValue={() => {
                                   return  getCheckedValueInterestedAll(checks)                                     
                                }}
                                           uncheckedValue={() => {
                                               return getUnCheckedValueInterestedAll(checks)
                                           }}></Field>

                                          
                            )

                        })}                               
                    </FormGroup>
                    <h2>Datos de Contrato</h2>
                    <Divider/>
                    <Field name="company" variant="outlined" component={MaterialTextField} type="text"
                           label="Compañia"/>
                    <Field name="contractHolder" variant="outlined" component={MaterialTextField} type="text"
                           label="Titular del contrato"/>
                    {/*<Field name="contactHour" variant="outlined" component={MaterialTextField}*/}
                    {/*       type="time"*/}
                    {/*       label="Hora de contacto desde"*/}
                    {/*       InputLabelProps={{*/}
                    {/*           shrink: true,*/}
                    {/*       }}*/}
                    {/*/>*/}
                    <Field name="franjas" variant="outlined" required
                           label="Franja Horarias de contacto"
                           component={SelectField}
                           errortext="Selecciona una franja horaria de contacto"
                           labelId="register-lead-document-type-label"
                    >
                        {getFranjas().map((v, k) => {
                            return <MenuItem key={k}
                                             value={v.franja}>{v.franja}</MenuItem>
                        })}

                    </Field>
                    {/*<Field name="contactHourLast" variant="outlined" component={MaterialTextField}*/}
                    {/*       type="time"*/}
                    {/*       InputLabelProps={{*/}
                    {/*           shrink: true,*/}
                    {/*       }}*/}
                    {/*       label="Hora de contacto hasta"/>*/}
                    {existsKeyInCampaign('create_lead.target_bonus.data', campaign) ?
                        <React.Fragment>
                            <h2>Tarjeta Bono</h2>
                            <Field name="cardbono" variant="outlined" required
                                   label="Tarjeta Bono"
                                   component={SelectField}
                                   errortext="Selecciona una tarjeta bono" labelId="register-lead-document-type-label"
                            >
                                {getBonusCard().map((v, k) => {
                                    return <MenuItem key={k}
                                        value={v}>{v}</MenuItem>
                                })}

                            </Field>
                        </React.Fragment>
                        : null}

                    <h2>Stand</h2>
                    <Field name="stand"
                        required
                        label="Elige un stand"
                        labelAttr='mall'
                        component = {AutocompleteUser}
                        load={filterStands}
                        loadParams={getStandFilterParams}
                        variant="outlined"
                        defaultFirst={true}
                        className="form-control"
                    />

                    <h2>Tarjeta regalo
                        <Button aria-describedby={popoverId} size="small" variant="secondary" type="button" onClick={handleClick} >
                            <WarningIcon className={'help-icon-button'} />
                        </Button>
                    </h2>

                    <Popover
                        id={popoverId}
                        open={openPopover}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <Typography className={'help-warning-text'}sx={{ p: 2 }}>
                            Para que el código de la imagen se procese correctamente, se debe acercar la cámara al código y evitar lo máximo posible la captura de letras.
                        </Typography>
                    </Popover>

                        <Field name="cardbono"
                            label="Introduzca el código de la tarjeta"
                            labelAttr='mall'
                            component={MaterialTextField}
                            variant="outlined"
                            type="text"
                            className="form-control"
                        />

                    {cardbonoError ?
                     <div className="error-form">{cardbonoError}</div>
                     :
                     isLoadingImage ?
                     <LinearProgress />
                     :
                     null
                    }

                    <Field name="cardbono"
                                    label="subir imagen"
                                    className="form-control"
                                    component={ButtonField}
                                    errorFunction={showcardbonoError}
                                    variant="outlined"
                                />

                    <Divider/>
                    <h2>Observaciones</h2>
                    <Field name="observations" variant="outlined" component={MaterialTextField} type="text"
                           label="Observaciones" multiline={true} rowsMax={14} rows={8}/>
                    <Divider/>
                    <h2>Clausulas de Privacidad</h2>
                    <Divider/>
                    <FormGroup>
                        {clauses.map((clause, index) => {
                            return (<Field key={index}
                                           name={`clauses[${clause.id}]`}
                                           component={CheckboxField}
                                           arrayindex="clauses"
                                           index={clause.id}
                                           label={clause.description} checkedValue={() => {
                                    return getCheckedValue(clause)
                                }}
                                           uncheckedValue={() => {
                                               return getUnCheckedValue(clause)
                                               
                                           }}/>
                            )
                        })}
                    {getClause(campaign)}
                    </FormGroup>
                     <Divider/>
                    <h2>FIRMA DEL CLIENTE (lo más parecido a la del DNI)</h2>
                    <Divider/>
                    <div id="canvas-container">
                        <p>Intenta rellenar todo el cuadro</p>
                        <div className="canvas-container">
                            <SignatureCanvas penColor='black'
                                             ref={(ref) => setCanvasRef(ref)}
                                             canvasProps={{
                                                 width: canvasWidth,
                                                 height: canvasHeight,
                                                 className: 'sigCanvas'
                                             }}/>
                        </div>
                        {canvasEmpty ? <p className="error-form">
                            La firma está vacia
                        </p> : null}
                        <Button variant="contained" color="primary" type="button" onClick={resetCanvas}>Limpiar
                            Firma</Button>
                    </div>

                </Grid>
                <div style={{margin: '15px'}}>
                    {!smsConfirmed && phoneConfirmationEnabled ? <p className="error-form">
                        Debes confirmar el número de teléfono por SMS.
                    </p> : null}
                </div>
                <Button className="register-user-button"
                        disabled={!isValid}
                        variant="contained" color="secondary" type="submit">Registrar
                    Lead</Button>
            </form>
        </div>
    )
}
const schema = ({user, campaign, props}) => {
    const getConfiguracionCardBono = () => {

        if (existsKeyInCampaign('create_lead.cardbono.config',campaign)){
           return getValueOrDefault('create_lead.cardbono.config', campaign);
        }
        return;
    }

    const getConfiguracionCardCheck1 = () => {

        if (existsKeyInCampaign('create_lead.checks[1].config',campaign)){
           return getValueOrDefault('create_lead.checks[1].config', campaign);
        }
        return;
    }
    const getConfiguracionCardCheck2 = () => {

        if (existsKeyInCampaign('create_lead.cardcheck2.config',campaign)){
           return getValueOrDefault('create_lead.cardcheck2.config', campaign);
        }
        return;
    }
    const getConfiguracionCardCheck3 = () => {

        if (existsKeyInCampaign('create_lead.cardcheck3.config',campaign)){
           return getValueOrDefault('create_lead.cardcheck3.config', campaign);
        }
        return;
    }

    const fields = {
        name: Yup.string().required('Obligatorio'),
        document: Yup.string().required('Obligatorio').test('document', 'No se ha validado el documento',
            function (value) {
                const {documentType} = this.parent;
                if (documentType == 2 || documentType == 3) {
                    return validNifNoAdamo(value);
                }
                if (documentType === 1) {
                    return validCif(value);
                }
                return true;
            }),
        surname1: Yup.string(),
        surname2: Yup.string().required('Obligatorio').test('is-surname','No es un formato válido',value => validateSurname2(value)),
        observations: Yup.string().test('len', 'No puede contener mas de 150 carácteres', val => {
            if (!val) {
                return true;
            }
            return val.length <= 150
        }),
        cardbono: Yup.string().matches(new RegExp(getConfiguracionCardBono()), 'Formato incorrecto'),
        cardcheck1: Yup.string().matches(new RegExp(getConfiguracionCardCheck1()), 'Formato incorrecto'),
        cardcheck2: Yup.string().matches(new RegExp(getConfiguracionCardCheck2()), 'Formato incorrecto'),
        cardcheck3: Yup.string().matches(new RegExp(getConfiguracionCardCheck3()), 'Formato incorrecto'),
        cardbonoImage: Yup.string(),
        documentType: Yup.string().required('Obligatorio'),
        hash: Yup.string(),
        confirmPhone: Yup.string().required(''),
        email: Yup.string().email('Debe ser un email válido'),
        company: Yup.string(),
        contractHolder: Yup.string(),
        codigoPostal: Yup.string().required('Obligatorio').test('len', 'No puede contener mas de 5 carácteres', val => {
            if (!val) {
                return true;
            }
            return val.length <= 5
        }),
        phone: Yup.string().required('Obligatorio').test('is-phone', "No es un número de teléfono válido", value => validatePhone(value)),
        stand: Yup.object().required('Obligatorio').nullable(),
        contactHour: Yup.string(),
        franjas: Yup.string(),
        contatHourLast: Yup.string(),
        singleFamilyHome: Yup.string().required('Obligatorio'),
    }
    let phoneConfirmationEnabled = getPhoneConfirmedEnabled(campaign, user);
    if (phoneConfirmationEnabled) {
        fields.smsConfirmed = Yup.boolean().required('Debes confirmar el teléfono');
        fields.smsSended = Yup.boolean(false)
        fields.cardbono = Yup.string()
    }
    const res = Yup.object().shape(fields);

    return res;
}

RegisterLeadForm = withFormik(
    {
        validationSchema: schema,
        mapPropsToValues: (props) => {
            let phoneConfirmationEnabled = getPhoneConfirmedEnabled(props.campaign, props.user);
            let clauseText = getClause(props.campaign);
            const fields = {
                name: '',
                surname1: '',
                surname2: '',
                documentType: '',
                document: '',
                codigoPostal: '',
                phone: '',
                email: '',
                checks: {},
                cardbono: '',
                cardcheck1: '',
                cardcheck2: '',
                cardcheck3: '',
                cardbonoImage: '',
                contactHourLast: '',
                franjas: 'LLAMAR YA',
                hash: '',
                smsSended: false,
                company: '',
                contractHolder: '',
                clauses: {},
                contactHour: '',
                stand: {},
                singleFamilyHome: ''
            };
            fields.cardbono = '';
            fields.cardcheck1 = '';
            fields.cardcheck2 = '';
            fields.cardcheck3 = '';
            fields.smsSended = false;
            fields.smsConfirmed = false;
            return fields;
        },
        handleSubmit: async (vals, props) => {
            props.setSubmitting(false);
            const values = {...vals};
            console.log(values.stand);
            if (values.stand !== null) {
                if (values.stand.id !== 0) {
                    values.stand = vals.stand.id;
                } else {
                    values.stand = null;
                }
            }

            if(values.stand == null && props.props.user.stand!= null){
                values.stand = props.props.user.stand.id;
            }

            console.log(values)
            log(`Enviando Lead`, "info");
            return await props.props.onLeadRegister(values)
                .then((e) => {
            
                    props.resetForm();
                    props.setFieldValue('observations', '');
                    props.setFieldValue('hash', generateUuid());
                    props.setFieldValue('confirmPhone', '');
                    props.setFieldValue('stand', {id: vals.stand.id});

                

                    const negativeDefaultClauses = {};
                    const clauses = props.props.defaultClauses;
                    clauses.forEach(clause => {
                        negativeDefaultClauses[clause.id] = getUnCheckedValue(clause);
                    });
                    props.setFieldValue('clauses', negativeDefaultClauses);
                    const defaultChecksInterested = {};
                    const checks = props.props.defaultChecks;
                    checks.forEach(check => {
                        if (check.id == 4){
                            defaultChecksInterested[check.id] = getCheckedValueInterested(check);
                        }else{
                            defaultChecksInterested[check.id] = getUnCheckedValueInterested(check);
                        }
                    });
                    props.setFieldValue('checks', defaultChecksInterested);
                    props.setFieldValue('observations', '');
                    log(`Lead Enviado con éxito`, "info");
                });
        },
        displayName: 'Registrar Lead',
    }
)(RegisterLeadForm);

const mapDispatchToProps = dispatch => {
    let timer = null;
    return {
        onLeadRegister: async data => {
            return await registerLead(data)(dispatch)
        },
        onSendSms: async data => {
            return await sendSmsConfirmationEvent(data)(dispatch)
        },
        onConfirmSms: async data => {
            return await confirmSmsEvent(data)(dispatch)
        },
        onCheckDocument: (document, setFieldError) => {
            let value = undefined;
            if (document.target) {
                value = document.target.value;
            } else {
                value = document;
            }

            clearTimeout(timer);
            timer = setTimeout(() => call(
                () => {
                    return documentExists(value).then((res) => {
                        setFieldError('document', 'El documento ya está dado de alta')
                        return 'El documento ya está registrado';
                    }).catch((err) => {
                        setFieldError('document', null);
                        return null;
                    })
                }
                , 'check-document', false)(dispatch), 1000);

        }
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        campaign: state.campaign
    }
}


RegisterLeadForm = connect(mapStateToProps, mapDispatchToProps)(RegisterLeadForm);
export default RegisterLeadForm;




