
import loginReducer from './LoginReducer'
import {combineReducers} from "redux";
import loadingReducer from "../components/comun/Loading/loadingReducer";
import {userReducer} from "../auth/auth";
import UserProfileReducer from "./UpdateProfileReducer";
import DialogManagerReducer from "./DialogManagerReducer";
import RegisterUserReducer from "./RegisterUserReducer";
import AppReducer from "./AppReducer";
import AsignTeamLeaderReducer from "./AsignTeamLeaderReducer";
import LeadReducer from "./LeadReducer";
import AlertReducer from "./AlertReducer";
import CityReducer from "./CityReducer";
import StandReducer from "./StandReducer";
import ZoneReducer from "./ZoneReducer";
import RegisterLeadReducer from "./RegisterLeadReducer";
import campaignReducer from "./CampaignReducer";

export default combineReducers({
    login: loginReducer,
    loading: loadingReducer,
    user: userReducer,
    app: AppReducer,
    userRegister: RegisterUserReducer,
    updateProfile: UserProfileReducer,
    dialogs: DialogManagerReducer,
    asignUser: AsignTeamLeaderReducer,
    lead: LeadReducer,
    registerLead: RegisterLeadReducer,
    alert: AlertReducer,
    campaign: campaignReducer,
    city: CityReducer,
    stand: StandReducer,
    zone: ZoneReducer
});
