import React from "react";
import {LeadList} from "../LeadList/LeadList";
import {getLeads} from "../LeadAction";
import {openDialogAction} from "../../../comun/DialogManager/DialogManagerAction";
import {connect} from "react-redux";
import {getRoleForm} from "../../../../auth/util";
import {AreaCoordinatorLeadFilters} from "../../AreaCoordinator/AreaCoordinatorLeadFilters/AreaCoordinatorLeadFilters";
import {TeamLeaderLeadFilters} from "../../TeamLeader/TeamLeaderLeadFilters/TeamLeaderLeadFilters";

export let ManageLeadsTeamLeader = ({user, leads,...props}) => {
    const initialValues = {};
    const key = getRoleForm(user);
    initialValues[key] = user.id;
    return (
        <LeadList callback={getLeads} listTitle="Gestionar Leads" initFilter={initialValues} manage={true} LeadFilterComponent={TeamLeaderLeadFilters}/>
    )
}
const mapPropsToState = state => {
    return {
        user: state.user
    }
}

ManageLeadsTeamLeader = connect(mapPropsToState, null)(ManageLeadsTeamLeader);
