import React from "react";
import { Field, withFormik } from "formik";
import { Grid } from "../../../comun/Grid/Grid";
import * as Yup from "yup";
import CheckboxField from "../../../comun/Forms/CheckboxField";
import Button from "@material-ui/core/Button";
import MaterialTextField from "../../../comun/Forms/MaterialTextField";
import { connect } from "react-redux";
import { existsKeyInCampaign, getValueOrDefault } from "../../../../util/utilCampaign";

import SelectField from "../../../comun/Forms/SelectField";
import MenuItem from "@material-ui/core/MenuItem";

import DatePickerField from "../../../comun/Forms/DatePickerField";
import HasRole from "../../../../auth/components/HasRole/HasRole";



export let MyLeadListFilters = ({ handleSubmit, manage = false, user, ...props }) => {

    const campaingName = user.campaign.name;
    console.log(user.campaign);

    React.useEffect(() => {
        let tipo = getPacientes();
        console.log(tipo);
        let franja = getFranjas();
        console.log(franja);
    }, [])

    const getPacientes = () => {
        if (existsKeyInCampaign('create_pacientes', user.campaign)) {
            console.log(JSON.parse(getValueOrDefault('create_pacientes', user.campaign)));
            return JSON.parse(getValueOrDefault('create_pacientes', user.campaign));
        }
        return [];
    }

    const getFranjas = () => {
        if (existsKeyInCampaign('create_lead.franjas', user.campaign)) {
            console.log(JSON.parse(getValueOrDefault('create_lead.franjas', user.campaign)));
            return JSON.parse(getValueOrDefault('create_lead.franjas', user.campaign));
        }
        return [];
    }


    return (
        <React.Fragment>
            <form className="form-filters" onSubmit={handleSubmit}>
                <h3>Filtros</h3>
                <Grid columns={2} mobileColumns="1fr">
                    <Field className="form-filters-text" size="small" component={MaterialTextField} name="name" variant="outlined" label="Nombre completo" />
                    <Field className="form-filters-text" size="small" component={MaterialTextField} name="document" variant="outlined" label="Documento de Identidad" />
                    <Field className="form-filters-text" size="small" component={MaterialTextField} name="phone" variant="outlined" label="Teléfono" />

                    {campaingName === "Vivanta" || campaingName === "Vitaldent" || campaingName === "KonectaDental" || campaingName === "Sicor" ?
                        <br></br>
                        :
                        <Field className="form-filters-text" size="small" component={MaterialTextField} name="company" variant="outlined" label="Compañia" />
                    }


                    {/*}
                    {campaingName === "Vivanta" || campaingName === "Vitaldent" || campaingName === "KonectaDental"?
                    <Field name="pacientesInteresados" variant="outlined" 
                           label="Número de Pacientes interesados"
                           component={SelectField}                                                                              
                         
                          
                    >                      
                        
                        {getPacientes().map((pacientesInteresados) => {
                            
                            return <MenuItem key={pacientesInteresados.id}
                                             value={pacientesInteresados.nombre}>{pacientesInteresados.nombre}</MenuItem>
                            
                        })}
                    </Field>
                    :
                    null
                    }
                    */}

                    {campaingName === "Vivanta" || campaingName === "Vitaldent" || campaingName === "KonectaDental" || campaingName === "Sicor" ?

                        <Field name="fechaDesde"
                            label="Fecha Desde" className="form-filters-text"
                            component={DatePickerField} variant="outlined" />
                        :
                        null
                    }

                    {campaingName === "Vivanta" || campaingName === "Vitaldent" || campaingName === "KonectaDental" || campaingName === "Sicor" ?

                        <Field name="fechaHasta"
                            label="Fecha Hasta" className="form-filters-text"
                            component={DatePickerField} variant="outlined" />
                        :
                        null
                    }

                    <HasRole role="FL_MANAGER,ADMIN">
                        {campaingName === "Iberdrola" ?

                            <Field name="fechaDesde"
                                label="Fecha Desde" className="form-filters-text"
                                component={DatePickerField} variant="outlined" />
                            :
                            null
                        }

                        {campaingName === "Iberdrola" ?

                            <Field name="fechaHasta"
                                label="Fecha Hasta" className="form-filters-text"
                                component={DatePickerField} variant="outlined" />
                            :
                            null
                        }

                        {/* {campaingName === "Iberdrola" ?
                            <Field className="form-filters-text" size="small"
                                component={CheckboxField} name="descarga" variant="outlined" label="Descargar Excel"
                            />
                            : null
                        } */}
                    </HasRole>

                    {campaingName != "Vivanta" && campaingName != "Vitaldent" && campaingName != "KonectaDental" && campaingName !== "Sicor" ?
                        <Field className="form-filters-text" size="small" component={CheckboxField} name="sale" variant="outlined" label="Venta" />
                        :
                        null
                    }


                    {campaingName === "Vivanta" || campaingName === "Vitaldent" || campaingName === "KonectaDental" || campaingName === "Sicor" ?
                        <Field className="form-filters-text" size="small"
                            component={CheckboxField} name="descarga" variant="outlined" label="Descargar Excel"
                        />
                        : null
                    }

                    <Button variant="contained" color="secondary" type="submit">Filtrar</Button>
                </Grid>

            </form>
        </React.Fragment>
    )
}

const schema = Yup.object().shape({
    name: Yup.string(),
    document: Yup.string(),
    phone: Yup.string(),
    company: Yup.string(),
    status: Yup.string(),
    pacientesInteresados: Yup.string(),
    franja: Yup.string(),
    fechaDesde: Yup.string(),
    fechaHasta: Yup.string(),
    sale: Yup.boolean(),
    descarga: Yup.boolean()
});

MyLeadListFilters = withFormik(
    {
        validationSchema: schema,
        mapPropsToValues: (props) => {
            return {
                name: '',
                document: '',
                teleofno: '',
                company: '',
                status: '',
                sale: false,
                pacientesInteresados: '',
                franja: '',
                fechaDesde: '',
                fechaHasta: '',
                descarga: false
            }
        },
        handleSubmit: async (vals, props) => {
            props.setSubmitting(false);
            props.props.onSubmit(vals);

        },
        displayName: 'Filtrar Lead',
    }
)(MyLeadListFilters);
const mapPropsToState = state => {
    return {
        user: state.user
    }
}

MyLeadListFilters = connect(mapPropsToState, null)(MyLeadListFilters);
