import {ASIGN_TEAM_LEADER_USER, SELECT_ASIGN_USER} from "../types/types";

export  default function UserProfileReducer (state  = {},  action) {
    let res;
    switch (action.type) {
        case SELECT_ASIGN_USER:
            res =  Object.assign({}, state, action.payload);
            return res;
        case ASIGN_TEAM_LEADER_USER:
            res =  Object.assign({}, state, action.payload);
            return res;
        default:
            return state;
    }
}
