
export const defaultStyles =
    {
        background: '#062653 linear-gradient(90deg,#062653,#2ba3ce)',
        button: {
            neutral:{
                background: '#062653',
                hover: '#2ba3ce'
            },
            secondary: {
                background: '#f5c067',
                color: '#000'
            }
        }
    }
