import React, {useRef} from "react";
import LeftPanel from "../LeftPanel/LeftPanel";
import './Dashboard.scss'
import {Main} from "../Main/Main";
import Header from "../Header/Header";
import {connect} from "react-redux";
import {menuHideDispatcher, menuShowDispatcher} from "../../../dispatcher/commonDispatcher";
import {withRouter} from "react-router-dom";
import {ErrorBoundary} from "../../comun/ErrorBoundary/ErrorBoundary";
import {UpdateConnectionTime} from "../../comun/UpdateConnectionTime/UpdateConnectionTime";

let Dashboard = (props)  =>{
    const leftPanelRef = useRef();

    React.useEffect(() => {
        props.onClickMenu(true);
    }, [props.location.pathname]);
    const openMenu = () => {
        props.onClickMenu(props.menuOpened);
    }
    return (
        <div>
        <Header />
        <Main>
            <UpdateConnectionTime />
        <div className={'dashboard '.concat(props.menuOpened ? 'dashboard-closed' : '')}>
            <div className={'dashboard-left-panel '.concat(props.menuOpened === false ? 'dashboard-left-panel-closed' : 'dashboard-left-panel-opened')} ref={leftPanelRef}>
                <LeftPanel />
            </div>
            <div className="dashboard-main">
                <ErrorBoundary>
                {props.children}
                </ErrorBoundary>
            </div>
            {props.menuOpened === true ?    <div className="overlay-dashboard" onClick={openMenu}></div> : null }

        </div>
        </Main>
        </div>
    )
}

const mapPropsToState = state => {
    if (state.app.menu !== undefined) {
        return {menuOpened: state.app.menu.open}
    }
    return {
        menuOpened:false,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onClickMenu: (status) => {
            if (status === true) {
                dispatch(menuHideDispatcher({}));
                return;
            }

            dispatch(menuShowDispatcher({}));
        },

    }
}


Dashboard = connect(mapPropsToState, mapDispatchToProps)(Dashboard);
Dashboard = withRouter(Dashboard);
export default Dashboard;
