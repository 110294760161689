import axios from "axios";
import {envLoader} from "../../../env/envLoader";
import {loadingDispatcher} from "../../comun/Loading/loadingAction";
import {openAlertAction} from "../../comun/AlertMessage/AlertAction";
const baseUrl = envLoader().base_url;

export const filterTeamLeaders = async (data) => {
    return await axios.post(`${baseUrl}team_leader/filter`, data).then(res => res.data.data);
}
export const getTeamLeaders  = (values) => {
    return async (dispatch) =>  {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
        return await filterTeamLeaders(values)
            .then( response => {
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));
                return response;
            }).catch(reason => {
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true}));
                dispatch(openAlertAction({
                    id: 'error-register-lead', severity: 'error',
                    message: "Hubo un error al intentar cargar los usuarios, por favor inténtalo de nuevo", title:'Error'
                }));
                throw reason;
            })
    }
}

