export const validCif = (cif) => {
    if (!cif || cif.length !== 9) {
        return false;
    }

    const letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
    const digits = cif.substr(1, cif.length - 2);
    const letter = cif.substr(0, 1);
    const control = cif.substr(cif.length - 1);
    let sum = 0;
    let i;
    let digit;

    if (!letter.match(/[A-Z]/)) {
        return false;
    }

    for (i = 0; i < digits.length; ++i) {
        digit = parseInt(digits[i], 10);

        if (isNaN(digit)) {
            return false;
        }

        if (i % 2 === 0) {
            digit *= 2;
            if (digit > 9) {
                digit = parseInt(String(digit / 10), 10) + (digit % 10);
            }

            sum += digit;
        } else {
            sum += digit;
        }
    }

    sum %= 10;
    if (sum !== 0) {
        digit = 10 - sum;
    } else {
        digit = sum;
    }

    if (letter.match(/[ABEH]/)) {
        return String(digit) === control;
    }
    if (letter.match(/[NPQRSW]/)) {
        return letters[digit] === control;
    }

    return String(digit) === control || letters[digit] === control;
};

export const validNif = (nif) => {
    //nif = nif.toUpperCase();
    if (!nif || nif.length !== 9) {
        return false;
    }  

    const nieMap = {X: 0, Y: 1, Z: 2};
    const nifMap = 'TRWAGMYFPDXBNJZSQVHLCKE';
    const firstChar = nif.substring(0, 1);
    if (firstChar in nieMap) {
        nif = nieMap[firstChar] + nif.substring(1, nif.length);
    }

    var cadenaNumerica = nif.substring(0, nif.length - 1);
    var noNumerico = isNaN(cadenaNumerica) 

    if (noNumerico){
        return false;
    }
    
    const numbers = parseInt(nif.substring(0, nif.length - 1), 10);
    const letra = nif.charAt(8);
    const i = numbers % 23;
    return letra === nifMap.charAt(i);
};

export const validNifNoAdamo = (nif) => {
    //nif = nif.toUpperCase();   

    if (!nif || nif.length !== 9) {
        return false;
    }

    if (nif == '00000000T' || nif == '11111111H'){
        return false;
    }

    const nieMap = {X: 0, Y: 1, Z: 2};
    const nifMap = 'TRWAGMYFPDXBNJZSQVHLCKE';
    const firstChar = nif.substring(0, 1);
    if (firstChar in nieMap) {
        nif = nieMap[firstChar] + nif.substring(1, nif.length);
    }

    var cadenaNumerica = nif.substring(0, nif.length - 1);
    var noNumerico = isNaN(cadenaNumerica) 

    if (noNumerico){
        return false;
    }

    const numbers = parseInt(nif.substring(0, nif.length - 1), 10);
    const letra = nif.charAt(8);
    const i = numbers % 23;
    return letra === nifMap.charAt(i);
};

export const validCCC = (ccc) => {
    if (ccc.length !== 20) {
        return false;
    }
    ccc = ccc.replace(/-/g, '').replace(/ /g, '');
    const firstFourNumbers = ccc.substring(0, 4);
    const secondFourNumbers = ccc.substring(4, 8);
    const controlDigits = ccc.substring(8, 10);
    const lastTenNumbers = ccc.substring(10, 20);
    return '' + validControlDigit('00' + firstFourNumbers + secondFourNumbers) === controlDigits[0]
        && '' + validControlDigit(lastTenNumbers) === controlDigits[1];
};

export const validControlDigit = (controlDigit) => {
    let intSum = 0;
    for (let intI = 0; intI < 10; intI++) {
        intSum += (Math.pow(2, intI) * controlDigit[intI]);
    }
    intSum %= 11;
    return (intSum <= 1 ? intSum : (11 - intSum));
};

export const passwordStrengthIndex = (password) => {
    const multiplier = Math.min(password.length, 20);
    let multiplying = 0;
    for (const char of password) {
        if (char === char.toLowerCase()) {
            multiplying += 3;
            break;
        }
    }
    for (const char of password) {
        if (char === char.toUpperCase()) {
            multiplying += 2;
            break;
        }
    }
    if (/[^A-Za-z0-9]/.test(password) === true) {
        multiplying += 2;
    }

    return Math.max(0, Math.min(multiplier * multiplying, 100));
};

export const passwordHasLowercase = (password) => {
    let result = false;
    for (const char of password) {
        if (char === char.toLowerCase()) {
            result = true;
            break;
        }
    }
    return result;
};

export const passwordHasUppercase = (password) => {
    let result = false;
    for (const char of password) {
        if (char === char.toUpperCase()) {
            result = true;
            break;
        }
    }
    return result;
};

export const passwordHasSpecialChars = (password) => {
    return (/[^A-Za-z0-9]/.test(password) === true);
};
