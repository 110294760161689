import React from "react";
import {Grid} from "../Grid/Grid";
import "./List.scss";
import {isMobile} from 'react-device-detect';

export let List = ({columns="1fr 7fr 2fr", columnGap="0", mobileColumns="1fr", ...props}) => {
    if (isMobile) {
        columns = mobileColumns;
    }
    return (
        <Grid columns={columns} columnGap={columnGap} className="list-container" {...props}>
            {props.children}
        </Grid>
    )
}


export let ListContent = (props) => {
    return (<div className="list-content">
            {props.children}
        </div>
    )
}


export let ListAvatar = (props) => {
    return (<div className="list-avatar">
            {props.children}
    </div>
    )
}

export let ListActions = (props) => {
    let columns = 1;
    if (isMobile) {
        columns = "auto auto";
    }
    return (
        <Grid columns={columns} rowGap="0">
            {props.children}
        </Grid>
    )
}
