import React, {useCallback, useEffect, useState} from "react";
import {connect} from "react-redux";
import {deleteZone, getZones} from "../ZoneAction";
import Pagination from "@material-ui/lab/Pagination";
import Button from "@material-ui/core/Button";
import {Icon} from "../../../comun/Icon/Icon";
import Divider from "@material-ui/core/Divider";
import {openDialogAction} from "../../../comun/DialogManager/DialogManagerAction";
import {CreateZone} from "../CreateZone/CreateZone";
import {Grid} from "../../../comun/Grid/Grid";
import "./ZoneList.scss";
import {List, ListActions, ListAvatar, ListContent} from "../../../comun/List/List";
import Avatar from "@material-ui/core/Avatar";
import {StandList} from "../../Stand/StandList/StandList";
import HasRole from "../../../../auth/components/HasRole/HasRole";


export let ZoneList = ({onLoadZone, onDeleteZone, zones, listTitle="Zonas", dialogData, onDialogOpen, ...props}) => {
    const [page, setPage] = useState(1);
    const getInitialFilter = () => {
        let res = {page: 0, size: 10, cleaned: true};
        if (dialogData !== undefined) {
            res = Object.assign(dialogData, res);
        }

        return res;
    }
    const changeFilter = useCallback((data) => {
        return Object.assign({}, filter, data);
    }, []);
    const changPage = (event, page) => {
        setPage(page);
        setFilter(changeFilter({page: page- 1}));
    }
    const [filter, setFilter] = useState(getInitialFilter());
    useEffect(() => {
        onLoadZone(filter);
    }, [onLoadZone, filter]);
    const createZone = () => {
        onDialogOpen({component: CreateZone, data: {editting: false}, opened: true, id: "create-zone-dialog"});
    };
    const editZone = (zone) => {
        onDialogOpen({component: CreateZone,  data: {editting: true, id: zone, ...zone}, opened: true, id: "create-zone-dialog"});
    };
    const getStands = (zone) => {
        onDialogOpen({component: StandList,  data: {zone: zone.id}, opened: true, id: "list-stands-dialog"});
    }
    return (
        <div className="box">
            <h1>{listTitle}</h1>
            <HasRole role="AREA_COORDINATOR,FL_MANAGER,ADMIN">
            <Button variant="contained" color="secondary" style={{margin: "7px"}} onClick={createZone}>Añadir Zona</Button>
            </HasRole>
            {(zones.content.length === 0) ? <p>No hay ninguna zona para mostrar</p> : null}
            <Pagination count={zones.totalPages} variant="outlined"
                        shape="rounded" page={page} onChange={changPage} />
                {zones.content.map((zone) => {
                    return (
                        <React.Fragment key={zone.id}>
                        <List>
                            <ListAvatar>
                                <Avatar className="list-avatar-container">
                                    <Icon style={{fontSize: "1.6em", color: '#666'}}>add_business</Icon>
                                </Avatar>
                            </ListAvatar>
                            <ListContent>
                                <br></br>
                                <br></br>
                                <h3>{zone.name}</h3>
                            </ListContent>
                        <ListActions>
                            <HasRole role="AREA_COORDINATOR,FL_MANAGER,ADMIN">
                            <div>
                                <Button onClick={() => {
                                    editZone(zone);
                                }}>
                                    <Icon>edit</Icon> EDITAR
                                </Button>
                            </div>
                            </HasRole>
                            <div>
                                <Button onClick={() => getStands(zone)}>
                                    <Icon>add_business</Icon> STANDS
                                </Button>
                            </div>
                            <HasRole role="AREA_COORDINATOR,FL_MANAGER,ADMIN">
                            <div>
                                <Button onClick={() => onDeleteZone(zone.id)}>
                                    <Icon>delete</Icon> BORRAR
                                </Button>
                            </div>
                            </HasRole>
                        </ListActions>
                      </List>
                    <Divider />
                        </React.Fragment>
                    )
                })}
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadZone: async data => {
            await (await getZones(data))(dispatch)
        },
        onDialogOpen: data => {
            dispatch(openDialogAction(data));
        },
        onDeleteZone: async data => {
            return await deleteZone(data)(dispatch);
        }
    }
}
const mapPropsToState = state => {
    return {
        zones: state.zone,
    }
}

ZoneList = connect(mapPropsToState, mapDispatchToProps)(ZoneList);
