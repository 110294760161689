import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import "./Login.scss"
import {connect} from "react-redux";
import {required, validatePhone, validateUsername} from "../../../validation/basicValidation";
import MaterialTextField from "../../../components/comun/Forms/MaterialTextField";
import {Field, withFormik} from "formik";
import * as Yup from "yup";
import {login} from "./LoginAction";
export let LoginForm = ({ handleSubmit, isValid, isSubmitting, error, isTouched, errorData, ...props}) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            '& > *': {
                margin: theme.spacing(1),
                "& > *": {
                    width: '100%'
                }
            },
            width: '65%',
            display: 'table',
            margin: '0 auto',
            "@media (max-width:800px)": {
                width: '100%',
                display: 'block'
            }
        },
    }));
    const classes = useStyles();
    const getErrorMessage = () => {
        return (errorData !== undefined) ? errorData.errorMessage : 'Ha habido un error';
    }
    return (
        <div className="login-form">
            <form className={classes.root} onSubmit={handleSubmit}  >
                <div>
                    <Field name="username" component={MaterialTextField}  label="Nombre de Usuario"  />
                </div>
                <div>
                    <Field name="password" component={MaterialTextField}  type="password" label="Contraseña"  />
                </div>
                {
                    (error === true) ?  <div className="error-form">
                        {getErrorMessage()}
                    </div> : null
                }

                <div>
                    <Button type="submit" disabled={isSubmitting || (isValid && isTouched)}  variant="contained" color="secondary">Enviar</Button>
                </div>
                <div>
                    <a href={process.env.PUBLIC_URL + "/request-password-reset"}>¿Has olvidado tu contraseña? Click aquí.</a>
                </div>
            </form>
        </div>
    )
}
const mapPropsToState = state => {
    return {error: state.login.error, errorData: state.login.data}
}
const mapDispatchToProps = dispatch => {
    return {
        onPostLogin: loginData => {
            login(loginData)(dispatch)
        }
    }
}

LoginForm = withFormik({
    mapPropsToValues: () => {
        return {
            username: '', password: ''
        }
    },
    handleSubmit: (values, props) => {
        props.props.onPostLogin({username: values.username, password: values.password});
        props.setSubmitting(false);
    },
    validationSchema: Yup.object().shape({
        username: Yup.string().required('Obligatorio'),
        password: Yup.string().required('Obligatorio'),
    }),
})(LoginForm);
LoginForm = connect(mapPropsToState,mapDispatchToProps)(LoginForm);

