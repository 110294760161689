import React from 'react';
import "./Loading.scss"
import {connect} from "react-redux";
import Spinner from "../Spinner/Spinner";


let Loading = (props) => {
    return (
    <div className="overlay">
        <div className="overlay-text-container">
            <div className="overlay-text"><Spinner /></div>
        </div>
        </div>
    )
}

Loading = connect(null)(Loading);

export default Loading;
