import React from "react";
import {LeadList} from "../LeadList/LeadList";
import {getLeads} from "../LeadAction";
import {openDialogAction} from "../../../comun/DialogManager/DialogManagerAction";
import {connect} from "react-redux";
import {getRoleForm} from "../../../../auth/util";
import {TeamLeaderLeadFilters} from "../../TeamLeader/TeamLeaderLeadFilters/TeamLeaderLeadFilters";
import {AreaCoordinatorLeadFilters} from "../../AreaCoordinator/AreaCoordinatorLeadFilters/AreaCoordinatorLeadFilters";

export let ManageLeadsAreaCoordinator = ({user, leads,...props}) => {
    const initialValues = {};
    const key = getRoleForm(user);
    initialValues[key] = user.id;
    return (
        <LeadList callback={getLeads} initFilter={initialValues} manage={true} LeadFilterComponent={AreaCoordinatorLeadFilters}/>
    )
}
const mapPropsToState = state => {
    return {
        user: state.user
    }
}

ManageLeadsAreaCoordinator = connect(mapPropsToState, null)(ManageLeadsAreaCoordinator);
