import React, {useState} from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Grid} from "../../../comun/Grid/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {connect} from "react-redux";
import {getRegisterLeadConfigDispatch} from "../LeadAction";
import moment from "moment";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import "./LeadDetails.scss";
import HasRole from "../../../../auth/components/HasRole/HasRole";
import Button from "@material-ui/core/Button";
import {Icon} from "../../../comun/Icon/Icon";
import {LeadDetailsSicorPdf} from "./LeadDetailsPdf/LEadDetailsSicorPdf";
import {ListActions} from "../../../comun/List/List";
import InputAdornment  from '@mui/material/InputAdornment';

export let LeadDetailsSicor = ({dialogData, onRegisterLeadConfig, ...props}) => {
    let lead = {};
    const [documents, setDocuments] = useState([]);
    const [clauses, setClauses] = useState([]);
    React.useEffect(() => {
        onRegisterLeadConfig().then((res) => {
            setDocuments(res.documentTypeList);
            setClauses(res.privacyClauses);
        })
    }, [setDocuments, setClauses, onRegisterLeadConfig]);
    if (dialogData) {
        lead = dialogData.lead;
    }
    const getClauseValue = (clause) => {
        if (lead.privacyClauseValues === null || !lead.privacyClauseValues.length ) {
            return false;
        }
        const findedClause =  lead.privacyClauseValues.find(p => p.privacyClause.id === clause.id);
        
        if (findedClause != undefined){
	        return findedClause.privacyClauseValue.value === 'SI';
        }else{
	        return [];
        }
        
    }
    return (
        <React.Fragment>
            <DialogTitle>Detalles de Lead</DialogTitle>
            <DialogContent>
                {clauses.length > 0 ? <HasRole role="FL_MANAGER,ADMIN,AREA_COORDINATOR">
                    <div>
                        <LeadDetailsSicorPdf lead={lead} clauses={clauses} />
                    </div>
                </HasRole> : null}

                <Grid columns={2} mobileColumns="1fr">
                
                    <div>
                        <TextField variant="outlined" label="Gestor" disabled={true}
                                   value={`${lead.gestor?? ''}`}
                                   />
                    </div>
                    <div>
                        <TextField variant="outlined" label="Nombre y apellidos" disabled={true}
                                   value={`${lead.name} ${lead.surname1} ${lead.surname2}`} />
                    </div>
                    <div>
                        <TextField variant="outlined" label="Documento" disabled={true}
                                   value={`${lead.document?? ''}`} />
                    </div>
                
                    
                    <div>
                        <TextField variant="outlined" label="Teléfono" disabled={true}
                                   value={`${lead.phone?? ''}`} />
                    </div>
                    
                    
                    
                    <div>
                        <TextField variant="outlined" label="Fecha de alta" disabled={true}
                                   value={`${moment(lead.signUpDate, "DD-MM-YYYY").format('DD-MM-YYYY')}`} />
                    </div>
                    
                    <div>
                        <TextField variant="outlined" label="Comercial" disabled={true}
                                   value={`${lead.user ? lead.user.name : ''}`} />
                    </div>

                    <div>
                        <TextField variant="outlined" label="Centro" disabled={true}
                                   value={`${lead.stand ? lead.stand.mall : ''}`} />
                    </div>
                    <div></div>
                </Grid>
                <div style={{marginTop: '30px'}}>
                    <TextField variant="outlined" label="Observaciones" disabled={true}
                               value={lead.observations?? ''} rows={4} multiline={true} />
                </div>
                <div>
                    <h4>Cláusulas</h4>
                    <FormGroup className="lead-details-disabled">
                        {clauses.map((clause, index) => {
                            return (<FormControlLabel key={index} label={clause.description}  control={<Checkbox  checked={getClauseValue(clause)} /> } />
                            )
                        })}
                    </FormGroup>
                </div>

            </DialogContent>
        </React.Fragment>
    )
}


const mapRegisterLeadDispatchToProps = dispatch => {
    return {
        onRegisterLeadConfig: async () => {
            return await getRegisterLeadConfigDispatch()(dispatch);
        }
    }
}

LeadDetailsSicor = connect(null, mapRegisterLeadDispatchToProps)(LeadDetailsSicor)
