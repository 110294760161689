import React, {useState} from "react";

import "../Login/Login.scss"
import {LoginForm} from "../Login/LoginForm";
import {Field} from "formik";
import MaterialTextField from "../../../components/comun/Forms/MaterialTextField";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {validateEmail} from "../../../validation/basicValidation";
import {connect} from "react-redux";
import {requestEmail} from "../../auth";

export let RequestPasswordReset = ({onRequestEmail, ...props}) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            '& > *': {
                margin: theme.spacing(1),
                "& > *": {
                    width: '100%'
                }
            },
            width: '65%',
            display: 'table',
            margin: '0 auto',
            "@media (max-width:800px)": {
                width: '100%',
                display: 'block'
            }
        },
    }));
    const classes = useStyles();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [error, setError] = useState(undefined);
    const [email, setEmail] = useState(undefined);
    const [value, setValue] = useState(undefined);
    const [sended, setSended] = useState(undefined);
    const validate = (event) => {
        let value = event.target.value;
        setValue(value);
        if (value !== undefined && value !== '' ) {
            if (validateEmail(value) == null) {
                setIsValid(true);
                setError(undefined);
                setValue(value);
            } else {
                setError('No es una dirección de correo válida');
            }
        } else {
            setError('La dirección Email es obligatoria');
            setIsValid(false);
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setError(undefined);
        if (isValid) {
            setIsSubmitting(true);
            onRequestEmail(value).then((res) => {
                setIsSubmitting(false);
                setSended(true);
            }).catch(res => {
                setIsSubmitting(false);
                setSended(false);
                setError("Hubo un error al solicitar cambiar la contraseña, por favor inténtalo de nuevo.")
            })
        }

    }
    return (
        <div className="login-container">
            <div className="login-main">
                <div className="login-main-logos">
                    <img  src={process.env.PUBLIC_URL + "/logo.png"} className="login-header-logo"  alt="Logo Kauto Iberdrola FL"/>
                </div>

                <div className="login-form">
                    <form className={classes.root} onSubmit={handleSubmit}  >
                        <h1>Recuperación de contraseña</h1>
                        <p>Introduce la dirección de correo que tienes vinculada a tu cuenta. A continuación se enviará un correo
                            con instrucciones para continuar el proceso.</p>
                        <div>
                            <TextField
                                name="email"
                                variant="outlined"
                                label="Dirección de Correo"
                                onInput={validate}
                                error={!!error}
                                helperText={error}
                                value={email}
                            />
                        </div>

                        <div>
                            <Button type="submit" disabled={isSubmitting || (!isValid)}  variant="contained" color="secondary">
                                Solicitar</Button>
                        </div>
                        {sended ? <p>Si la dirección de correo existe en el sistema, se habrá enviado un email con instrucciones para completar
                        la recuperación de la contraseña.</p>
                            : null }
                    </form>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        onRequestEmail: async (email) => {
            return requestEmail(email)(dispatch);
        }
    }
}

RequestPasswordReset = connect(null, mapDispatchToProps)(RequestPasswordReset)
