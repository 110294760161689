import axios from "axios";
import {loadingDispatcher} from "../../comun/Loading/loadingAction";
import {openAlertAction} from "../../comun/AlertMessage/AlertAction";
import {envLoader} from "../../../env/envLoader";

const baseUrl = envLoader().base_url;

export const summary = async (data) => {
    return await axios.post(`${baseUrl}stats/summary`, data).then(res => res.data.data);
}

export const summaryExcel = async (data) => {
      return await axios.post(`${baseUrl}stats/summaryExcel`, data,{ responseType: 'arraybuffer' })
      .then(res => res.data);    
}
export const principal = async () => {
    return await axios.get(`${baseUrl}stats/principal`).then(res => res.data.data);
}

export const leads = async (data) => {
    return await axios.post(`${baseUrl}stats/leads`, data).then(res => res.data.data);
}

export const leadsExcel = async (data) => {
    return await axios.post(`${baseUrl}stats/leadsExcel`, data,{ responseType: 'arraybuffer' })
    .then(res => res.data);
}

export const usersConnectionTime = async (data = {}) => {
    return await axios.post(`${baseUrl}stats/users-connection-time`, data).then(res => res.data.data);
}

export const standStat = async (data = {}) => {
    return await axios.post(`${baseUrl}stats/stand`, data).then(res => res.data.data);
}

export const standStatExcel = async (data = {}) => {
    return await axios.post(`${baseUrl}stats/standExcel`, data,{ responseType: 'arraybuffer' })
    .then(res => res.data);
}

export const getStandStat  = (values) => {
    return async (dispatch) =>  {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
        return await standStat(values)
            .then( response => {
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));
                return response;
            }).catch(reason => {
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true}));
                dispatch(openAlertAction({
                    id: 'error-stand-statns', severity: 'error',
                    message: "Hubo un error al intentar cargar las estadisticas de los stants", title:'Error'
                }));
                throw reason;
            })
    }
}


export const getStandStatExcel = (values) => {
    return async (dispatch) =>  {    //Tienes que poner eso para que no se te congele la página
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
        return  standStatExcel(values)
        .then(res => {
            //Process the returned file stream
            const content = res;
            const blob = new Blob([content], { type: 'application/vnd.ms-excel;charset=utf-8' });
            var date =
                new Date().getFullYear() +
                "" +
                (new Date().getMonth() + 1) +
                "" +
                new Date().getDate() +
                "" +
                new Date().getHours() +
                "" +
                new Date().getMinutes() +
                "" +
                new Date().getSeconds() +
                "" +
                new Date().getMilliseconds();
            const fileName = date + "." + "xls";
            if ("download" in document.createElement("a")) {
                //Non ie Download
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // release the URL object
                document.body.removeChild(elink);
            } else {
                //Ie10 + download
                navigator.msSaveBlob(blob, fileName);
            }
            dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));
            return res;
        });

    }
};


export const getSummary  = (values) => {
    return async (dispatch) =>  {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
        return await summary(values)
            .then( response => {
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));
                return response;
            }).catch(reason => {
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true}));
                dispatch(openAlertAction({
                    id: 'error-stats-summary', severity: 'error',
                    message: "Hubo un error al intentar cargar el resumen, por favor inténtalo de nuevo", title:'Error'
                }));
                throw reason;
            })
    }
}



export const getSummaryExcel = (values) => {
    return async (dispatch) =>  {
            dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
            return  summaryExcel(values)
            .then(res => {
                //Process the returned file stream
                const content = res;
                const blob = new Blob([content], { type: 'application/vnd.ms-excel;charset=utf-8' });
                var date =
                    new Date().getFullYear() +
                    "" +
                    (new Date().getMonth() + 1) +
                    "" +
                    new Date().getDate() +
                    "" +
                    new Date().getHours() +
                    "" +
                    new Date().getMinutes() +
                    "" +
                    new Date().getSeconds() +
                    "" +
                    new Date().getMilliseconds();
                const fileName = date + "." + "xls";
                if ("download" in document.createElement("a")) {
                    //Non ie Download
                    const elink = document.createElement("a");
                    elink.download = fileName;
                    elink.style.display = "none";
                    elink.href = URL.createObjectURL(blob);
                    document.body.appendChild(elink);
                    elink.click();
                    URL.revokeObjectURL(elink.href); // release the URL object
                    document.body.removeChild(elink);
                } else {
                    //Ie10 + download
                    navigator.msSaveBlob(blob, fileName);
                }
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));
                return res;
            });

    }
};

export const getLeadsByPrincipal  = (data) => {
    return async (dispatch) =>  {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
        return await leads(data)
            .then( response => {
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));
                return response;
            }).catch(reason => {
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true}));
                dispatch(openAlertAction({
                    id: 'error-stats-leads', severity: 'error',
                    message: "Hubo un error al intentar cargar los datos, por favor inténtalo de nuevo", title:'Error'
                }));
                throw reason;
            })
    }
}

export const getLeadsByPrincipalExcel = (values) => {
    return async (dispatch) =>  {
            dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
            return  leadsExcel(values)
            .then(res => {
                //Process the returned file stream
                const content = res;
                const blob = new Blob([content], { type: 'application/vnd.ms-excel;charset=utf-8' });
                var date =
                    new Date().getFullYear() +
                    "" +
                    (new Date().getMonth() + 1) +
                    "" +
                    new Date().getDate() +
                    "" +
                    new Date().getHours() +
                    "" +
                    new Date().getMinutes() +
                    "" +
                    new Date().getSeconds() +
                    "" +
                    new Date().getMilliseconds();
                const fileName = date + "." + "xls";
                if ("download" in document.createElement("a")) {
                    //Non ie Download
                    const elink = document.createElement("a");
                    elink.download = fileName;
                    elink.style.display = "none";
                    elink.href = URL.createObjectURL(blob);
                    document.body.appendChild(elink);
                    elink.click();
                    URL.revokeObjectURL(elink.href); // release the URL object
                    document.body.removeChild(elink);
                } else {
                    //Ie10 + download
                    navigator.msSaveBlob(blob, fileName);
                }
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));
                return res;
            });

    }
};


