import React, {useEffect, useState} from "react";
import {DateRangeWeekPicker} from "../../../comun/DateRangeWeekPicker/DateRangeWeekPicker";
import {DateRangePicker} from "../../../comun/DateRangeWeekPicker/DateRangePicker";
import {StatDateFilter} from "../StatDateFilter/StatDateFilter";
import moment from "moment";
import {connect} from "react-redux";
import "../Summary/Summary.scss";
import {Button, Table, TableBody, TableRow} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import {getLeadsByPrincipal, getLeadsByPrincipalExcel, usersConnectionTime} from "../statsAction";
import {envLoader} from "../../../../env/envLoader";
import {getValueOrDefault} from "../../../../util/utilCampaign";

export let MyActivity = ({user, onLoadLeads, onLoadLeadsExcel, ...props}) => {    
    
    var myfirstDay = "";
    var myLastDay = "";

    const campaingType = getValueOrDefault('campaign.type', user.campaign, 'event'); 

    var porSemana = campaingType == "kcrm" ? "NO":"SI";
    
    const [startInterval, setStartInterval] = useState();
    const [endInterval, setEndInterval] = useState();

    const firstDayMonth = moment().startOf('month').startOf('week');
    const lastDayMonth = moment().endOf('month').endOf('week');
    const [leads, setLeads] = useState();
    const [connectionTime, setConnectionTime] = useState([]);

    const [startFilter, setStartFilter] = useState({
        day: firstDayMonth.date(), month: firstDayMonth.month(),
        year: firstDayMonth.year(), moment: firstDayMonth
    });
    const [endFilter, setEndFilter] = useState({
        day: lastDayMonth.date(), month: lastDayMonth.month(),
        year: lastDayMonth.year(), moment: lastDayMonth
    });

    const aggregateLeads = (leads) => {
        const w = {};
        w.ko = leads.filter(l => l.status === 'KO').length;
        w.ok = leads.filter(l => l.status === 'OK').length;
        w.sales = leads.filter(l => l.sale === true).length;
        w.closed = w.ko + w.sales;
        w.total = leads.length;
        w.closes_percentage = Math.round((w.closed / ((w.total === 0) ? 1 : w.total))* 100) / 100 * 100;
        w.conversion = Math.round((w.sales / ((w.total === 0) ? 1 : w.total))* 100) / 100;
        w.wrongPhone = leads.filter(l => l.wrongPhone === true).length;
        w.tries = leads.filter(l => l.tries === true).length;
        w.robinson = leads.filter(l => l.robinson === true).length;
        w.deceasedTitular =leads.filter(l => l.deceasedTitular === true).length;
        w.noInterest = leads.filter(l => l.noInterest === true).length;
        w.noContact = leads.filter(l => l.noContact === true).length;
        //kcrm
        w.management = leads.filter(l => l.agent == true || l.machine == true).length;
        w.outOfTarget = leads.filter(l => l.outOfTarget === true).length;
        w.permanence = leads.filter(l => l.permanence === true).length;
        w.price =leads.filter(l => l.price === true).length;
        w.badExperience = leads.filter(l => l.badExperience === true).length;
        w.offerAlreadyHired = leads.filter(l => l.offerAlreadyHired === true).length;
        w.noDefiniteReason = leads.filter(l => l.noDefiniteReason === true).length;
        w.wrongLead = leads.filter(l => l.wrongLead === true).length;
        w.machine = leads.filter(l => l.machine === true).length;
        w.agent =leads.filter(l => l.agent === true).length;

        return w;
    }
    const groupLeads = (weeks) => {
        weeks.forEach(w => {
           
                const leadsFiltered = leads.filter(l => {
                    const date = moment(l.signUpDate, "DD-MM-YYYY");                    
                    var dfirst = new Date(w.firstDay);
                    dfirst.setHours(0);
                    dfirst.setMinutes(0);
                    dfirst.setSeconds(0);
                    dfirst.setMilliseconds(0);
                    var first = dfirst.getTime();

                    if (w.lastDay == undefined){
                        w.lastDay = w.firstDay;
                    }

                    var last = new Date(w.lastDay).getTime();                    

                    var myDate = new Date(date).getTime();
                    //return date >= w.firstDay && date <= w.lastDay;
                    return myDate >= first && date <= last;
                })           
            
            w.leads = leadsFiltered;
            w.stats = aggregateLeads(leadsFiltered);                       

            myfirstDay = w.firstDay.format('YYYY-MM-DD');
            myLastDay = myfirstDay;
            if (w.lastDay != undefined){
                myLastDay = w.lastDay.format('YYYY-MM-DD');
            }

            const dataSend = {users: [user.id], firstInterval: w.firstDay.format('YYYY-MM-DD'), lastInterval: myLastDay};

            w.stats.hours = 0;
            w.stats.leads_hours = 0;
            w.stats.wrongPhone = w.leads.filter(l => l.wrongPhone === true).length;
            w.stats.tries = w.leads.filter(l => l.tries === true).length;
            w.stats.robinson = w.leads.filter(l => l.robinson === true).length;
            w.stats.deceasedTitular = w.leads.filter(l => l.deceasedTitular === true).length;
            w.stats.noInterest = w.leads.filter(l => l.noInterest === true).length;
            w.stats.noContact = w.leads.filter(l => l.noContact === true).length;
            //kcrm
            w.stats.management = w.leads.filter(l => l.agent == true || l.machine == true).length;
            w.stats.outOfTarget = w.leads.filter(l => l.outOfTarget === true).length;
            w.stats.permanence = w.leads.filter(l => l.permanence === true).length;
            w.stats.price = w.leads.filter(l => l.price === true).length;
            w.stats.badExperience = w.leads.filter(l => l.badExperience === true).length;
            w.stats.offerAlreadyHired = w.leads.filter(l => l.offerAlreadyHired === true).length;
            w.stats.noDefiniteReason = w.leads.filter(l => l.noDefiniteReason === true).length;
            w.stats.wrongLead = w.leads.filter(l => l.wrongLead === true).length;
            w.stats.machine = w.leads.filter(l => l.machine == true).length;
            w.stats.agent = w.leads.filter(l => l.agent === true).length;
            setWeeks(weeks);
        });
    }
    const [weeks, setWeeks] = useState([]);
    useEffect(() => {
        if (leads === undefined) {
            return;
        }
        let curDate = firstDayMonth.clone();
        let endDate = lastDayMonth.clone();
      
        if (startFilter && endFilter)  {
                curDate = startFilter.moment.clone();
                endDate = endFilter.moment.clone();
        }               

        const weeksAux = [];
        while (curDate <= endDate) {
            const curWeek = curDate.week();
            const findWeek = weeksAux.find((w) => w.number === curWeek);
            if (!findWeek) {
                weeksAux.push({
                    number: curWeek, firstDay: curDate.clone(), lastDay: undefined, leads: [],
                    stats: {ok: 0, ko: 0, total: 0, closed: 0, closes_percentage: 0, conversion: 0, leads_hours: 0,
                    wrongPhone: 0, tries: 0, robinson: 0, deceasedTitular: 0, noInterest: 0, noContact: 0, agent: 0, machine: 0,
                    wrong_lead: 0, no_definite_reason: 0, offer_already_hired: 0, bad_experience: 0, price: 0, permanence: 0, out_of_target_audience: 0}
                });
            } else {
                findWeek.lastDay = curDate.clone();
            }
            curDate = curDate.add(1, "d");
        }
        groupLeads(weeksAux);

    }, [leads]);
    const [totalAggregateData, setTotalAggregateData] = useState({});
    useEffect(() => {
        if (leads === undefined) {
            return;
        }
        
           const filteredLeads = leads.filter(l => {
                const date = moment(l.signUpDate, "DD-MM-YYYY");                
                var dfirst = new Date(startFilter.moment);
                dfirst.setHours(0);
                dfirst.setMinutes(0);
                dfirst.setSeconds(0);
                dfirst.setMilliseconds(0);
                var first = dfirst.getTime();

                if (endFilter == undefined){
                    endFilter = startFilter;
                }

                var last = new Date(endFilter.moment).getTime();
                var myDate = new Date(date).getTime();
                //return date >= startFilter.moment && date <= endFilter.moment;
                return myDate >= first && date <= last;
            })
        
        
        const data = aggregateLeads(filteredLeads);
        setTotalAggregateData(data);
    }, [weeks]);

    useEffect(() => {
        onLoadLeads({startInterval: startFilter.moment.format('YYYY-MM-DD'), endInterval:
                endFilter.moment.format('YYYY-MM-DD')}).then(res => {
            setLeads(res.leads);
            setConnectionTime(res.connectionTimes);
        })
    }, [startFilter, endFilter]);

    useEffect(() => {
        
        if (!startInterval || !endInterval) {
            return;
        }
        onLoadLeads({
            startInterval: startInterval.moment.format('YYYY-MM-DD'),
            endInterval: endInterval.moment.format('YYYY-MM-DD'),
            
        }).then(res => {
            setLeads(res.leads);
            setConnectionTime(res.connectionTimes);
        })
            
    }, [startInterval, endInterval]);

    const filterDataExcel = () => {
        onLoadLeadsExcel({startInterval: startFilter.moment.format('YYYY-MM-DD'), endInterval:
                endFilter.moment.format('YYYY-MM-DD')}).then(res => {
            //setLeads(res.leads);
            //setConnectionTime(res.connectionTimes);
        })
        
    }	

    const filterStart = (data) => {
        if (!data) {
            setStartFilter({
                day: firstDayMonth.date(), month: firstDayMonth.month(),
                year: firstDayMonth.year(), moment: firstDayMonth
            });
            return;
        }
        setStartFilter(data);
    }
    const filtereEnd = (data) => {
        if (!data) {
            setEndFilter({
                day: lastDayMonth.date(), month: lastDayMonth.month(),
                year: lastDayMonth.year(), moment: lastDayMonth
            });
            return;
        }
        setEndFilter(data);
    }
    const getTotalHours = (w) => {
        let total = 0;
        w.forEach((w) => {
            const firstDay = w.firstDay;
            const lastDay = w.lastDay;
            let totalUnit = connectionTime.filter((c) => {
                const date = moment(c.firstConnection);
                return firstDay <= date && lastDay >=date;
            });
            if (totalUnit.length === 0) {
                totalUnit = 0;
            } else {
                totalUnit = totalUnit.map((c) => c.totalHours).reduce ((a,b) => a + b, 0);
                total += totalUnit;
            }
        })


        return total;
    }

    const calculeLeadsHours = (w)  => {
        let total = 0;
        w.forEach((w) => {
            total+=Math.floor((w.stats.total / Math.max(1, getTotalHours([w]))) * 100 ) / 100;
        })
        return  total;
    }

    const calculeMyWeekDay = (w)  => {
     
       var myWeekDays=["D", "L", "M", "X", "J", "V", "S"];       
       var firstDayWeek= new Date(w[0].firstDay);
       var lastDayWeek= new Date(w[0].lastDay);
       var myFirstWeekDay = myWeekDays[firstDayWeek.getUTCDay()];
       var myLastWeekDay = myWeekDays[lastDayWeek.getUTCDay()];

       if (myLastWeekDay == undefined){
        myLastWeekDay = myFirstWeekDay;
       }

       return (myFirstWeekDay == myLastWeekDay)? myLastWeekDay: (myFirstWeekDay+" - "+myLastWeekDay);

    }

    //const campaingType = getValueOrDefault('campaign.type', user.campaign, 'event');    

    return (
    
    <div className="box">
        <h1>Mi Actividad</h1>

        {porSemana == "SI" ?
            <React.Fragment>
            <h2>Filtro por semana</h2>        
            <div className="summary-filters" style={{margin: "20px"}}>
                <StatDateFilter updateFirstInterval={filterStart} updateSecondInterval={filtereEnd}
                                component={DateRangeWeekPicker}/>
            </div> 
            </React.Fragment>  
            :
            <React.Fragment>
            <h2>Filtro por día</h2>        
            <div className="summary-filters" style={{margin: "20px"}}>
                <StatDateFilter updateFirstInterval={filterStart} updateSecondInterval={filtereEnd} />
                
            </div>     
            </React.Fragment>
        }


        <div className="my-stands-table-container">

        <Table className="summary-stats-table">
            <TableBody>
                <TableRow className="my-stands-total-results">
                    <TableCell component="th"></TableCell>
                    
                    {campaingType == "kcrm"?
                    <React.Fragment>
                    <TableCell component="th">Total Leads</TableCell>
                    <TableCell component="th">Leads en Gestion</TableCell>
                    </React.Fragment>
                    :
                    <React.Fragment>
                    <TableCell component="th">Total Leads</TableCell>
                    <TableCell component="th">Leads OK</TableCell>
                    <TableCell component="th">Leads KO</TableCell>
                    </React.Fragment>
                    }
                    
                    <TableCell component="th">Total Ventas</TableCell>
                    <TableCell component="th">Leads Cerrados</TableCell>
                    {campaingType == "kcrm"?
                    null
                    :
                    <TableCell component="th">Leads Cerrados %</TableCell>
                    }
                    <TableCell component="th">Conversion</TableCell>

                    {campaingType == "kcrm"?
                    <React.Fragment>
                        <TableCell component="th">KO: Permanencia</TableCell>
                        <TableCell component="th">KO: Precio</TableCell>
                        <TableCell component="th">KO: Fuera publico objetivo</TableCell>
                        <TableCell component="th">KO: Mala experiencia</TableCell>
                        <TableCell component="th">KO: Oferta ya contratada</TableCell>
                        <TableCell component="th">KO: Sin motivo definido</TableCell>
                        <TableCell component="th">KO: superado numero de contacto</TableCell>
                        <TableCell component="th">KO: Lead erroneo</TableCell>
                        <TableCell component="th">KO: OTROS</TableCell>
                        <TableCell component="th">Intentando contacto</TableCell>
                        <TableCell component="th">Agendados</TableCell>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <TableCell component="th">Leads / hora</TableCell>
                        <TableCell component="th">Total Horas</TableCell>
                        <TableCell component="th">KO: Telefono Erroneo</TableCell>
                        <TableCell component="th">KO: Intentos de Contacto</TableCell>
                        <TableCell component="th">KO: Lista Robinson</TableCell>
                        <TableCell component="th">KO: Titular Fallecido</TableCell>
                        <TableCell component="th">KO: Sin interes</TableCell>
                        <TableCell component="th">KO: Imposible contacto</TableCell>
                    </React.Fragment>
                    }                    
                    
                </TableRow>
                {weeks.map((w, i) => {
                    return  <TableRow key={i}>
                        
                        <TableCell component="th">Semana {w.number}
                        {porSemana =="SI" ?
                        <div>{w.firstDay.format("DD-MM-YYYY")} - {w.lastDay.format("DD-MM-YYYY")}
                        </div>
                        :
                        <div>{calculeMyWeekDay([w])}
                        </div>
                        }

                        </TableCell>
                        

                        <TableCell component="td">  {w.stats.total} </TableCell>
                        {campaingType == "kcrm"?
                            <TableCell component="td"> {w.stats.management} </TableCell>
                        :
                        <React.Fragment>
                            <TableCell component="td"> {w.stats.ok} </TableCell>
                            <TableCell component="td"> {w.stats.ko} </TableCell>
                        </React.Fragment>
                        }
                        <TableCell component="td"> {w.stats.sales} </TableCell>
                        <TableCell component="td"> {w.stats.closed} </TableCell>
                        {campaingType == "kcrm"?
                        null
                        :
                        <TableCell component="td"> {w.stats.closes_percentage} </TableCell>
                        }
                        <TableCell component="td"> {w.stats.conversion} </TableCell>
                        {campaingType == "kcrm"?
                        <React.Fragment>
                            
                            <TableCell component="td"> {w.stats.permanence} </TableCell>
                            <TableCell component="td"> {w.stats.price} </TableCell>
                            <TableCell component="td"> {w.stats.outOfTarget} </TableCell>
                            <TableCell component="td"> {w.stats.badExperience} </TableCell>
                            <TableCell component="td"> {w.stats.offerAlreadyHired} </TableCell>
                            <TableCell component="td"> {w.stats.noDefiniteReason} </TableCell>
                            <TableCell component="td"> {w.stats.tries} </TableCell>
                            <TableCell component="td"> {w.stats.wrongLead} </TableCell>
                            <TableCell component="td"> {w.stats.robinson} </TableCell>
                            <TableCell component="td"> {w.stats.machine} </TableCell>
                            <TableCell component="td"> {w.stats.agent} </TableCell>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <TableCell component="td"> {calculeLeadsHours([w])} </TableCell>
                            <TableCell component="td"> {getTotalHours([w])} </TableCell>
                            <TableCell component="td">{w.stats.wrongPhone}</TableCell>
                            <TableCell component="td">{w.stats.tries}</TableCell>
                            <TableCell component="td">{w.stats.robinson}</TableCell>
                            <TableCell component="td">{w.stats.deceasedTitular}</TableCell>
                            <TableCell component="td">{w.stats.noInterest}</TableCell>
                            <TableCell component="td">{w.stats.noContact}</TableCell>
                        </React.Fragment>
                        }
                    </TableRow>
                })}
                <TableRow className="my-stands-total-results">
                    <TableCell component="th">Total
                    </TableCell>
                    <TableCell component="th">  {totalAggregateData.total} </TableCell>
                    {campaingType == "kcrm"?
                    <TableCell component="th"> {totalAggregateData.management} </TableCell>
                    :
                    <React.Fragment>
                        <TableCell component="th"> {totalAggregateData.ok} </TableCell>
                        <TableCell component="th"> {totalAggregateData.ko} </TableCell>
                    </React.Fragment>
                    }
                    <TableCell component="th"> {totalAggregateData.sales} </TableCell>
                    <TableCell component="th"> {totalAggregateData.closed} </TableCell>
                    {campaingType == "kcrm"?
                    null
                    :
                    <TableCell component="th"> {totalAggregateData.closes_percentage} </TableCell>
                    }
                    <TableCell component="th"> {totalAggregateData.conversion} </TableCell>
                    {campaingType == "kcrm"?
                    <React.Fragment>
                        <TableCell component="th">{totalAggregateData.permanence}</TableCell>
                        <TableCell component="th">{totalAggregateData.price}</TableCell>
                        <TableCell component="th">{totalAggregateData.outOfTarget}</TableCell>
                        <TableCell component="th">{totalAggregateData.badExperience}</TableCell>
                        <TableCell component="th">{totalAggregateData.offerAlreadyHired}</TableCell>
                        <TableCell component="th">{totalAggregateData.noDefiniteReason}</TableCell>
                        <TableCell component="th">{totalAggregateData.tries}</TableCell>
                        <TableCell component="th">{totalAggregateData.wrongLead}</TableCell>
                        <TableCell component="th">{totalAggregateData.robinson}</TableCell>
                        <TableCell component="th">{totalAggregateData.machine}</TableCell>
                        <TableCell component="th">{totalAggregateData.agent}</TableCell>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <TableCell component="th"> {calculeLeadsHours(weeks)} </TableCell>
                        <TableCell component="th"> {getTotalHours(weeks)} </TableCell>
                        <TableCell component="th">{totalAggregateData.wrongPhone}</TableCell>
                        <TableCell component="th">{totalAggregateData.tries}</TableCell>
                        <TableCell component="th">{totalAggregateData.robinson}</TableCell>
                        <TableCell component="th">{totalAggregateData.deceasedTitular}</TableCell>
                        <TableCell component="th">{totalAggregateData.noInterest}</TableCell>
                        <TableCell component="th">{totalAggregateData.noContact}</TableCell>
                    </React.Fragment>
                    }
                </TableRow>
            </TableBody>
        </Table>

        {/*
        <div>
               <Button style={{width: 'fit-content'}} size="medium"
                         variant="contained" color="secondary" type="submit" onClick={filterDataExcel}> Descargar
                </Button>
        </div>
        */}

        </div>
    </div>
    
    )
}

const mapStateToProps = (state) => {
    return {user: state.user};
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLoadLeads: async (data) => {
            return await getLeadsByPrincipal(data)(dispatch);
        },

        onLoadLeadsExcel: async (data) => {
            return await getLeadsByPrincipalExcel(data)(dispatch);
        }
    }
}

MyActivity = connect(mapStateToProps, mapDispatchToProps)(MyActivity)
