import {loadingDispatcher} from "../../comun/Loading/loadingAction";
import axios from "axios";
import {userAction} from "../Users/UserAction";
import {openAlertAction} from "../../comun/AlertMessage/AlertAction";
import {CREATE_LEAD, EDIT_ZONE, LOAD_ZONES} from "../../../types/types";
import {envLoader} from "../../../env/envLoader";


const baseUrl = envLoader().base_url;
export const loadZoneAction = values => {
    return {
        type: LOAD_ZONES,
        payload: values
    }
}


export const createZoneAction = values => {
    return {
        type: LOAD_ZONES,
        payload: values
    }
}

export const editZoneAction = values => {
    return {
        type: EDIT_ZONE,
        payload: values
    }
}

export const filterZones = async(values) => {
    console.log(`${baseUrl}zone/filter`);
    return await axios.post(`${baseUrl}zone/filter`, values).then(res => res.data.data);
}

export const filterMisAcciones = async(values) => {
    return await axios.post(`${baseUrl}zone/filter2`, values).then(res => res.data.data);
}

export const getZones = async (values) => {
    return async (dispatch) =>  {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
        return await filterZones(values)
            .then( response => {
                dispatch(loadZoneAction(response));
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));
            }).catch(reason => {
                console.log(reason);
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true}));
                dispatch(openAlertAction({
                    id: 'error-register-lead', severity: 'error',
                    message: "Hubo un error al intentar cargar las zonas, por favor inténtalo de nuevo", title:'Error'
                }));
            })
    }
}

export const createZone = async (values) => {
    return async (dispatch) =>  {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
        return await axios.post(`${baseUrl}zone/create`, values)
            .then( async response => {
                dispatch(createZoneAction(response.data.data));
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));
                await (await getZones({page: 0, size: 10}))(dispatch);
            }).catch(reason => {
                console.log(reason);
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true}));
                dispatch(openAlertAction({
                    id: 'error-register-lead', severity: 'error',
                    message: "Hubo un error al intentar crear la zona", title:'Error'
                }));
            })
    }
}
export const editZone = async (values) => {
    return async (dispatch) =>  {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
        return await axios.post(`${baseUrl}zone/edit`, values)
            .then( async response => {
                dispatch(editZoneAction(response.data.data));
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));
                await (await getZones({page: 0, size: 10}))(dispatch);
            }).catch(reason => {
                console.log(reason);
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true}));
                dispatch(openAlertAction({
                    id: 'error-register-lead', severity: 'error',
                    message: "Hubo un error al intentar crear la zona", title:'Error'
                }));
            })
    }
}
export const deleteZone = (id) => {
    return async (dispatch) =>  {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
        return await axios.get(`${baseUrl}zone/${id}/delete`)
            .then( async response => {
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));
                await (await getZones({page: 0, size: 10}))(dispatch);
            }).catch(reason => {
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true}));
                dispatch(openAlertAction({
                    id: 'error-delete-zone', severity: 'error',
                    message: "Hubo un error al intentar borrar la zona", title:'Error'
                }));
            })
    }
}