import React, {useEffect, useState} from "react";
import './Calendar.scss';
import moment from "moment";
import {getLocaleWeekDay} from "../../../util/utilMoment";


export let Calendar = ({date, onDayClick= () => {}, onDayOver= () => {}, onDayOverOut= () => {}, dayClass = () => "", ...props}) => {
    const [days, setDays] = useState([]);
    const daysOfWeek = ['L', 'M','X','J','V','S','D'];

    const getDaysOfMonth = () =>{
        return date.daysInMonth();
    }
    const addDay = (day) => {
        let dayData = {day: day.date(), year: day.year(), month: day.month(), daysOfWeek: getLocaleWeekDay(day.day()), selected: false, moment: day.clone()};
        return dayData;
    }
    const generateDays = async () => {
        const res = [];
        const dayInMonth = date.daysInMonth();
        const curDate = date.clone().set("date", 1);
        res.push(addDay(curDate))
        for(let i = 2; i <= dayInMonth; i++) {
            curDate.set("date",i);
            res.push(addDay(curDate));
        }
        await setDays(res);
    }
    const renderDay = (day) => {
        let extraClass = '';
        if (moment().format('DD-MM-YYYY') === day.moment.format('DD-MM-YYYY')) {
            extraClass= ' current-day';
        }
        return  <div className={"calendar-week-days-day-container ".concat(dayClass(day)).concat(extraClass)}>
            <button onClick={(e) => {onDayClick(day, days)}} onMouseOver={(e) => {onDayOver(day, e)}}
                    onMouseOut={(e) => {onDayOverOut(day, e)}}
                    className={"calendar-week-days-day-filled "}>{day.day}</button>
        </div>
    }
    const renderDays = () => {
        if (days.length === 0) {
            return [];
        }
        const res = [];
        const month = date.month();
        let curDate = date.clone().set('date', 1);
        let counter = 1;
        let weekCounter = 1;
        let totalDaysCounter = 1;
        const daysMonth = curDate.daysInMonth();
        const daysAcum = [];
        let i = 0;
        while (days.length > daysAcum.length) {
            ++i;
            const day = curDate.date();
            const renderWeekDays = [];
            for(let i = 0; i <= 6; i++) {
                ++totalDaysCounter;
                const actualIterableDay = days.find((d1) => d1.day === counter && i === d1.daysOfWeek);
                if (actualIterableDay) {
                    daysAcum.push(actualIterableDay);
                    renderWeekDays.push(
                        <React.Fragment key={totalDaysCounter}>
                            {renderDay(actualIterableDay)}
                        </React.Fragment>);
                    ++counter;
                    curDate = curDate.add(1, 'd');
                } else {
                    renderWeekDays.push( <div key={totalDaysCounter} className="calendar-week-days-day-container" ></div>);
                }
            }
            const renderWeek = <div className="calendar-week" key={weekCounter}>{renderWeekDays}</div>
            res.push(renderWeek);
            ++weekCounter;
            if (i > 100) {
                break;
            }
        }

        return res;
    }
    useEffect( () => {
        const cb = async () => {
            return await generateDays();
        };
        cb().then(r => {});
    }, [date]);
    return (
        <div className="calendar-container">
            <div className="calendar-week-days">
                {daysOfWeek.map(d => {
                    return <button key={d} className="calendar-week-header-day">{d}</button>
                })}
            </div>
            <div className="calendar-days">
                {renderDays()}
            </div>
        </div>
    )
}
