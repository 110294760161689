import {CREATE_LEAD} from "../types/types";

export  default function RegisterLeadReducer (state  = {}, action) {
    let res;
    switch (action.type) {
        case CREATE_LEAD:
            res =  Object.assign({}, state, action.payload);
            return res;
        default:
            return state;
    }
}
