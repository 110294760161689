import React, {useEffect, useRef, useState} from "react";
import './RegisterLead.scss';
import * as Yup from 'yup';
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import {getRegisterLeadConfigDispatch, registerLeadSicor} from "../LeadAction";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import {Field, withFormik} from "formik";
import {connect} from "react-redux";
import {Grid} from "../../../comun/Grid/Grid";
import MaterialTextField from "../../../comun/Forms/MaterialTextField";
import CheckboxField from "../../../comun/Forms/CheckboxField";
import CheckboxField2 from "../../../comun/Forms/CheckboxField2";
import {validatePhone,validateSurname2} from "../../../../validation/basicValidation";
import SelectField from "../../../comun/Forms/SelectField";
import {AutocompleteUser} from "../../../comun/AutocompleteUser/AutocompleteUser";
import {hasAnyRole, hasRole} from "../../../../auth/util";
import {filterStands} from "../../Stand/StandAction";
import {documentExists} from "../../Users/UserAction";
import {call} from "../../../../util/utilXhr";
import SignatureCanvas from "react-signature-canvas";
import {validCif, validNif, validNifNoAdamo} from "../../../../util/utilValid";
import {existsKeyInCampaign, getValueOrDefault} from "../../../../util/utilCampaign";
import {log} from "../../../../util/utilLog";
import {generateUuid} from "../../../../util/utilUuid";
import {confirmSmsEvent, sendSmsConfirmationEvent} from "../SmsAction";
import ButtonField from "../LeadFormComponents/ButtonField";
import { Typography, LinearProgress, Popover } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { set } from "date-fns";

import { useParams,useLocation } from "react-router-dom";


const getPhoneConfirmedEnabled = (campaign, user) => {
    let phoneConfirmationEnabled = getValueOrDefault('lead.register.sms_confirmation.enable', campaign, 'false');
    phoneConfirmationEnabled = (phoneConfirmationEnabled === 'true');
    let roles = getValueOrDefault('lead.register.sms_confirmation.roles_excluded', campaign, '');
    roles = roles.split(',');
    
    console.log("phoneConfirmationEnabled = "+phoneConfirmationEnabled);
    console.log("hasAnyRole(user, roles) = "+hasAnyRole(user, roles));
    console.log("phoneConfirmationEnabled && !hasAnyRole(user, roles) = "+phoneConfirmationEnabled && !hasAnyRole(user, roles));
    
    return phoneConfirmationEnabled && !hasAnyRole(user, roles);
}

const getInterestedProducts = (campaign) => {
    let products = getValueOrDefault('interested_product', campaign, '');

    return products;
}



const getPreferenceCall = (campaign) => {
    let calls = getValueOrDefault('call_preference', campaign, '');

    return calls;
}


const getTypeHousing = (campaign) => {
    let houses = getValueOrDefault('type_housing', campaign, '');

    return houses;
}

const getTypeHome = (campaign) => {
    let houses = getValueOrDefault('type_home', campaign, '');

    return houses;
}


const getUseAssistance = (campaign) => {
    let houses = getValueOrDefault('use_assistance', campaign, '');

    return houses;
}


const getClause = (campaign) => {
    let clauseText = getValueOrDefault('lead.register.clause_Sicor', campaign, '');
    return clauseText;
}

const getWeb = (campaign) => {
    let web = getValueOrDefault('sicor.web', campaign, '');
    return web;
}

const getCheckedValue = (clause) => {
    return clause.privacyClausePrivacyClauseValues.find(a => a.privacyClauseValue.value === "SI");
}
const getUnCheckedValue = (clause) => {
    return clause.privacyClausePrivacyClauseValues.find(a => a.privacyClauseValue.value === "Off");
}



export let RegisterLeadSicor = ({user, onRegisterLeadConfig, ...props}) => {
    const [documents, setDocuments] = useState([]);
    const [clauses, setClauses] = useState([]);
    const [misClauses, setMisClauses] = useState([]);

    const location = useLocation();
        
    const gestor = location.state.gestor;

    React.useEffect(() => {
        onRegisterLeadConfig().then((res) => {
            setDocuments(res.documentTypeList);
            setClauses(res.privacyClauses);
        })

    }, []);


    
    
    return (
        <RegisterLeadSicorForm
           documents={documents} 
           gestor={gestor}
           clauses={clauses} 
           misClauses={misClauses}
           defaultClauses={[...clauses]}
         />
    )
}

const mapRegisterLeadDispatchToProps = dispatch => {
    return {
        onRegisterLeadConfig: async () => {
            return await getRegisterLeadConfigDispatch()(dispatch);
        }
    }
}
RegisterLeadSicor = connect(null, mapRegisterLeadDispatchToProps)(RegisterLeadSicor);

export let RegisterLeadSicorForm = ({
                                   onCheckDocument,
                                   campaign,
                                   onConfirmSms,
                                   clauses,
                                   misClauses,
                                   user,
                                   documents,
                                   gestor,
                                   getFieldValue,
                                   setFieldValue,
                                   setFieldError,
                                   onSendSms,
                                   isSubmitting,
                                   handleSubmit,
                                   values,
                                   defaultClauses,
                                   defaultChecks,
                                   setErrors,
                                   isValid,
                                   ...props
                               }) => {
    const getStandFilterParams = () => {
        const res = {};

        let key = 'teamLeader';
        if (hasRole(user, 'COMMERCIAL_PROMOTER')) {
            key = 'commercialPromoter';
        }
        res[key] = user.id;
        res.cleaned = true;
        return res;
    }
    
    const submit = (vals) => {
        log(`Empezando a registrar Lead de ${user.id}`, "info");

        try {                
            handleSubmit(vals);
            setSmsconfirmed(false);              
    } catch (e) {
            console.log('Error' + e)

    }
        
        
    }
    const [smsCodeError, setSmsCodeError] = useState(false);
    const [smsConfirmed, setSmsconfirmed] = useState(false);
    const [required, setRequired] = useState(true);



    const documentRef = useRef(null);
    
    React.useEffect(() => {
        
        setFieldValue('gestor',  gestor);
    }, [gestor])
    
    React.useEffect(() => {
        if (Object.keys(values.clauses).length === 4) {
            return;
        }
        const negativeDefaultClauses = {};
        clauses.forEach(clause => {
            negativeDefaultClauses[clause.id] = getUnCheckedValue(clause);
        });
        setFieldValue('clauses', negativeDefaultClauses);
    }, [clauses]);
    
    const expresiones = {
        apellido2: /^[a-zA-Z]$/
    }

    useEffect(() => {
        if (values.hash === '') {
            setFieldValue('hash', generateUuid());
        }
    }, []);


    const sendSms = () => {
        onSendSms({hash: values.hash, phone: values.phone}).then(a => {
            setFieldValue('smsSended', true);
        }).catch(e => {

        })
    }

    const confirmSmsAction = () => {
        const code = values.confirmPhone;
        onConfirmSms({code, hash: values.hash}).then(res => {
            if (res === true) {
                setFieldValue('smsConfirmed', true);
                setSmsconfirmed(true);
                setSmsCodeError(false);
            } else {
                setSmsconfirmed(false);
                setSmsCodeError(true);
            }
        }).catch(e => {

        })
    }
    let phoneConfirmationEnabled = getPhoneConfirmedEnabled(campaign, user);
    const getStyleConfirmPhone = () => {
        return (values.smsSended === true && !values.smsConfirmed) ? {display: 'block'} : {display: 'none'};
    }


    const products = getInterestedProducts(campaign).split(","); // Se utiliza para escoger un tipo de producto

    const calls = getPreferenceCall(campaign).split(","); // Se utiliza para escoger un horario de llamadas

    const housing = getTypeHousing(campaign).split(","); // Se utiliza para escoger un tipo de vivienda

    const homes = getTypeHome(campaign).split(","); // Se utiliza para escoger un tipo de hogar

    const uses = getUseAssistance(campaign).split(","); // Se utiliza para escoger un uso de la asistencia
        
    return (
        <div className="box">
                           
            <h1>Alta de Lead</h1>
            <Field name="gestor" component={MaterialTextField}
                           label="Gestor (código)" variant="outlined" value={gestor} disabled
                                                      
             />
            <Divider/>
            <form onSubmit={submit}>
                <Grid className="register-lead-form-block" columns={1} columnGap="0" rowGap="15px">
                    <h2>Datos Personales</h2>
                    <Divider/>
                    <Field name="name" required
                           label="Nombre" component={MaterialTextField} variant="outlined"/>
                    <Field name="surname1" required component={MaterialTextField}
                           label="Primer Apellido" variant="outlined"/>
                    <Field name="surname2"  required component={MaterialTextField}
                           label="Segundo Apellido"  variant="outlined"/>

                    <Field name="documentType" variant="outlined" 
                           label="Selecciona el Tipo de Documento"
                           component={SelectField}
                           errortext="Escoge un tipo de Documento" labelId="register-lead-document-type-label"
                           onChangeField={() => {
                               setFieldValue('document', '');
                           }}
                    >
                        {documents.map((documentType) => {
                            if( documentType.name == 'CIF' || documentType.name == 'NIF' || documentType.name == 'NIE'){
                            return <MenuItem key={documentType.name}
                                             value={documentType.idEvent}>{documentType.showName}</MenuItem>
                            }
                        })}
                    </Field>

                    <Field name="document" disabled={values.documentType === undefined || values.documentType === ''}
                            variant="outlined"
                            innerRef={documentRef}
                           component={MaterialTextField}
                           label="Documento"/>   

                    {phoneConfirmationEnabled ?
                        <div className={'phone-field'}>
                            <Field name="phone" disabled={values.smsSended ? true : false} required variant="outlined"
                                   component={MaterialTextField} type="telefono"
                                   label="Teléfono" helperText="Se tendrá que confirmar por SMS el teléfono"/>
                            {values.smsSended === false ?
                                <React.Fragment>
                                    <div>
                                        <Button disabled={props.errors.phone ? true : false} onClick={sendSms}
                                                variant="contained" color="secondary" type="button">
                                            Enviar SMS
                                        </Button>
                                    </div>
                                </React.Fragment>
                                : null}
                            <React.Fragment>
                                <div style={getStyleConfirmPhone()}>
                                    <Field name="confirmPhone" required variant="outlined" component={MaterialTextField}
                                           type="telefono"
                                           label="Código de confirmación de teléfono"/>
                                    <a onClick={sendSms} className="cursor">
                                        Volver a enviar SMS
                                    </a>
                                </div>
                                <div><Button variant="contained" color="secondary" type="button"
                                             onClick={confirmSmsAction} style={getStyleConfirmPhone()}>
                                    Confirmar
                                </Button>
                                    {smsCodeError ? <div className="error-form">El código no es correcto</div> : null}
                                </div>
                            </React.Fragment>

                        </div>
                        : <Field name="phone" required variant="outlined" component={MaterialTextField} type="telefono"
                                 label="Teléfono" helperText="Debes poner un número de teléfono válido"/>
                     }                                  

                    <Field name="email" required
                            variant="outlined"
                            component={MaterialTextField}
                           label="Email"/> 

                    <Field name="codigoPostal" required
                            variant="outlined"
                            component={MaterialTextField}
                           label="Código Postal"/>  


                    <Field name="interestedProduct" variant="outlined"
                           required 
                           label="Producto Interesado"
                           component={SelectField}
                           errortext="Escoge un tipo de Producto" labelId="register-lead-product-type-label"

                           onChangeField={() => {
                            setFieldValue('typeHousing', '');
                            setFieldValue('typeHome', '');
                            setFieldValue('useAssistance', '');
                            
                        }}
                    >
                        {products.map((product,index) => {
                            return <MenuItem key={index}
                                             value={product}>{product}</MenuItem>
    
                        })}
                    </Field>                                                                               
                                                                                   

                    <Divider/>
                    <h2>Llamada</h2>

                    <Field name="preferedCall" variant="outlined"
                           required
                           label="Preferencia de llamada"
                           component={SelectField}
                           errortext="Escoge un horario de llamada" labelId="register-lead-calls-type-label"
                    >
                        {calls.map((call,index) => {
                            return <MenuItem key={index}
                                             value={call}>{call}</MenuItem>
    
                        })}
                    </Field>

                    {values.interestedProduct === 'Alarma'?
                    <Field name="typeHousing" variant="outlined"
                        required
                           label="Tipo de Vivienda"
                           component={SelectField}
                           errortext="Escoge un tipo de vivienda" labelId="register-lead-housings-type-label"
                           onChangeField={() => {
                            setFieldValue('typeHome', '');
                        }}>
                        {housing.map((house,index) => {
                            return <MenuItem key={index}
                                             value={house}>{house}</MenuItem>
    
                        })}
                    </Field>
                        :
                        null
                    }

                    {values.typeHousing === 'Hogar'?
                    <Field name="typeHome" variant="outlined"
                        required
                           label="Tipo de Hogar"
                           component={SelectField}
                           errortext="Escoge un tipo de hogar" labelId="register-lead-homes-type-label"
                    >
                        {homes.map((home,index) => {
                            return <MenuItem key={index}
                                             value={home}>{home}</MenuItem>
    
                        })}
                    </Field>
                        :
                    null
                    }

                    {values.interestedProduct === 'Teleasistencia'?
                    <Field name="useAssistance" variant="outlined"
                        required
                           label="Uso Asistencia"
                           component={SelectField}
                           errortext="Escoge un uso de asistencia" labelId="register-lead-uses-type-label"
                    >
                        {uses.map((use,index) => {
                            return <MenuItem key={index}
                                             value={use}>{use}</MenuItem>
    
                        })}
                    </Field>
                        :
                    null
                    }


                    <Field name="observations" variant="outlined" component={MaterialTextField} type="text"
                           label="Observaciones" multiline={true} rowsMax={14} rows={8}/>
                           
                           
                    <Divider/>
                    
                    <FormGroup>
                        {clauses.map((clause, index) => {
                            return (<Field key={index} 
                                           name={`clauses[${clause.id}]`}
                                           component={CheckboxField}
                                           arrayindex="clauses"
                                           index={clause.id}
                                           requiredFunction={setRequired}
                                           label={clause.description} checkedValue={() => {
                                    return getCheckedValue(clause)
                                }}
                                           uncheckedValue={() => {
                                               return getUnCheckedValue(clause)
                                               
                                           }}/>
                            )
                        })}

                    {getClause(campaign)}
                    
                    <br></br>
                    <br></br>

                    {getWeb(campaign)}

                    </FormGroup>
                    
                     <Divider/>
                    
                     
                </Grid>

                <div style={{margin: '15px'}}>
                    {!smsConfirmed && phoneConfirmationEnabled ? <p className="error-form">
                        
                    </p> : null}
                </div>
                <Button className="register-user-button"
                        disabled={!isValid}
                        variant="contained" color="secondary" type="submit">Registrar Lead</Button>
            </form>
        </div>
    )
}
const schema = ({user, campaign, props}) => {

    const fields = {
        name: Yup.string().required('Obligatorio'),
        document: Yup.string(),    
        documentType: Yup.string(),        
        surname1: Yup.string().required('Obligatorio'),
        gestor: Yup.string(),
        surname2: Yup.string().required('Obligatorio'),
        observations: Yup.string().test('len', 'No puede contener mas de 150 carácteres', val => {
            if (!val) {
                return true;
            }
            return val.length <= 150
        }),              
        phone: Yup.string().required('Obligatorio').test('is-phone', "No es un número de teléfono válido", value => validatePhone(value)),
        email: Yup.string().required('Obligatorio'),
        codigoPostal: Yup.string().required('Obligatorio'),
        hash: Yup.string(),
        interestedProduct: Yup.string().required('Obligatorio'),
        preferedCall: Yup.string().required('Obligatorio'),
        typeHousing: Yup.string().when('interestedProduct',{
            is: 'Alarma',
            then: Yup.string().required('Obligatorio'),
            otherwise: Yup.string(),
        }),
        typeHome:Yup.string().when('typeHousing',{
            is: 'Hogar',
            then: Yup.string().required('Obligatorio'),
            otherwise: Yup.string(),
        }),
        useAssistance: Yup.string().when('interestedProduct',{
            is: 'Teleasistencia',
            then: Yup.string().required('Obligatorio'),
            otherwise: Yup.string(),
        })
    }

    let phoneConfirmationEnabled = getPhoneConfirmedEnabled(campaign, user);
    if (phoneConfirmationEnabled) {
        fields.smsConfirmed = Yup.boolean().required('Debes confirmar el teléfono');
        fields.smsSended = Yup.boolean(false)        
    }
    const res = Yup.object().shape(fields);

    return res;
}

RegisterLeadSicorForm = withFormik(
    {
        validationSchema: schema,
       
        mapPropsToValues: (props) => {
            let phoneConfirmationEnabled = getPhoneConfirmedEnabled(props.campaign, props.user);
            let clauseText = getClause(props.campaign);
            let web = getWeb(props.campaign);
            const fields = {
                name: '',
                surname1: '',
                gestor: '',
                surname2: '',
                document: '',
                documentType: '',
                phone: '',
                email: '',
                codigoPostal: '',
                hash: '' ,
                interestedProduct: '',
                preferedCall: '',
                typeHousing: '',
                typeHome: '',
                useAssistance: '',
                observations: '',
                smsSended: false,
                clauses: {}

               
               
            };

            fields.smsSended = false;
            fields.smsConfirmed = false;
            
            return fields;
        },
        handleSubmit: async (vals, props) => {
            props.setSubmitting(false);
            const values = {...vals};
            
            console.log(values)
            log(`Enviando Lead`, "info");
            return await props.props.onLeadRegister(values)
                .then((e) => {
                    props.resetForm();
                    //props.setFieldValue('observations', '');
                    props.setFieldValue('hash', generateUuid());
                    props.setFieldValue('gestor',props.props.user.code);
                    props.setFieldValue('confirmPhone', '');
                    const negativeDefaultClauses = {};
                    const clauses = props.props.defaultClauses;
                    clauses.forEach(clause => {
                        negativeDefaultClauses[clause.id] = getUnCheckedValue(clause);
                    });
                    props.setFieldValue('clauses', negativeDefaultClauses);
                    log(`Lead Enviado con éxito`, "info");
                });
        },
        displayName: 'Registrar Lead',
    }
)(RegisterLeadSicorForm);

const mapDispatchToProps = dispatch => {
    let timer = null;
    return {
        onLeadRegister: async data => {
            return await registerLeadSicor(data)(dispatch)
        },
        onSendSms: async data => {
            return await sendSmsConfirmationEvent(data)(dispatch)
        },
        onConfirmSms: async data => {
            return await confirmSmsEvent(data)(dispatch)
        },
        onCheckDocument: (document, setFieldError) => {
            let value = undefined;
            if (document.target) {
                value = document.target.value;
            } else {
                value = document;
            }

            clearTimeout(timer);
            timer = setTimeout(() => call(
                () => {
                    return documentExists(value).then((res) => {
                        setFieldError('document', 'El documento ya está dado de alta')
                        return 'El documento ya está registrado';
                    }).catch((err) => {
                        setFieldError('document', null);
                        return null;
                    })
                }
                , 'check-document', false)(dispatch), 1000);

        }
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        campaign: state.campaign
    }
}


RegisterLeadSicorForm = connect(mapStateToProps, mapDispatchToProps)(RegisterLeadSicorForm);
export default RegisterLeadSicorForm;




