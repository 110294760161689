import React, {useEffect, useRef, useState} from "react";
import './RegisterLead.scss';
import * as Yup from 'yup';
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import {getRegisterLeadConfigDispatch, registerLeadDental} from "../LeadAction";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import {Field, withFormik} from "formik";
import {connect} from "react-redux";
import {Grid} from "../../../comun/Grid/Grid";
import MaterialTextField from "../../../comun/Forms/MaterialTextField";
import CheckboxField from "../../../comun/Forms/CheckboxField";
import CheckboxField2 from "../../../comun/Forms/CheckboxField2";
import {validatePhone,validateSurname2} from "../../../../validation/basicValidation";
import SelectField from "../../../comun/Forms/SelectField";
import {AutocompleteUser} from "../../../comun/AutocompleteUser/AutocompleteUser";
import {hasAnyRole, hasRole} from "../../../../auth/util";
import {filterStands} from "../../Stand/StandAction";
import {documentExists} from "../../Users/UserAction";
import {call} from "../../../../util/utilXhr";
import SignatureCanvas from "react-signature-canvas";
import {validCif, validNif, validNifNoAdamo} from "../../../../util/utilValid";
import {existsKeyInCampaign, getValueOrDefault} from "../../../../util/utilCampaign";
import {log} from "../../../../util/utilLog";
import {generateUuid} from "../../../../util/utilUuid";
import {confirmSmsEvent, sendSmsConfirmationEvent} from "../SmsAction";
import ButtonField from "../LeadFormComponents/ButtonField";
import { Typography, LinearProgress, Popover } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { set } from "date-fns";

import { useParams } from "react-router-dom";





const getPhoneConfirmedEnabled = (campaign, user) => {
    let phoneConfirmationEnabled = getValueOrDefault('lead.register.sms_confirmation.enable', campaign, 'false');
    phoneConfirmationEnabled = (phoneConfirmationEnabled === 'true');
    let roles = getValueOrDefault('lead.register.sms_confirmation.roles_excluded', campaign, '');
    roles = roles.split(',');
    
    console.log("phoneConfirmationEnabled = "+phoneConfirmationEnabled);
    console.log("hasAnyRole(user, roles) = "+hasAnyRole(user, roles));
    console.log("phoneConfirmationEnabled && !hasAnyRole(user, roles) = "+phoneConfirmationEnabled && !hasAnyRole(user, roles));
    
    return phoneConfirmationEnabled && !hasAnyRole(user, roles);
}

const getClause = (campaign) => {
    let clauseText = getValueOrDefault('lead.register.clause_Sicor', campaign, '');
    return clauseText;
}

const getWeb = (campaign) => {
    let web = getValueOrDefault('sicor.web', campaign, '');
    return web;
}

const getCheckedValue = (clause) => {
    return clause.privacyClausePrivacyClauseValues.find(a => a.privacyClauseValue.value === "SI");
}
const getUnCheckedValue = (clause) => {
    return clause.privacyClausePrivacyClauseValues.find(a => a.privacyClauseValue.value === "Off");
}
const getCheckedValueInterested = (check) => {
    return check.interestedCheckInterestedCheckValues.find(a => a.interestedCheckValue.value === "SI");
}
const getUnCheckedValueInterested = (check) => {
    return check.interestedCheckInterestedCheckValues.find(a => a.interestedCheckValue.value === "NO");
}



export let RegisterLeadDental = ({user, onRegisterLeadConfig, ...props}) => {
    const [documents, setDocuments] = useState([]);
    const [clauses, setClauses] = useState([]);
    const [checks, setChecks] = useState([]);
    const [pacientes,setPacientes] = useState([]);
    const [franjaHoraria,setFranjaHoraria] = useState([]);
    const [misClauses, setMisClauses] = useState([]);
        
    const {gestor,setGestor} =  useParams();
    
    console.log("mi usuario");
    console.log(props.user);
    console.log("mi gestor ="+gestor);
                
	const setMyNumeroPacientes = e => {
		console.log("numero de pacientes = "+e.target.value);
	}
    
    //-------
    //Prueba
    //--------
    const getPacientesEstaticos = () => {
        setPacientes(
            [
		        {
	                "id": 7,
	                "tipo": "pacienteTipo1",
	                "nombre": "pacientes tipo 1"
	                
	            },
	            {
	                "id": 8,
	                "tipo": "pacienteTipo2",
	                "nombre": "pacientes tipo 2"
	            },
	            {
	                "id": 9,
	                "tipo": "pacienteTipo3",
	                "nombre": "pacientes tipo 3"
	            }
            ]
        )
    };
    
    const getFranjaHorariaEstatico = () => {
        setFranjaHoraria(
            [
		        {
	                "id": 7,
	                "tipo": "manana",
	                "franja": "09:00 a 12:00",
	                "horaDesde":"09:00",
	                "horaHasta":"12:00"
	                
	            },
	            {
	                "id": 8,
	                "tipo": "mediodia",
	                "franja": "12:00 a 15:00",
	                "horaDesde":"12:00",
	                "horaHasta":"15:00"
	            },
	            {
	                "id": 9,
	                "tipo": "tarde",
	                "franja": "16:00 a 20:00",
	                "horaDesde":"16:00",
	                "horaHasta":"20:00"
	            }
            ]
        )
    };    
    
    const getClausulasEstatico = () => {
        setMisClauses(
            [
		        {
	                "id": 7,
	                "tipo": "primera",
	                "privacyClauseValue":"SI",
	                "nombre": "He leído y entendido el contenido de este formulario, por lo que voluntariamente acepto el mismo"
	                
	            },
	            {
	                "id": 8,
	                "tipo": "segunda",
	                "privacyClauseValue":"SI",
	                "nombre": "Quiero que xxxx me envíe por cualquier medio, información comercial sobres sus actividades, productos y servicios"
	            }
            ]
        )
    };        
    
    React.useEffect(() => {
        onRegisterLeadConfig().then((res) => {
            setDocuments(res.documentTypeList);
            setClauses(res.privacyClauses);
            setChecks(res.interestedChecks);
        })
        
        getPacientesEstaticos();
        getFranjaHorariaEstatico();
        getClausulasEstatico();
    }, []);
    return (
        <RegisterLeadDentalForm 
           documents={documents} 
           pacientes={pacientes}
           franjaHoraria={franjaHoraria}
           gestor={gestor}
           clauses={clauses} 
           misClauses={misClauses}
           defaultClauses={[...clauses]}
           checks={checks}
           defaultChecks={[...checks]}
         />
    )
}

const mapRegisterLeadDispatchToProps = dispatch => {
    return {
        onRegisterLeadConfig: async () => {
            return await getRegisterLeadConfigDispatch()(dispatch);
        }
    }
}
RegisterLeadDental = connect(null, mapRegisterLeadDispatchToProps)(RegisterLeadDental);

export let RegisterLeadDentalForm = ({
                                   onCheckDocument,
                                   onConfirmSms,
                                   campaign,
                                   clauses,
                                   misClauses,
                                   checks,
                                   user,
                                   documents,
                                   pacientes,
                                   franjaHoraria,
                                   gestor,
                                   getFieldValue,
                                   setFieldValue,
                                   setFieldError,
                                   isSubmitting,
                                   onSendSms,
                                   handleSubmit,
                                   values,
                                   defaultClauses,
                                   defaultChecks,
                                   setErrors,
                                   isValid,
                                   ...props
                               }) => {
    const getStandFilterParams = () => {
        const res = {};

        let key = 'teamLeader';
        if (hasRole(user, 'COMMERCIAL_PROMOTER')) {
            key = 'commercialPromoter';
        }
        res[key] = user.id;
        res.cleaned = true;
        return res;
    }
    
    const [numeroPacientes, setNumeroPacientes] = useState(0);
    
    const submit = (vals) => {
        log(`Empezando a registrar Lead de ${user.id}`, "info");
        
        /*
          **********************************************************
          * CANVAS ES LO DE LA FIRMA
          **********************************************************        
         */
        
        /*
        if (canvasRef.isEmpty()) {
            setCanvasEmpty(true);
            vals.preventDefault();
            return false;
        } else {
            setCanvasEmpty(false);
            setFieldValue('sign', canvasRef.getTrimmedCanvas()
                .toDataURL('image/png').replace('data:image/png;base64,', ''))
            try {
                canvasRef.clear();
                handleSubmit(vals);
                if (Object.keys(values.clauses).length === 4) {
                    return;
                }
                setSmsconfirmed(false);
                canvasRef.clear();
            } catch (e) {
                console.log('Error' + e)

            }

        }
        */
        
        try {                
                handleSubmit(vals);
                if (Object.keys(values.clauses).length === 4) {
                    return;
                }
                setSmsconfirmed(false);               
        } catch (e) {
                console.log('Error' + e)

        }
        
        
        
    }
    const [canvasWidth, setCanvasWidth] = useState(100);
    const [canvasHeight, setCanvasHeight] = useState(56);
    const [canvasEmpty, setCanvasEmpty] = useState(false);
    const [canvasRef, setCanvasRef] = useState();
    const [smsCodeError, setSmsCodeError] = useState(false);
    const [smsConfirmed, setSmsconfirmed] = useState(false);
   
    const [isLoadingImage, setIsLoadingImage] = useState(false);
    const [showingHelp, setShowingHelp] = useState(false);
    const [required, setRequired] = useState(true);



    const getCheckedValueInterestedAll = (checks) => {
        let valores = []
        checks.map(check =>{
           
            valores.push(check.interestedCheckInterestedCheckValues.find(a => a.interestedCheckValue.value === "SI"))
        })
        console.log(valores)
        return valores;
    }
    
    const getUnCheckedValueInterestedAll = (checks) => {
        let valores = []
        checks.map(check =>{
            valores.push(check.interestedCheckInterestedCheckValues.find(a => a.interestedCheckValue.value === "NO"))
        })
        return valores;
    }

    const documentRef = useRef(null);
    
     //---------------------------------------------------------
    //Valor del gestor que viene por defecto que es el código
    //---------------------------------------------------------
    
    React.useEffect(() => {
        let miGestor = gestor;
        
        setFieldValue('gestor',  miGestor);
    }, [values. miGestor])
    
    //---------------------------------------------------------
    //Método franjas cuando creas la campaña
    //---------------------------------------------------------
    
    React.useEffect(() => {
        let franja = getFranjas();
        if (franja.length === 0) {
            return;
        }
        franja = franja.find((f) => f.franja === values.franjas);
        setFieldValue('contactHourLast', franja.horaHasta);
        setFieldValue('contactHour', franja.horaDesde);        
    }, [values.franjas])
    
    //------------------------------------
    //Método de prueba con lo mío estático
    //------------------------------------
    {/*
    React.useEffect(() => {
        let franja = getFranjaHorariaPrueba();
        if (franja.length === 0) {
            return;
        }
        franja = franja.find((f) => f.franja === values.franjas);
        setFieldValue('contactHourLast', franja.horaHasta);
        setFieldValue('contactHour', franja.horaDesde);
    }, [values.franjas])
    */}
    
    //---------------------------------------------
    //Método obtener pacientes BBDD campaña dental
    //---------------------------------------------
    React.useEffect(() => {
        let tipo = getPacientes();
        if (tipo.length === 0) {
            return;
        }
        tipo = tipo.find((f) => f.nombre === values.tipos);
        setFieldValue('pacientesInteresados', tipo.nombre);   
        let numero = +tipo.nombre; 
        setNumeroPacientes(numero);  
    }, [values.tipos])    
    
    //----------------
    
    const resetCanvas = () => {
        canvasRef.clear();
    }

    const validateDocument = (value) => {
        return onCheckDocument(value, setFieldError);
    };
    React.useEffect(() => {
        setTimeout(() => {
            const elem = document.getElementById("canvas-container");
            if (elem !== null) {
                const width = Math.min(500, elem.getBoundingClientRect().width - 10);
                const heigth = Math.min(((width * 9) / 16), 200);
                setCanvasWidth(width);
                setCanvasHeight(heigth);
            }

        }, 100);
        if (Object.keys(values.clauses).length === 4) {
            return;
        }
        const negativeDefaultClauses = {};
        clauses.forEach(clause => {
            negativeDefaultClauses[clause.id] = getUnCheckedValue(clause);
        });
        setFieldValue('clauses', negativeDefaultClauses);
    }, [clauses]);

    React.useEffect(() => {
        setTimeout(() => {
            const elem = document.getElementById("canvas-container");
            if (elem !== null) {
                const width = Math.min(500, elem.getBoundingClientRect().width - 10);
                const heigth = Math.min(((width * 9) / 16), 200);
                setCanvasWidth(width);
                setCanvasHeight(heigth);
            }

        }, 100);
        
        /*
        if (Object.keys(values.checks).length === 4) {
            return;
        }
        */
        const defaultChecksInterested = {};
        checks.forEach(check => {
            if (check.id == 4){
                defaultChecksInterested[check.id] = getCheckedValueInterested(check);
               
            }else{
                defaultChecksInterested[check.id] = getUnCheckedValueInterested(check);
            }
        });
        setFieldValue('checks', defaultChecksInterested);
    }, [checks]);

    useEffect(() => {
        if (values.hash === '') {
            setFieldValue('hash', generateUuid());
        }
    }, []);
    const getFranjas = () => {
        if (existsKeyInCampaign('create_lead.franjas', campaign)) {
	        console.log(JSON.parse(getValueOrDefault('create_lead.franjas', campaign)));
            return JSON.parse(getValueOrDefault('create_lead.franjas', campaign));
        }
        return [];
    }
    
    const getPacientes = () => {
        if (existsKeyInCampaign('create_pacientes', campaign)) {
	        console.log(JSON.parse(getValueOrDefault('create_pacientes', campaign)));
            return JSON.parse(getValueOrDefault('create_pacientes', campaign));
        }
        return [];
    }    
    
    const getFranjaHorariaPrueba = () => {
        return(
            [
	           {
	                "id": 6,
	                "tipo": "inicio",
	                "franja": "LLAMAR YA",
	                "horaDesde":"",
	                "horaHasta":""
	                
	            },
		        {
	                "id": 7,
	                "tipo": "manana",
	                "franja": "09:00 a 12:00",
	                "horaDesde":"09:00",
	                "horaHasta":"12:00"
	                
	            },
	            {
	                "id": 8,
	                "tipo": "mediodia",
	                "franja": "12:00 a 15:00",
	                "horaDesde":"12:00",
	                "horaHasta":"15:00"
	            },
	            {
	                "id": 9,
	                "tipo": "tarde",
	                "franja": "16:00 a 20:00",
	                "horaDesde":"16:00",
	                "horaHasta":"20:00"
	            }
            ]
        )
    };    
    
    const clearContactHourLast = (e) => {
        setFieldValue('contactHourLast', '');
    }
    const getBonusCard = () => {
        if (existsKeyInCampaign('create_lead.target_bonus.data', campaign)) {
            let data = getValueOrDefault('create_lead.target_bonus.data', campaign, '{}');
            data = JSON.parse(data);
            return Object.values(data);
        }
        return [];
    }
    const sendSms = () => {
        onSendSms({hash: values.hash, phone: values.phone}).then(a => {
            setFieldValue('smsSended', true);
        }).catch(e => {

        })
    }

    const confirmSmsAction = () => {
        const code = values.confirmPhone;
        onConfirmSms({code, hash: values.hash}).then(res => {
            if (res === true) {
                setFieldValue('smsConfirmed', true);
                setSmsconfirmed(true);
                setSmsCodeError(false);
            } else {
                setSmsconfirmed(false);
                setSmsCodeError(true);
            }
        }).catch(e => {

        })
    }
    let phoneConfirmationEnabled = getPhoneConfirmedEnabled(campaign, user);
    const getStyleConfirmPhone = () => {
        return (values.smsSended === true && !values.smsConfirmed) ? {display: 'block'} : {display: 'none'};
    }
    const isValidPhoneConfirmed = () => {
        if (phoneConfirmationEnabled === true) {
            return smsConfirmed && isValid;
        }
        return isValid;
    }

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const openPopover = Boolean(anchorEl);
    const popoverId = openPopover ? 'simple-popover' : undefined;
    const expresiones = {
        apellido2: /^[a-zA-Z]$/
    }
        
    return (
        <div className="box">
            
                           
            <h1>Alta de Lead</h1>
            <Field name="gestor" component={MaterialTextField}
                           label="Gestor (código)" variant="outlined" value={gestor} disabled
                                                      
             />
            <Divider/>
            <form onSubmit={submit}>
                <Grid className="register-lead-form-block" columns={1} columnGap="0" rowGap="15px">
                    <h2>Datos Personales</h2>
                    <Divider/>
                    <Field name="name" required
                           label="Nombre" component={MaterialTextField} variant="outlined"/>
                    <Field name="surname1" required component={MaterialTextField}
                           label="Primer Apellido" variant="outlined"/>
                    <Field name="surname2"  component={MaterialTextField}
                           label="Segundo Apellido"  variant="outlined"/>
                    <Field name="documentType" variant="outlined" 
                           label="Selecciona el Tipo de Documento"
                           component={SelectField}
                           errortext="Escoge un tipo de Documento" labelId="register-lead-document-type-label"
                           onChangeField={() => {
                               setFieldValue('document', '');
                           }}
                    >
                        {documents.map((documentType) => {
                            if( documentType.name == 'CIF' || documentType.name == 'NIF' || documentType.name == 'NIE'){
                            return <MenuItem key={documentType.name}
                                             value={documentType.idEvent}>{documentType.showName}</MenuItem>
                            }
                        })}
                    </Field>
                    <Field name="document" disabled={values.documentType === undefined || values.documentType === ''}
                            variant="outlined"
                           component={MaterialTextField} type="text"
                           innerRef={documentRef}
                           label="Documento"
                    />
                    
                   {phoneConfirmationEnabled ?
                        <div className={'phone-field'}>
                            <Field name="phone" disabled={values.smsSended ? true : false} required variant="outlined"
                                   component={MaterialTextField} type="telefono"
                                   label="Teléfono" helperText="Se tendrá que confirmar por SMS el teléfono"/>
                            {values.smsSended === false ?
                                <React.Fragment>
                                    <div>
                                        <Button disabled={props.errors.phone ? true : false} onClick={sendSms}
                                                variant="contained" color="secondary" type="button">
                                            Enviar SMS
                                        </Button>
                                    </div>
                                </React.Fragment>
                                : null}
                            <React.Fragment>
                                <div style={getStyleConfirmPhone()}>
                                    <Field name="confirmPhone" required variant="outlined" component={MaterialTextField}
                                           type="telefono"
                                           label="Código de confirmación de teléfono"/>
                                    <a onClick={sendSms} className="cursor">
                                        Volver a enviar SMS
                                    </a>
                                </div>
                                <div><Button variant="contained" color="secondary" type="button"
                                             onClick={confirmSmsAction} style={getStyleConfirmPhone()}>
                                    Confirmar
                                </Button>
                                    {smsCodeError ? <div className="error-form">El código no es correcto</div> : null}
                                </div>
                            </React.Fragment>

                        </div>
                        : <Field name="phone" required variant="outlined" component={MaterialTextField} type="telefono"
                                 label="Teléfono" helperText="Debes poner un número de teléfono válido"/>
                     }                    
                                                                        
                    
                    
                                                                                   

                    <h2>Cita</h2>
                    
                    {/*
                    <Field name="pacientesInteresados" variant="outlined" 
                           label="Pacientes interesados"
                           component={SelectField}
                           errortext="Escoge un tipo de paciente" 
                           onChangeField={() => {
                               setFieldValue('paciente', '');
                           }}
                    >
                        {pacientes.map((pacientesInteresados) => {
                            
                            return <MenuItem key={pacientesInteresados.id}
                                             value={pacientesInteresados.tipo}>{pacientesInteresados.nombre}</MenuItem>
                            
                        })}
                    </Field>
                    */}
                    
                    <Field name="tipos" variant="outlined" 
                           label="Pacientes interesados adicionales"
                           component={SelectField}
                           errortext="Selecciona numero pacientes"
                           labelId="register-lead-document-type-label"
                           onChangeField={() => {
                               setFieldValue('paciente', '');
                           }}
                    >
                        {getPacientes().map((v, k) => {
                            return <MenuItem key={k}
                                             value={v.nombre}>{v.nombre}</MenuItem>
                        })}

                    </Field>
                    
                    {numeroPacientes >= 1  ?
                    <Field name="namePaciente1" required
                           label="Nombre paciente 1" component={MaterialTextField} variant="outlined"/>
                     :
                    null
                    }
                           
                    {numeroPacientes >= 1 ?
                    <Field name="surNamePaciente1" required
                           label="Apellidos paciente 1" component={MaterialTextField} variant="outlined"/>  
                    :
                    null
                    }
                    
                    
                    {numeroPacientes >= 2 ?
                    <Field name="namePaciente2" required
                           label="Nombre paciente 2" component={MaterialTextField} variant="outlined"/>
                           
                    :null}
                    
                    {numeroPacientes >= 2  ?
                    <Field name="surNamePaciente2" required
                           label="Apellidos paciente 2" component={MaterialTextField} variant="outlined"/> 
                    :null}
                           
                    {numeroPacientes >= 3  ?
                    <Field name="namePaciente3" required
                           label="Nombre paciente 3" component={MaterialTextField} variant="outlined"/>
                    :null}
                           
                    {numeroPacientes >= 3  ?
                    <Field name="surNamePaciente3" required
                           label="Apellidos paciente 3" component={MaterialTextField} variant="outlined"/> 
                    :null}
                           
                    {numeroPacientes >= 4  ?
                    <Field name="namePaciente4" required
                           label="Nombre paciente 4" component={MaterialTextField} variant="outlined"/>
                    :null}
                           
                    {numeroPacientes >= 4  ?
                    <Field name="surNamePaciente4" required
                           label="Apellidos paciente 4" component={MaterialTextField} variant="outlined"/> 
                    :null}   
                           
                    {numeroPacientes >= 5  ?
                    <Field name="namePaciente5" required
                           label="Nombre paciente 5" component={MaterialTextField} variant="outlined"/>
                    :null}
                    
                    {numeroPacientes >= 5  ?
                    <Field name="surNamePaciente5" 
                           label="Apellidos paciente 5" component={MaterialTextField} variant="outlined"/>
                    :null}
                    
                    {numeroPacientes >= 6  ? 
                    <Field name="namePaciente6" required
                           label="Nombre paciente 6" component={MaterialTextField} variant="outlined"/>
                    :null}
                           
                    {numeroPacientes >= 6  ?
                    <Field name="surNamePaciente6" required
                           label="Apellidos paciente 6" component={MaterialTextField} variant="outlined"/>  
                    :null}

                    {numeroPacientes >= 7  ?
                    <Field name="namePaciente7" required
                           label="Nombre paciente 7" component={MaterialTextField} variant="outlined"/>
                    :null}
                           
                    {numeroPacientes >= 7  ?
                    <Field name="surNamePaciente7" required
                           label="Apellidos paciente 7" component={MaterialTextField} variant="outlined"/> 
                    :null}
                          
                    {numeroPacientes >= 8  ? 
                    <Field name="namePaciente8" required
                           label="Nombre paciente 8" component={MaterialTextField} variant="outlined"/>
                    :null}
                           
                    {numeroPacientes >= 8  ?
                    <Field name="surNamePaciente8" required
                           label="Apellidos paciente 8" component={MaterialTextField} variant="outlined"/> 
                    :null}
                           
                    {numeroPacientes >= 9  ?
                    <Field name="namePaciente9" required
                           label="Nombre paciente 9" component={MaterialTextField} variant="outlined"/>
                    :null}
                           
                    {numeroPacientes >= 9  ?
                    <Field name="surNamePaciente9" required
                           label="Apellidos paciente 9" component={MaterialTextField} variant="outlined"/>   
                    :null}                                                                                                                                   
                    
                    
                    
                    {/*********************************************** */}
                    {/* VERDADERAS FRANJAS DE BASE DE DATOS VALS      */}
                    {/*********************************************** */}
                    
                    
                    <Field name="franjas" variant="outlined" required
                           label="Franja Horarias de contacto"
                           component={SelectField}
                           errortext="Selecciona una franja horaria de contacto"
                           labelId="register-lead-document-type-label"
                    >
                        {getFranjas().map((v, k) => {
                            return <MenuItem key={k}
                                             value={v.franja}>{v.franja}</MenuItem>
                        })}

                    </Field>
                    
                                                                                                                                       

                    <Divider/>
                    <h2>Observaciones</h2>
                    <Field name="observations" variant="outlined" component={MaterialTextField} type="text"
                           label="Observaciones" multiline={true} rowsMax={14} rows={8}/>
                           
                           
                    <Divider/>
                    
                    
                    <h2>Clausulas de Privacidad</h2>
                    <Divider/>
                                       
                    {/*
                    <FormGroup>
                        {misClauses.map((clause) => {
                            return (<Field key={clause.id}
                                           name={`misClauses[${clause.tipo}]`}
                                           component={CheckboxField}                                                                                     
                                           label={clause.nombre} />
                            )
                        })}
                    
                    
                    </FormGroup>
                    */}
                    
                    <FormGroup>
                        {clauses.map((clause, index) => {
                            return (<Field key={index} 
                                           name={`clauses[${clause.id}]`}
                                           component={CheckboxField}
                                           arrayindex="clauses"
                                           index={clause.id}
                                           requiredFunction={setRequired}
                                           label={clause.description} checkedValue={() => {
                                    return getCheckedValue(clause)
                                }}
                                           uncheckedValue={() => {
                                               return getUnCheckedValue(clause)
                                               
                                           }}/>
                            )
                        })}
                    
                    <br></br>
                    {getClause(campaign)}
                    
                    <br></br>
                    <br></br>

                    <a href={getWeb(campaign)} target="_blank">{getWeb(campaign)}</a>
                    </FormGroup>
                    
                     <Divider/>
                     
                    {/*
                    <h2>Firma</h2>
                    <Divider/>
                    <div id="canvas-container">
                        <p>Intenta rellenar todo el cuadro</p>
                        <div className="canvas-container">
                            <SignatureCanvas penColor='black'
                                             ref={(ref) => setCanvasRef(ref)}
                                             canvasProps={{
                                                 width: canvasWidth,
                                                 height: canvasHeight,
                                                 className: 'sigCanvas'
                                             }}/>
                        </div>
                        {canvasEmpty ? <p className="error-form">
                            La firma está vacia
                        </p> : null}
                        <Button variant="contained" color="primary" type="button" onClick={resetCanvas}>Limpiar
                            Firma</Button>
                    </div>
                    */}

                </Grid>
                <div style={{margin: '15px'}}>
                    {!smsConfirmed && phoneConfirmationEnabled ? <p className="error-form">
                        
                    </p> : null}
                </div>
                <Button className="register-user-button"
                        disabled={!isValid}
                        variant="contained" color="secondary" type="submit">Registrar
                    Lead</Button>
            </form>
        </div>
    )
}
const schema = ({user, campaign, props}) => {
    const getConfiguracionCardBono = () => {

        if (existsKeyInCampaign('create_lead.cardbono.config',campaign)){
           return getValueOrDefault('create_lead.cardbono.config', campaign);
        }
        return;
    }

    const getConfiguracionCardCheck1 = () => {

        if (existsKeyInCampaign('create_lead.checks[1].config',campaign)){
           return getValueOrDefault('create_lead.checks[1].config', campaign);
        }
        return;
    }
    const getConfiguracionCardCheck2 = () => {

        if (existsKeyInCampaign('create_lead.cardcheck2.config',campaign)){
           return getValueOrDefault('create_lead.cardcheck2.config', campaign);
        }
        return;
    }
    const getConfiguracionCardCheck3 = () => {

        if (existsKeyInCampaign('create_lead.cardcheck3.config',campaign)){
           return getValueOrDefault('create_lead.cardcheck3.config', campaign);
        }
        return;
    }

    const fields = {
        name: Yup.string().required('Obligatorio'),
        document: Yup.string(),            
        surname1: Yup.string().required('Obligatorio'),
        gestor: Yup.string(),
        surname2: Yup.string(),
        observations: Yup.string().test('len', 'No puede contener mas de 150 carácteres', val => {
            if (!val) {
                return true;
            }
            return val.length <= 150
        }),        
        //cardcheck1: Yup.string().matches(new RegExp(getConfiguracionCardCheck1()), 'Formato incorrecto'),
        //cardcheck2: Yup.string().matches(new RegExp(getConfiguracionCardCheck2()), 'Formato incorrecto'),
        //cardcheck3: Yup.string().matches(new RegExp(getConfiguracionCardCheck3()), 'Formato incorrecto'),      
        documentType: Yup.string(),
        pacientesInteresados: Yup.string(),
        namePaciente1: Yup.string(),
        surNamePaciente1: Yup.string(),
        namePaciente2: Yup.string(),
        surNamePaciente2: Yup.string(),
        namePaciente3: Yup.string(),
        surNamePaciente3: Yup.string(),
        namePaciente4: Yup.string(),
        surNamePaciente4: Yup.string(),  
        namePaciente5: Yup.string(),
        surNamePaciente5: Yup.string(),
        namePaciente6: Yup.string(),
        surNamePaciente6: Yup.string(),
        namePaciente7: Yup.string(),
        surNamePaciente7: Yup.string(),
        namePaciente8: Yup.string(),
        surNamePaciente8: Yup.string(),
        namePaciente9: Yup.string(),
        surNamePaciente9: Yup.string(),          
        //preferenciaCita: Yup.string().required('Obligatorio'),        
        hash: Yup.string(),
        confirmPhone: Yup.string().required(''),                      
        phone: Yup.string().required('Obligatorio').test('is-phone', "No es un número de teléfono válido", value => validatePhone(value)),        
        franjas: Yup.string().required('Obligatorio'),
        contactHour: Yup.string(),        
        contatHourLast: Yup.string(),
    }
    let phoneConfirmationEnabled = getPhoneConfirmedEnabled(campaign, user);
    if (phoneConfirmationEnabled) {
        fields.smsConfirmed = Yup.boolean().required('Debes confirmar el teléfono');
        fields.smsSended = Yup.boolean(false)        
    }
    const res = Yup.object().shape(fields);

    return res;
}

RegisterLeadDentalForm = withFormik(
    {
        validationSchema: schema,
        mapPropsToValues: (props) => {
            let phoneConfirmationEnabled = getPhoneConfirmedEnabled(props.campaign, props.user);
            let clauseText = getClause(props.campaign);
            let web = getWeb(props.campaign);
            const fields = {
                name: '',
                surname1: '',
                gestor: '',
                surname2: '',
                documentType: '',
                pacientesInteresados: '',
                namePaciente1: '',
                surNamePaciente1: '',
        		namePaciente2: '',
		        surNamePaciente2: '',
		        namePaciente3: '',
		        surNamePaciente3: '',
		        namePaciente4: '',
		        surNamePaciente4: '',  
		        namePaciente5: '',
		        surNamePaciente5: '',
		        namePaciente6: '',
		        surNamePaciente6: '',
		        namePaciente7: '',
		        surNamePaciente7: '',
		        namePaciente8: '',
		        surNamePaciente8: '',
		        namePaciente9: '',
		        surNamePaciente9: '',
                //preferenciaCita: '',
                document: '',
                phone: '',               
                //checks: {},                
                //cardcheck1: '',
                //cardcheck2: '',
                //cardcheck3: '',                
                contactHourLast: '',   
                franjas: 'MAÑANA',  
                tipos: '0',           
                hash: '',
                smsSended: false,                               
                clauses: {},
                //misClauses: {},
                contactHour: ''
               
            };
            
            //fields.cardcheck1 = '';
            //fields.cardcheck2 = '';
            //fields.cardcheck3 = '';
            fields.smsSended = false;
            fields.smsConfirmed = false;
            return fields;
        },
        handleSubmit: async (vals, props) => {
            props.setSubmitting(false);
            const values = {...vals};
            
            console.log(values)
            log(`Enviando Lead`, "info");
            return await props.props.onLeadRegister(values)
                .then((e) => {
                    props.resetForm();
                    props.setFieldValue('observations', '');
                    props.setFieldValue('hash', generateUuid());
                    props.setFieldValue('confirmPhone', '');
                    const negativeDefaultClauses = {};
                    const clauses = props.props.defaultClauses;
                    clauses.forEach(clause => {
                        negativeDefaultClauses[clause.id] = getUnCheckedValue(clause);
                    });
                    props.setFieldValue('clauses', negativeDefaultClauses);
                    const defaultChecksInterested = {};
                    const checks = props.props.defaultChecks;
                    
                    checks.forEach(check => {
                        if (check.id == 4){
                            defaultChecksInterested[check.id] = getCheckedValueInterested(check);
                        }else{
                            defaultChecksInterested[check.id] = getUnCheckedValueInterested(check);
                        }
                    });
                    
                    props.setFieldValue('checks', defaultChecksInterested);
                    props.setFieldValue('observations', '');
                    log(`Lead Enviado con éxito`, "info");
                });
        },
        displayName: 'Registrar Lead',
    }
)(RegisterLeadDentalForm);

const mapDispatchToProps = dispatch => {
    let timer = null;
    return {
        onLeadRegister: async data => {
            return await registerLeadDental(data)(dispatch)
        },
        onSendSms: async data => {
            return await sendSmsConfirmationEvent(data)(dispatch)
        },
        onConfirmSms: async data => {
            return await confirmSmsEvent(data)(dispatch)
        },
        onCheckDocument: (document, setFieldError) => {
            let value = undefined;
            if (document.target) {
                value = document.target.value;
            } else {
                value = document;
            }

            clearTimeout(timer);
            timer = setTimeout(() => call(
                () => {
                    return documentExists(value).then((res) => {
                        setFieldError('document', 'El documento ya está dado de alta')
                        return 'El documento ya está registrado';
                    }).catch((err) => {
                        setFieldError('document', null);
                        return null;
                    })
                }
                , 'check-document', false)(dispatch), 1000);

        }
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        campaign: state.campaign
    }
}


RegisterLeadDentalForm = connect(mapStateToProps, mapDispatchToProps)(RegisterLeadDentalForm);
export default RegisterLeadDentalForm;




