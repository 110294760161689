import {Field, withFormik} from "formik";
import MaterialTextField from "../../../components/comun/Forms/MaterialTextField";
import Button from "@material-ui/core/Button";
import * as Yup from "yup";
import {changePassword, updateProfile} from "../../../components/sections/UpdateProfile/UpdateProfileAction";
import {connect} from "react-redux";
import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import { useParams, useLocation } from "react-router";
import {parse} from "qs";
import {withRouter} from "react-router-dom";
export  let RecoverPassword = (props)  => {
    const {handleSubmit, isSubmitting, isValid} = props;
    const params = useLocation();
    const search = params.search;
    const token = parse(search, { ignoreQueryPrefix: true }).token;
    console.log(props);
    useEffect(() => {
        props.setFieldValue('token', token);
    }, [])

    const useStyles = makeStyles((theme) => ({
        root: {
            '& > *': {
                margin: theme.spacing(1),
                "& > *": {
                    width: '100%'
                }
            },
            width: '65%',
            display: 'table',
            margin: '0 auto',
            "@media (max-width:800px)": {
                width: '100%',
                display: 'block'
            }
        },
    }));
    const classes = useStyles();
    return (
        <div className="login-container">
            <div className="login-main">
                <div className="login-main-logos">
                    <img  src={process.env.PUBLIC_URL + "/logo.png"} className="login-header-logo"  alt="Logo Kauto Iberdrola FL"/>
                </div>
                <div className="login-form">
            <form  onSubmit={handleSubmit} className={classes.root}>
                <h1>Cambiar tu contraseña</h1>
                <div>
                    <Field className="form-control"  name="password" component={MaterialTextField}    type="password" label="Contraseña"  />
                </div>
                <div>
                    <Field className="form-control"  name="confirmPassword" component={MaterialTextField}    type="password" label="Repite contraseña"  />
                </div>
                <div>
                    <Button type="submit" disabled={isSubmitting || (isValid === false)}  variant="contained" color="secondary">Cambiar Contraseña</Button>
                </div>
            </form>
                </div>
        </div>
        </div>
    )
}


RecoverPassword = withFormik({
    mapPropsToValues: () => {
        return {
            confirmPassword: '', password: '', token: ''
        }
    },
    handleSubmit: (values, props) => {
        props.setSubmitting(false);
        props.props.onChangePassword(values)
            .then(e => {
                props.resetForm();
                props.props.history.push("/");
            })
    },
    validationSchema: Yup.object().shape({
        confirmPassword: Yup.string().required('Obligatorio').oneOf([Yup.ref('password'), null], "Las contraseñas deben coincidir"),
        password: Yup.string().required('Obligatorio'),
    }),
})(RecoverPassword);

const mapDispatchToProps = dispatch => {
    return {
        onChangePassword: data => {
            return changePassword(data)(dispatch)
        }
    }
}

const mapPropsToState = state => {
    return {
        user: state.user,
    }
}

RecoverPassword = connect(mapPropsToState, mapDispatchToProps)(RecoverPassword);
RecoverPassword = withRouter(RecoverPassword)
export default RecoverPassword;
