import React, {useState} from 'react';
import './UnloadedApp.scss';
import Spinner from "../../comun/Spinner/Spinner";
import {CSSTransition} from "react-transition-group";

let UnloadedApp = (props) => {
    const [inAppear, setInAppear] = useState(true);
    return (
        <CSSTransition
            classNames="unloaded-app"
            timeout={800}
            appear
            in={inAppear}
        >
            <div className="unloaded-app-load">
                <div className="unload-app-load-container">
                    <img src={process.env.PUBLIC_URL + "/logo.png"}  className="unloaded-app-load-header-logo"  alt="Logo Konecta"/>
                    <div className="unloaded-app-load-spinner">
                        <Spinner width="2em" height="2em" opacity="0.8" zIndex="-1" />
                    </div>
                </div>
            </div>
        </CSSTransition>

    )

}


export default UnloadedApp;
