import React,{useState} from "react";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { CheckSharp, NoBackpackSharp } from "@mui/icons-material";
import {Field, withFormik} from "formik";
import ButtonField from "../../sections/Lead/LeadFormComponents/ButtonField";
import { FormGroup } from "@mui/material";
import MaterialTextField from "./MaterialTextField";
import {LinearProgress, Typography, Popover} from "@mui/material"
import '../../sections/Lead/RegisterLead/RegisterLead.scss';
import Button from "@material-ui/core/Button";
import WarningIcon from '@mui/icons-material/Warning';
import * as Yup from 'yup';

export default function CheckboxField2({
                                          label, requiredFunction, arrayindex, errorFunction, index,checkedValue=() => true, id, uncheckedValue = () => false, 
                                          field: {name, onChange, onBlur},
                                          form:{values, initialValues, errors, touched, setFieldValue}, ...props}) {
    const getValue = () => {
        if (arrayindex) {
            const res = values[arrayindex][index];
            if (res) {
                return (res.interestedCheckValue.value !== 'NO');
            }
            return false;
        }
        return values[name];
        
    }

const [cardbonoError, setCardbonoError] = useState(false);
const [isLoadingImage, setIsLoadingImage] = useState(false);
const showcardbonoError = (error, loadingImage) => {
        setCardbonoError(error);
        setIsLoadingImage(loadingImage)
    }

const [anchorEl, setAnchorEl] = React.useState(null);

const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };


const handleClose = () => {
    setAnchorEl(null);
  };

const openPopover = Boolean(anchorEl);
const popoverId = openPopover ? 'simple-popover' : undefined;

const getComprobacionEmail = () =>{
    if (getValue() == true && name != "checks[4]"){
        return Yup.string().email('Debe ser un email válido').required('Obligatorio')
    }else {
        return Yup.string().email('Debe ser un email válido')
    }
}

 const getCardCheck= () => {
    if(getValue() == true && name !="checks[4]" && name != "checks[3]"){
        return(
            
            <FormGroup>
                <h2>Tarjeta producto
                <Button aria-describedby={popoverId} size="small" variant="secondary" type="button" onClick={handleClick} >
                            <WarningIcon className={'help-icon-button'} />
                </Button>
                </h2>
                        <Popover
                        id={popoverId}
                        open={openPopover}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <Typography className={'help-warning-text'}sx={{ p: 2 }}>
                            Para que el código de la imagen se procese correctamente, se debe acercar la cámara al código y evitar lo máximo posible la captura de letras.
                        </Typography>
                    </Popover>

                        <Field name= {`cardcheck${index}`}
                            label="Introduzca el código de la tarjeta"
                            labelAttr='mall'
                            component={MaterialTextField}
                            variant="outlined"
                            type="text"
                            className="form-control"
                        />

                    {cardbonoError ?
                     <div className="error-form">{cardbonoError}</div>
                     :
                     isLoadingImage ?
                     <LinearProgress />
                     :
                     null
                    }

                    <Field name= {`cardcheck${index}`}
                                    label="subir imagen"
                                    className="form-control"
                                    component={ButtonField}
                                    errorFunction={showcardbonoError}
                                    variant="outlined"
                                />
                    </FormGroup>
        )
    }
}
    return (
        <FormGroup>
        <FormControlLabel  label={label} style={{marginRight: "80px"}} control={<Checkbox  name={name} checked={getValue()} onChange={(e, value) => {
            if (value === true) {
                setFieldValue(name, checkedValue()[index-1])
                if(name == "checks[4]"){
                    setFieldValue('checks[1]', uncheckedValue()[0]);
                    setFieldValue('checks[2]', uncheckedValue()[1]);
                    setFieldValue('checks[3]', uncheckedValue()[2]);
                    requiredFunction(false);
                }
                if(name == "checks[1]" || name == "checks[2]" || name == "checks[3]"){
                    setFieldValue('checks[4]', uncheckedValue()[3]);
                    requiredFunction(true);
                }
                return;
            } 
            setFieldValue(name, uncheckedValue()[index-1]);
            requiredFunction(false);
        }
        
    
    } /> 
    } 
   
   
    />
    {getCardCheck()}
       
</FormGroup>
    
        )
}
