import {connect} from "react-redux";


let HasRole  = ({role, ...props}) => {
    const hasRole = (role) => {
        if (role === undefined) {
            return false;
        }
        let roles = role.split(',');
        roles = roles.map(r => r.toLowerCase().trim());
        return props.user.authorities.map(auth => auth.authority.toLowerCase())
            .filter((authorites) => {
            return roles.indexOf(authorites) > -1;
        }).length > 0;
    }
    if (!hasRole(role) ) {
        return null;
    }
    return (
        props.children
    )
}


const mapPropsToState = state => {
    return {user: state.user}
}

HasRole = connect(mapPropsToState, null)(HasRole);

export default HasRole;
