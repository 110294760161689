import TextField from "@material-ui/core/TextField";
import React from "react";



export default ({
                    form,
                    variant = 'outlined',
                    label,
                    field: {onChange, name, onBlur},
                    multiline = false,
                    rowsMax = 1,
                    rows = 1,
                    ...custom
                }) => {
    const fieldError = form.errors[name];
    const showError = form.touched[name] !== undefined && !!fieldError;
    return (
        <TextField
            name={name}
            variant={variant}
            label={label}
            error={showError}
            onChange={onChange}
            onBlur={onBlur}
            helperText={showError ? fieldError : false}
            value={form.values[name]}
            multiline={multiline}
            rowsMax={rowsMax}
            rows={rows}
            {...custom}
        />
    )
}
