import React, {useCallback, useEffect, useState} from "react";
import {connect} from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import Button from "@material-ui/core/Button";
import {Icon} from "../../../comun/Icon/Icon";
import Divider from "@material-ui/core/Divider";
import {openDialogAction} from "../../../comun/DialogManager/DialogManagerAction";
import "./StandList.scss";
import {List, ListActions, ListAvatar, ListContent} from "../../../comun/List/List";
import {CreateStandSicor} from "../CreateStand/CreateStandSicor";
import {deleteStand, getStands} from "../StandAction";
import Avatar from "@material-ui/core/Avatar";
import {Grid} from "../../../comun/Grid/Grid";
import {hasRole} from "../../../../auth/util";
import HasRole from "../../../../auth/components/HasRole/HasRole";
import {ManageUser} from "../../ManageUsers/ManageUsers";
import {getCommercialPromoters} from "../../CommercialPromoter/CommercialPromoterAction";

export let StandListSicor = ({onLoadStand, onStandDelete, listTitle="Centros Comerciales", onDialogOpen, dialogData, ...props}) => {
    const getInitialFilter = () => {
        let res = {page: 0, size: 10, cleaned: true};
        if (dialogData !== undefined) {
            res = Object.assign(dialogData, res);
        }

        return res;
    }
    const [stands, setStands] = useState({content: [], page: 1});
    const changeStandFilter = useCallback((data) => {
        return Object.assign({}, leadFilter, data);
    }, []);
    const changPage = (event, page) => {
        setPage(page);
        setLeadFilter(changeStandFilter({page: page- 1}));
    }
    const [page, setPage] = useState(1);

    const [leadFilter, setLeadFilter] = useState(getInitialFilter());

    const [remove,setRemove] = useState(false);

    useEffect(() => {

        onLoadStand(leadFilter).then(res => {
            setStands(res);
        });

        return () => setRemove(false);
        

    }, [leadFilter,remove]);

    const onDialogClose = () => {
        onLoadStand(leadFilter).then(res => {
            setStands(res);
        })
    }
    const createStand = () => {
        onDialogOpen({component: CreateStandSicor, data: {editting: false}, opened: true, id: "create-stand-dialog", onClose: onDialogClose
        });
    };
    const openCommercialPromoters = (stand) => {
        onDialogOpen({component: ManageUser, data: {editting: false, stand: stand.id, action: getCommercialPromoters}, opened: true, id: "stand-users-dialog", onClose: onDialogClose
        });
    }
    const editStand = (stand) => {
        onDialogOpen({component: CreateStandSicor,  data: {editting: true, id: stand, ...stand}, opened: true, id: "create-stand-dialog",
            onClose: onDialogClose
        });
    };
    const isStandOpened =  (status) => {
        return (status === 'OPENED');
    }
    const renderCity = (stand) => {
        return stand.city.name;
    }
    const renderTeamLeader = (stand) => {
        if (stand.city.teamLeader === null) {
            return null;
        }
        return <div className="list-content-list-item">
                <h4 className="list-content-list-item-header">Jefe de equipo Responsable</h4>
                <small className="list-content-list-item-subtitle">{stand.city.teamLeader.name}</small>
            </div>;
    }
    const renderCommercialPromoter = (stand) => {
        if (stand.user) {
            return <div className="list-content-list-item">
                <h4 className="list-content-list-item-header">Comercial Asociado</h4>
                <small className="list-content-list-item-subtitle">{stand.user.name}</small>
            </div>;
        }
        return null;
    }



    return (
        <div className="box">
            <h1>{listTitle}</h1>
            <HasRole role="AREA_COORDINATOR,FL_MANAGER,ADMIN">
            <Button variant="contained" color="secondary" style={{margin: "7px"}} onClick={createStand}>Añadir Centro Comercial</Button>
            </HasRole>
            {(stands.content.length === 0) ? <p>No hay ningun centro comercial para mostrar</p> : null}
            <Pagination count={stands.totalPages} variant="outlined"
                        shape="rounded" page={page} onChange={changPage} />
                {stands.content.map((stand) => {
                    return (
                        <React.Fragment key={stand.id}>
                        <List>
                            <ListAvatar>
                                <Avatar className="list-avatar-container">
                                    <Icon style={{fontSize: "1.6em", color: '#666'}}>house</Icon>
                                </Avatar>
                            </ListAvatar>
                            <ListContent>
                                <h3 className="list-header">{(isStandOpened(stand.status)) ? null : <Icon>lock</Icon>}{stand.mall}</h3>
                                <div className="list-content-list">
                                    <Grid columns="repeat(3, fit-content(200px))" className="list-content-list-columns">
                                    <div className="list-content-list-item">
                                        <h4 className="list-content-list-item-header">Código</h4>
                                        <small className="list-content-list-item-subtitle">{stand.code}</small>
                                    </div>
                                    <div className="list-content-list-item">
                                        <h4 className="list-content-list-item-header">Ciudad</h4>
                                        <small className="list-content-list-item-subtitle">{renderCity(stand)}</small>
                                    </div>
                                    {renderTeamLeader(stand)}
                                    </Grid>
                                </div>
                            </ListContent>
                            <HasRole role="AREA_COORDINATOR,FL_MANAGER,ADMIN">
                        <ListActions>

                            <div>
                                <Button onClick={() => {
                                    editStand(stand);
                                }}>
                                    <Icon>edit</Icon> EDITAR
                                </Button>
                            </div>


                            <div>
                                <Button onClick={() => {
                                    openCommercialPromoters(stand);
                                }}>
                                    <Icon>group</Icon> PROMOTORES COMERCIALES
                                </Button>
                            </div>
                            <div>
                                <Button onClick={async () => {
                                    await onStandDelete(stand.id);
                                    setRemove(true);
                                    }}>
                                    <Icon>delete</Icon> BORRAR
                                </Button>
                            </div>

                        </ListActions>
                            </HasRole>
                      </List>
                    <Divider style={{marginTop: '7px', marginBottom: '7px'}} />
                        </React.Fragment>
                    )
                })}
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadStand: async data => {
            return await getStands(data)(dispatch)
        },
        onDialogOpen: data => {
            dispatch(openDialogAction(data));
        },
        onStandDelete: async data => {
           return await deleteStand(data)(dispatch);
        }
    }
}
const mapPropsToState = state => {
    return {
        stands: state.stand,
    }
}

StandListSicor = connect(mapPropsToState, mapDispatchToProps)(StandListSicor);
