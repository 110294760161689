import React from "react";
import {LeadList} from "../LeadList/LeadList";
import {getLeads} from "../LeadAction";
import {openDialogAction} from "../../../comun/DialogManager/DialogManagerAction";
import {connect} from "react-redux";
import {getRoleForm} from "../../../../auth/util";
import {TeamLeaderLeadFilters} from "../../TeamLeader/TeamLeaderLeadFilters/TeamLeaderLeadFilters";
import {AreaCoordinatorLeadFilters} from "../../AreaCoordinator/AreaCoordinatorLeadFilters/AreaCoordinatorLeadFilters";
import {FlManagerLeadFilters} from "../../FlManager/FlManagerLeadFilters/FlManagerLeadFilters";

export let ManageLeadsFlManager = ({user, leads,...props}) => {

    return (
        <LeadList callback={getLeads}  LeadFilterComponent={FlManagerLeadFilters}/>
    )
}

const mapPropsToState = state => {
    return {
        user: state.user
    }
}

ManageLeadsFlManager = connect(mapPropsToState, null)(ManageLeadsFlManager);
