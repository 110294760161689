import axios from 'axios';
import {baseUrl} from "../../../util/utilAction";
import {UPDATE_PROFILE} from "../../../types/types";
import {loadingDispatcher} from "../../comun/Loading/loadingAction";
import {openAlertAction} from "../../comun/AlertMessage/AlertAction";


export const updateProfileAction = (data) => {
    return {
        type: UPDATE_PROFILE,
        payload: data
    }
}

export const updateProfile = (data) => {
    return (dispatch) => {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
        const response = axios.post(`${baseUrl()}user/update-profile`, data)
            .then(() => {
                dispatch(updateProfileAction(response.data));
                dispatch(loadingDispatcher({
                    isLoading: false, isError: false, isLoaded: true
                }));
                dispatch(openAlertAction({
                    id: 'error-update-profile', severity: 'success', message: "Contraseña cambiada con éxito", title: 'Éxito'
                }));
            })
            .catch(e => {
                dispatch(loadingDispatcher({
                    isLoading: false, isError: true, isLoaded: true,
                }));
                console.log(e);
                dispatch(openAlertAction({
                    id: 'error-update-profile', severity: 'error', message: e.data.errorMessage, title:'Error'
                }));
            })

    }
}
export const setActivate = (user) => {
    return async (dispatch) => {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
       return axios.get(`${baseUrl()}user/${user.id}/activate`)
            .then( () => {
                dispatch(loadingDispatcher({
                    isLoading: false, isError: false, isLoaded: true
                }));
                dispatch(openAlertAction({
                    id: 'activate-user', severity: 'success', message: "Usuario cambiado con exito", title: 'Éxito'
                }));
            })
            .catch(e => {
                dispatch(loadingDispatcher({
                    isLoading: false, isError: true, isLoaded: true,
                }));
                console.log(e);
                dispatch(openAlertAction({
                    id: 'error-activate-user', severity: 'error', message: e.data.errorMessage, title:'Error'
                }));
            })

    }
}
export const changePassword = (data) => {
    return (dispatch) => {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
        const response = axios.post(`${baseUrl()}user/recover-password`, data)
            .then(() => {
                dispatch(loadingDispatcher({
                    isLoading: false, isError: false, isLoaded: true
                }));
                dispatch(openAlertAction({
                    id: 'error-recover-password', severity: 'success', message: "Contraseña cambiada con éxito", title: 'Éxito'
                }));
            })
            .catch(e => {
                dispatch(loadingDispatcher({
                    isLoading: false, isError: true, isLoaded: true,
                }));
                dispatch(openAlertAction({
                    id: 'error-recover-password', severity: 'error', message: "Error al cambiar la contraseña", title:'Error'
                }));
            })
        return response;
    }
}
