

export class ResponseException {
    errorMessage;
    data;
    status;

    constructor(status, data, errorMessage) {
        this.status = status;
        this.data = data;
        this.errorMessage = errorMessage;
    }
}
