import React, {useEffect} from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";


export default function RenderTextField({
                                            initialValue = '', isSelected,
                                            defaultFirst=false,
                                            setInputValue, inputValue, label, options = [], getOptionLabel,
                                            onInput, onOpen, elementchange,
                                            checkedValue = () => true, variant = 'contained', uncheckedValue = () => false,
                                            field: {name, onChange, onBlur},
                                            form: {values, initialValues, errors, touched, setFieldValue}, ...props
                                        }) {
    const opts = [...options];
    useEffect(() => {
        if (defaultFirst && options.length > 1) {
            setFieldValue(name, options[1]);
            setVal(options[1]);
        }
    }, [options]);
    const [val, setVal] = React.useState(values[name]);
    const onChangeComponent = (event, newValue) => {
        setVal(newValue);
        setFieldValue(name, newValue);
        if (elementchange) {
            elementchange(newValue);
        }

    };
    const getOptionLabelComponent = (option) => {
        if (option === '') {
            return '';
        }
        if (getOptionLabel !== undefined) {
            return getOptionLabel(option);
        }
        return option;
    };
    const renderInputTextField = (params) => <TextField {...params}
                                                        label={label}
                                                        variant="outlined"
                                                        onInput={(e) => {
                                                            if (onInput) {
                                                                onInput(e, e.target.value);
                                                            }
                                                        }}

    />;
    const getOptionSelectedComponent = (option, value) => {
        if (isSelected) {
            return isSelected(option, value);
        }
        return option === value;
    };
    return (
        <React.Fragment>
            <Autocomplete value={val}
                          open={onOpen}
                          onChange={onChangeComponent}
                          id="controllable-states-demo"
                          getOptionSelected={getOptionSelectedComponent}
                          getOptionLabel={getOptionLabelComponent}
                          options={opts}
                          {...props}
                          renderInput={renderInputTextField}/>
            {errors[name] ? <FormHelperText>
                {errors[name]}
            </FormHelperText> : null}
        </React.Fragment>
    )
}
