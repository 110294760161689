import React from "react";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import Login from "./auth/components/Login/Login";
import {RequestPasswordReset} from "./auth/components/RequestPasswordReset/RequestPasswordReset";
import RecoverPassword from "./auth/components/RecoverPassword/RecoverPassword";
import PrivateRoute from "./components/comun/PrivateRoute/PrivateRoute";
import {isAuth} from "./auth/auth";
import Dashboard from "./components/structural/Dashboard/Dashboard";
import Home from "./components/sections/Home/Home";
import {ManageCommercialPromoter} from "./components/sections/CommercialPromoter/ManageCommercialPromoter";
import {ManageTeamLeader} from "./components/sections/TeamLeader/ManageTeamLeader";
import {Summary} from "./components/sections/Stats/Summary/Summary";
import {SummaryDental} from "./components/sections/Stats/Summary/SummaryDental";
import {SummarySicor} from "./components/sections/Stats/Summary/SummarySicor";

import {MyActivity} from "./components/sections/Stats/MyActivity/MyActivity";
import {StandStats} from "./components/sections/Stats/MyStands/StandStats";
import {ManageAreaCoordinator} from "./components/sections/AreaCoordinator/ManageAreaCoordinator";
import {MyLeads} from "./components/sections/Lead/MyLeads/MyLeads";
import HasRole from "./auth/components/HasRole/HasRole";
import {ManageLeadsAreaCoordinator} from "./components/sections/Lead/ManageLeads/ManageLeadsAreaCoordinator";
import {ManageLeadsTeamLeader} from "./components/sections/Lead/ManageLeads/ManageLeadsTeamLeader";
import {ManageLeadsFlManager} from "./components/sections/Lead/ManageLeads/ManageLeadsFlManager";
import {CityList} from "./components/sections/City/CityList/CityList";
import {StandList} from "./components/sections/Stand/StandList/StandList";
import {StandListDental} from "./components/sections/Stand/StandList/StandListDental";
import {StandListSicor} from "./components/sections/Stand/StandList/StandListSicor";

import {MyStandsCity} from "./components/sections/TeamLeader/MyStandsCity";
import {SuperInfo} from "./components/sections/Superior/SuperiorInfo/SuperiorInfo";
import UpdateProfile from "./components/sections/UpdateProfile/UpdateProfile";
import {RegisterLead} from "./components/sections/Lead/RegisterLead/RegisterLeadForm";
import {RegisterUser} from "./components/sections/RegisterUser/RegisterUser";
import {RegisterUserDental} from "./components/sections/RegisterUser/RegisterUserDental";
import {RegisterUserSicor} from "./components/sections/RegisterUser/RegisterUserSicor";
import {Footer} from "./components/structural/Footer/Footer";
import { RegisterLeadAdamo } from "./components/sections/Lead/RegisterLead/RegisterLeadFormAdamo";
import {RegisterLeadDental} from "./components/sections/Lead/RegisterLead/RegisterLeadDentalForm";
import {RegisterLeadSicor} from "./components/sections/Lead/RegisterLead/RegisterLeadSicorForm";

import {StandStatsDental} from "./components/sections/Stats/MyStands/StandStatsDental";
import {StandStatsSicor} from "./components/sections/Stats/MyStands/StandStatsSicor";

import {CityListDental} from "./components/sections/City/CityList/CityListDental";
import {CityListSicor} from "./components/sections/City/CityList/CityListSicor";

import {MyActivityDental} from "./components/sections/Stats/MyActivity/MyActivityDental";

import {MyActivitySicor} from "./components/sections/Stats/MyActivity/MyActivitySicor";

import {ManageClinica} from "./components/sections/CommercialPromoter/ManageClinica";
import { ZoneList } from "./components/sections/Zone/ZoneList/ZoneList";


export const Routes = () => {
    return (<Router basename={process.env.PUBLIC_URL}>
            <Switch>
                <Route path="/login">
                    <Login/>
                </Route>
                <Route path="/request-password-reset">
                    <RequestPasswordReset/>
                </Route>
                <Route path="/recover-password">
                    <RecoverPassword />
                </Route>
                <PrivateRoute path="/" exact auth={isAuth}>
                    <Dashboard>
                        <Home />
                    </Dashboard>
                </PrivateRoute>
                
                <PrivateRoute path="/manage-commercial-promoter" auth={isAuth}>
                    <Dashboard>
                        <ManageCommercialPromoter />
                    </Dashboard>
                </PrivateRoute>
                
                <PrivateRoute path="/manage-clinica" auth={isAuth}>
                    <Dashboard>
                        <ManageClinica />
                    </Dashboard>
                </PrivateRoute>
                
                <PrivateRoute path="/manage-team-leader" auth={isAuth}>
                    <Dashboard>
                        <ManageTeamLeader />
                    </Dashboard>
                </PrivateRoute>
                
                <PrivateRoute path="/stats/summary" auth={isAuth}>
                    <Dashboard>
                        <Summary />
                    </Dashboard>
                </PrivateRoute>
                
                <PrivateRoute path="/stats/summaryDental" auth={isAuth}>
                    <Dashboard>
                        <SummaryDental />
                    </Dashboard>
                </PrivateRoute>

                <PrivateRoute path="/stats/summarySicor" auth={isAuth}>
                    <Dashboard>
                        <SummarySicor />
                    </Dashboard>
                </PrivateRoute>
                
                <PrivateRoute path="/stats/activity" auth={isAuth}>
                    <Dashboard>
                        <MyActivity />
                    </Dashboard>
                </PrivateRoute>
                
                <PrivateRoute path="/stats/activityDental" auth={isAuth}>
                    <Dashboard>
                        <MyActivityDental />
                    </Dashboard>
                </PrivateRoute>

                <PrivateRoute path="/stats/activitySicor" auth={isAuth}>
                    <Dashboard>
                        <MyActivitySicor />
                    </Dashboard>
                </PrivateRoute>
                
                <PrivateRoute path="/stats/stands" auth={isAuth}>
                    <Dashboard>
                        <StandStats />
                    </Dashboard>
                </PrivateRoute>
                
                <PrivateRoute path="/stats/standsDental" auth={isAuth}>
                    <Dashboard>
                        <StandStatsDental />
                    </Dashboard>
                </PrivateRoute>

                <PrivateRoute path="/stats/standsSicor" auth={isAuth}>
                    <Dashboard>
                        <StandStatsSicor />
                    </Dashboard>
                </PrivateRoute>
                
                <PrivateRoute path="/manage-area-coordinator" auth={isAuth}>
                    <Dashboard>
                        <ManageAreaCoordinator />
                    </Dashboard>
                </PrivateRoute>
                <PrivateRoute path="/my-leads" auth={isAuth}>
                    <Dashboard>
                        <MyLeads />
                    </Dashboard>
                </PrivateRoute>
                <PrivateRoute path="/manage-leads" auth={isAuth}>
                    <Dashboard>
                        <HasRole role="AREA_COORDINATOR">
                            <ManageLeadsAreaCoordinator />
                        </HasRole>
                        <HasRole role="TEAM_LEADER">
                            <ManageLeadsTeamLeader />
                        </HasRole>
                        <HasRole role="FL_MANAGER,ADMIN">
                            <ManageLeadsFlManager />
                        </HasRole>
                    </Dashboard>
                </PrivateRoute>
                <PrivateRoute path="/cities" auth={isAuth}>
                    <Dashboard>
                        <CityList />
                    </Dashboard>
                </PrivateRoute>

                <PrivateRoute path="/zones" auth={isAuth}>
                    <Dashboard>
                        <ZoneList />
                    </Dashboard>
                </PrivateRoute>
                
                <PrivateRoute path="/citiesDental" auth={isAuth}>
                    <Dashboard>
                        <CityListDental />
                    </Dashboard>
                </PrivateRoute>

                <PrivateRoute path="/citiesSicor" auth={isAuth}>
                    <Dashboard>
                        <CityListSicor />
                    </Dashboard>
                </PrivateRoute>
                
                <PrivateRoute path="/stands" auth={isAuth}>
                    <Dashboard>
                        <StandList />
                    </Dashboard>
                </PrivateRoute>
                
                <PrivateRoute path="/standsDental" auth={isAuth}>
                    <Dashboard>
                        <StandListDental />
                    </Dashboard>
                </PrivateRoute>

                <PrivateRoute path="/standsSicor" auth={isAuth}>
                    <Dashboard>
                        <StandListSicor />
                    </Dashboard>
                </PrivateRoute>
                
                <PrivateRoute path="/my-stands-city" auth={isAuth}>
                    <Dashboard>
                        <MyStandsCity />
                    </Dashboard>
                </PrivateRoute>
                <PrivateRoute path="/superior" auth={isAuth}>
                    <Dashboard>
                        <SuperInfo />
                    </Dashboard>
                </PrivateRoute>
                <PrivateRoute path="/update-profile" auth={isAuth}>
                    <Dashboard>
                        <UpdateProfile />
                    </Dashboard>
                </PrivateRoute>
                <PrivateRoute path="/register-lead" auth={isAuth}>
                    <Dashboard>
                        <RegisterLead />
                    </Dashboard>
                </PrivateRoute>
                
                <PrivateRoute exact path="/register-lead-dental" auth={isAuth}>
                    <Dashboard>
                        <RegisterLeadDental />
                    </Dashboard>
                </PrivateRoute>
                
                <PrivateRoute path="/register-lead-dental/:gestor" auth={isAuth}>
                    <Dashboard>
                        <RegisterLeadDental />
                    </Dashboard>
                </PrivateRoute>

                <PrivateRoute path="/register-lead-sicor" auth={isAuth}>
                    <Dashboard>
                        <RegisterLeadSicor />
                    </Dashboard>
                </PrivateRoute>

                <PrivateRoute path="/register-lead-sicor/:gestor" auth={isAuth}>
                    <Dashboard>
                        <RegisterLeadSicor />
                    </Dashboard>
                </PrivateRoute>
                
                
                <PrivateRoute  path="/register-lead-adamo" auth={isAuth}>
                    <Dashboard>
                        <RegisterLeadAdamo />
                    </Dashboard>
                </PrivateRoute>
                
                
                
                <PrivateRoute path="/register-user" auth={isAuth}>
                    <Dashboard>
                        <RegisterUser />
                    </Dashboard>
                </PrivateRoute>
                <PrivateRoute path="/register-user-dental" auth={isAuth}>
                    <Dashboard>
                        <RegisterUserDental />
                    </Dashboard>
                </PrivateRoute>

                <PrivateRoute path="/register-user-sicor" auth={isAuth}>
                    <Dashboard>
                        <RegisterUserSicor />
                    </Dashboard>
                </PrivateRoute>
                <Redirect to="/" />
            </Switch>
        </Router>)
}
