import React, {useEffect, useState} from "react";
import {Field} from "formik";
import AutocompleteField from "../Forms/AutocompleteField";

export let AutocompleteUser2 = ({load,  disabled,defaultFirst=false, labelAttr = 'mall', multiple=false, options, loadParams = () => {}, onChange= () => {}, ...props}) => {
    const [isLoadingUser, setIsLoadingUser] = useState(false);
    const [users, setUsers] = useState([]);
    // useEffect(() => {
    //     if (options !== undefined) {
    //         setUsers(options);
    //     }
    // }, [setUsers, options]);
    const defaultObject = () => {
        let res = {};
        res.id = 0;
        res[labelAttr] = 'Ninguno';
        res = Object.assign(res, loadParams());

        return res;
    }
    const loadUsers = async (data) => {
        setIsLoadingUser(true);
        data = Object.assign(data, loadParams());
        if (load !== undefined) {
            const users = await load(data);
            setUsers([defaultObject(), ...users.content]);
        } else {
            setUsers([options]);
        }
        setIsLoadingUser(false);
    }
    const isSelectedTeamLeaderComponent = (value, newValue) => {
        if (value.id !== undefined && newValue.id !== undefined) {
            return value.id === newValue.id;
        }
        return value === newValue;
    };
    const onInputComponent = (e, val) => {
        if (isLoadingUser === true) {
            return;
        }
        const data = {page: 0, size: 10, ...loadParams()};
        data[labelAttr] = val || null;
        loadUsers(data);
    };
    const getOptionLabelComponent = user => {
        if (user === undefined) {
            return '';
        }
        return user[labelAttr]
    };
    React.useEffect(() => {
        const loadFn = async () => {
            await loadUsers({page: 0, size: 10, ...loadParams()})
        }
       loadFn();
    }, []);
    return (
        <Field
            options={options ? options : users}
            loading={isLoadingUser}
            defaultFirst={defaultFirst}
            isSelected={isSelectedTeamLeaderComponent}
            component={AutocompleteField}
            elementchange={onChange}
            getOptionLabel={getOptionLabelComponent}
            onInput={onInputComponent}
            multiple={multiple}
            disabled={disabled}
            value={props.value}
            {...props}
        />
    )
}
