import './Spinner.scss'
import React from 'react';

export default function Spinner({width = '80px', height = '80px', opacity= 1, zIndex = 1}, props) {


    return (
        <div className="spinner" style={{width, height, opacity}} {...props}>
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
        </div>
    )
}
