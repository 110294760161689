
import {LOADING} from "../../../types/types";




export const loadingDispatcher = (data) => {
    return {
        type: LOADING,
        payload: data
    }
}

export const setLoading = ({isLoading, isError, isLoaded}) => {
    return (dispatch) => {
        return dispatch(loadingDispatcher({isLoading, isError, isLoaded}))
    }
}
