import React from "react";
import {ManageUser} from "../ManageUsers/ManageUsers";
import {ManageUserDental} from "../ManageUsers/ManageUsersDental";
import {getClinica} from "./CommercialPromoterAction";
import {connect} from "react-redux";
import {getRoleForm, hasRole} from "../../../auth/util";
import {openDialogAction} from "../../comun/DialogManager/DialogManagerAction";
import {AssignTeamLeader} from "./AssignTeamLeader/AssignTeamLeader";


export let ManageClinica = ({dialogData, onDialogOpen, user, ...props}) => {
    const loadFilter = {};
    if (!hasRole(user, 'ADMINISTRATOR')) {
        loadFilter[getRoleForm(user)] = user.id;
    }
    const assignText = "Asignar";
    const assignAction = (userAction, callback) => {
        onDialogOpen({
            component: AssignTeamLeader, data: {parent: userAction.parent, user:userAction},
            opened: true, id: "assign-user-dialog", onClose: () => {
                callback();
            }
        });
    }
    
    if (user.campaign.name === "Vivanta" || user.campaign.name === "Vitaldent" ||user.campaign.name === "KonectaDental" ){
		  return (
		    
	        <ManageUserDental title="Gestionar Clínicas" assignText={assignText} assignAction={assignAction}
	                    loadFilter={loadFilter} action={getClinica} assignStand={true} />
	    )
    }else{
	  return (
	    
        <ManageUser title="Gestionar Promotores comerciales" assignText={assignText} assignAction={assignAction}
                    loadFilter={loadFilter} action={getClinica} assignStand={true} />
      )
    }
    
}


const mapDispatchToProps = dispatch => {
    return {
        onDialogOpen: data => {
            dispatch(openDialogAction(data));
        },
    }
}


const mapStateToProps = (state) => {
    return {user: state.user};
}
ManageClinica = connect(mapStateToProps, mapDispatchToProps)(ManageClinica);


