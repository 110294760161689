import React, {useEffect, useState} from 'react';
import {StatDateFilter} from "../StatDateFilter/StatDateFilter";
import {withFormik} from "formik";
import {AutocompleteUser} from "../../../comun/AutocompleteUser/AutocompleteUser";
import {filterCities} from "../../City/CityAction";
import * as Yup from "yup";
import {connect} from "react-redux";
import {hasRole} from "../../../../auth/util";
import {Checkbox, Divider, FormControlLabel, Table, TableBody, TableRow, Button} from "@material-ui/core";
import {getStandStat,getStandStatExcel} from "../statsAction";
import TableCell from "@material-ui/core/TableCell";
import Chip from "@material-ui/core/Chip";
import "../Summary/Summary.scss";
import {getValueOrDefault} from "../../../../util/utilCampaign";

export let StandStats = ({onStandStat, user, onStandStatExcel, ...props}) => {
    const [startInterval, setStartInterval] = useState();
    const [endInterval, setEndInterval] = useState();
    const [filterCity, setFilterCity] = useState();
    const [seeClosedStands, setSeeClosedStands] = useState(true);
    const [cities, setCities] = useState([]);
    const calculateExtraData = (data) => {
        const res = {...data};
        res.closed = res.leadsKO + res.sales;
        res.closes_percentage = Math.round(res.closed * 100 / res.totalLeads) / 100;
        res.conversion = getConversion(res);
        console.log(res.conversion)
        res.totalHoursPercentage = Math.round(res.totalLeads * 100 / res.totalhours) / 100;
        res.totalManagement = res.machine + res.agent;
        return res;
    }
    const sumatory = (s) => {
        const example = s[0];
        const res = {};
        if (s.length === 0) {
            return [];
        }

        for (let i in example) {
            if (example.hasOwnProperty(i)) {
                let auxSum = 0;
                s.forEach((l) => {
                    auxSum += l[i];
                    auxSum = Math.round(auxSum * 100) / 100;
                })
                res[i] = auxSum;
            }

        }

        res['totalHoursPercentage'] = Math.round(res['totalLeads'] * 100 / res['totalhours']) / 100;
        
        return [res];

    }
    const renderStandStatusChip = (stand) => {
        if (stand.status === 'CLOSED') {
            return <Chip className="stand-closed-chip" aria-label="Cerrado" alt="Cerrado"
                         size="small"
                         label="Cerrado"/>
        }
        return <Chip className="lead-sale-chip" aria-label="Abierto" alt="Abierto"
                     size="small"
                     label="Abierto"/>;
    }
    const estructureData = (data) => {
        const auxCities = [];
        data.forEach((d) => {
            let existsCity = auxCities.find(c => c.id === d.cityId);
            if (!existsCity) {
                auxCities.push({name: d.cityname, id: d.cityId, stands: []});
                existsCity = auxCities.find(c => c.name === d.cityname);
            }
            let existsStand = existsCity.stands.find(c => c.id === d.standId);
            if (!existsStand) {
                existsCity.stands.push({mall: d.mall, id: d.standId, stats: [], status: d.standStatus});
                existsStand = existsCity.stands.find(c => c.id === d.standId);
            }
            existsStand.stats.push(calculateExtraData(d));
        })
        setCities(auxCities);
    }

    const estructureDataExcel = (data) => {
        console.log(data);
    }

    useEffect(() => {
        let cityId = undefined;
        if (filterCity) {
            cityId = filterCity.id;
        }
        if (!startInterval || !endInterval) {
            return;
        }
        onStandStat({
            firstInterval: startInterval.moment.format('YYYY-MM-DD'),
            lastInterval: endInterval.moment.format('YYYY-MM-DD'),
            cityId: cityId
        })
            .then((a) => {
                estructureData(a);
            })
        

    }, [startInterval, endInterval, filterCity])

    const filterDataExcel = () => {
        let myCity = undefined;
        if (filterCity) {
            myCity = filterCity.id;
        }

        if (!startInterval || !endInterval) {
            return;
        }
        let myFirstInterval =  startInterval.moment.format('YYYY-MM-DD');
        let myLastInterval =   endInterval.moment.format('YYYY-MM-DD');
        
        onStandStatExcel({firstInterval: myFirstInterval, lastInterval: myLastInterval, cityId: myCity}).then(res => {
            console.log(res);
        })
           .then((a) => {
            estructureDataExcel(a);
        })
        
    }

    const campaingType = getValueOrDefault('campaign.type', user.campaign, 'event');

    const getConversion = (lead) => {
        return Math.round((lead.sales / ((lead.totalLeads === 0) ? 1 : lead.totalLeads)) * 100) / 100;
    }

    return (<div className="box">
        <h1>Estadisticas de mis Stands</h1>
        <div className="form-filters">

            <div className="form-control">
                <h3>Intervalo de Fecha</h3>
                <StatDateFilter updateFirstInterval={setStartInterval} updateSecondInterval={setEndInterval}/>
            </div>
            <div className="form-control">
                <h3>Ciudad</h3>
                <AutocompleteUser name="city" load={filterCities} onChange={(e) => {
                    if (e.name === 'Ninguno') {
                        setFilterCity(undefined);
                        return;
                    }
                    setFilterCity(e);
                }} label="Selecciona una Ciudad" loadParams={() => {
                    if (hasRole(user, 'TEAM_LEADER')) {
                        return {teamLeader: user.id}
                    }
                    return {};
                }}/>
                <FormControlLabel
                    control={<Checkbox
                        checked={seeClosedStands}
                        onChange={(e) => {
                            setSeeClosedStands(!seeClosedStands);
                        }}
                        inputProps={{'aria-label': 'primary checkbox'}}
                    />}
                    label="Ver Stands Cerrados"
                />                
                

            </div>
        </div>
        {cities.length === 0 ? <p>No hay resultados para el filtro.</p> : <h1>Ciudades</h1>}
        {cities.map((c, k) => {
            return <div key={k} className="my-stands-city">
                <h2>{c.name}
                </h2>
                <Divider/>
                {c.stands.length === 0 ? <p>No hay stands para el filtro.</p> : null}
                {c.stands.filter(s => (s.status === 'CLOSED' && seeClosedStands === true) || (s.status === 'OPENED')).map((s, k2) => {
                    return <div key={k2} className="my-stands-stand">
                        <h3>{s.mall} {renderStandStatusChip(s)}</h3>
                        <div className="my-stands-table-container">
                            <Table className="summary-stats-table">
                                <TableBody>
                                    <TableRow className="my-stands-total-results">
                                    <TableCell component="th"></TableCell>
                                    {campaingType == "kcrm"?
                                    <React.Fragment>
                                    <TableCell component="th">Total Leads</TableCell>
                                    <TableCell component="th">Leads en Gestion</TableCell>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                    <TableCell component="th">Total Leads</TableCell>
                                    <TableCell component="th">Leads OK</TableCell>
                                    <TableCell component="th">Leads KO</TableCell>
                                    </React.Fragment>
                                    }
                                    
                                    <TableCell component="th">Total Ventas</TableCell>
                                    <TableCell component="th">Leads Cerrados</TableCell>
                                    {campaingType == "kcrm"?
                                    null
                                    :
                                    <TableCell component="th">Leads Cerrados %</TableCell>
                                    }
                                    <TableCell component="th">Conversion</TableCell>

                                    {campaingType == "kcrm"?
                                    <React.Fragment>
                                        <TableCell component="th">KO: Permanencia</TableCell>
                                        <TableCell component="th">KO: Precio</TableCell>
                                        <TableCell component="th">KO: Fuera publico objetivo</TableCell>
                                        <TableCell component="th">KO: Mala experiencia</TableCell>
                                        <TableCell component="th">KO: Oferta ya contratada</TableCell>
                                        <TableCell component="th">KO: Sin motivo definido</TableCell>
                                        <TableCell component="th">KO: superado numero de contacto</TableCell>
                                        <TableCell component="th">KO: Lead erroneo</TableCell>
                                        <TableCell component="th">KO: OTROS</TableCell>
                                        <TableCell component="th">Intentando contacto</TableCell>
                                        <TableCell component="th">Agendados</TableCell>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <TableCell component="th">Leads / hora</TableCell>
                                        <TableCell component="th">Total Horas</TableCell>
                                        <TableCell component="th">KO: Telefono Erroneo</TableCell>
                                        <TableCell component="th">KO: Intentos de Contacto</TableCell>
                                        <TableCell component="th">KO: Lista Robinson</TableCell>
                                        <TableCell component="th">KO: Titular Fallecido</TableCell>
                                        <TableCell component="th">KO: Sin interes</TableCell>
                                        <TableCell component="th">KO: Imposible contacto</TableCell>
                                    </React.Fragment>
                                    }                    
                                    
                                </TableRow>
                                    {s.stats.filter((s1) => s.status === 'OPENED' && s1.status === 'ACTIVATED').map((l, k3) => {
                                            return <TableRow key={k3} className="my-stands-total-results">
                                                <TableCell component="th">
                                                    <div>{l.name}</div>
                                                </TableCell>
                                                <TableCell component="td">  {l.totalLeads} </TableCell>
                                                    {campaingType == "kcrm"?
                                                        <TableCell component="td"> {l.management} </TableCell>
                                                    :
                                                    <React.Fragment>
                                                        <TableCell component="td"> {l.ok} </TableCell>
                                                        <TableCell component="td"> {l.ko} </TableCell>
                                                    </React.Fragment>
                                                    }
                                                    <TableCell component="td"> {l.sales} </TableCell>
                                                    <TableCell component="td"> {l.closed} </TableCell>
                                                    {campaingType == "kcrm"?
                                                    null
                                                    :
                                                    <TableCell component="td"> {l.closes_percentage} </TableCell>
                                                    }
                                                    <TableCell component="td"> {l.conversion} </TableCell>
                                                    {campaingType == "kcrm"?
                                                    <React.Fragment>
                                                        
                                                        <TableCell component="td"> {l.permanence} </TableCell>
                                                        <TableCell component="td"> {l.price} </TableCell>
                                                        <TableCell component="td"> {l.outOfTarget} </TableCell>
                                                        <TableCell component="td"> {l.badExperience} </TableCell>
                                                        <TableCell component="td"> {l.offerAlreadyHired} </TableCell>
                                                        <TableCell component="td"> {l.noDefiniteReason} </TableCell>
                                                        <TableCell component="td"> {l.tries} </TableCell>
                                                        <TableCell component="td"> {l.wrongLead} </TableCell>
                                                        <TableCell component="td"> {l.robinson} </TableCell>
                                                        <TableCell component="td"> {l.machine} </TableCell>
                                                        <TableCell component="td"> {l.agent} </TableCell>
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <TableCell component="td"> {l.totalHoursPercentage} </TableCell>
                                                        <TableCell component="td"> {l.totalhours} </TableCell>
                                                        <TableCell component="td">{l.wrongPhone}</TableCell>
                                                        <TableCell component="td">{l.tries}</TableCell>
                                                        <TableCell component="td">{l.robinson}</TableCell>
                                                        <TableCell component="td">{l.deceasedTitular}</TableCell>
                                                        <TableCell component="td">{l.noInterest}</TableCell>
                                                        <TableCell component="td">{l.noContact}</TableCell>
                                                    </React.Fragment>
                                                    }
                                                </TableRow>
                                        }
                                    )}
                                    {sumatory(s.stats.filter((s1) => s.status === 'OPENED' && s1.status === 'DEACTIVATED')).map((l, k3) => {
                                            return <TableRow key={k3} className="my-stands-total-results">
                                                <TableCell component="th">
                                                    <div>Ex-Agentes</div>
                                                </TableCell>
                                                <TableCell component="td">  {l.totalLeads} </TableCell>
                                                    {campaingType == "kcrm"?
                                                        <TableCell component="td"> {l.management} </TableCell>
                                                    :
                                                    <React.Fragment>
                                                        <TableCell component="td"> {l.ok} </TableCell>
                                                        <TableCell component="td"> {l.ko} </TableCell>
                                                    </React.Fragment>
                                                    }
                                                    <TableCell component="td"> {l.sales} </TableCell>
                                                    <TableCell component="td"> {l.closed} </TableCell>
                                                    {campaingType == "kcrm"?
                                                    null
                                                    :
                                                    <TableCell component="td"> {l.closes_percentage} </TableCell>
                                                    }
                                                    <TableCell component="td"> {Math.round((l.sales / ((l.totalLeads === 0) ? 1 : l.totalLeads)) * 100) / 100} </TableCell>
                                                    {campaingType == "kcrm"?
                                                    <React.Fragment>
                                                        
                                                        <TableCell component="td"> {l.permanence} </TableCell>
                                                        <TableCell component="td"> {l.price} </TableCell>
                                                        <TableCell component="td"> {l.outOfTarget} </TableCell>
                                                        <TableCell component="td"> {l.badExperience} </TableCell>
                                                        <TableCell component="td"> {l.offerAlreadyHired} </TableCell>
                                                        <TableCell component="td"> {l.noDefiniteReason} </TableCell>
                                                        <TableCell component="td"> {l.tries} </TableCell>
                                                        <TableCell component="td"> {l.wrongLead} </TableCell>
                                                        <TableCell component="td"> {l.robinson} </TableCell>
                                                        <TableCell component="td"> {l.machine} </TableCell>
                                                        <TableCell component="td"> {l.agent} </TableCell>
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <TableCell component="td"> {l.totalHoursPercentage} </TableCell>
                                                        <TableCell component="td"> {l.totalhours} </TableCell>
                                                        <TableCell component="td">{l.wrongPhone}</TableCell>
                                                        <TableCell component="td">{l.tries}</TableCell>
                                                        <TableCell component="td">{l.robinson}</TableCell>
                                                        <TableCell component="td">{l.deceasedTitular}</TableCell>
                                                        <TableCell component="td">{l.noInterest}</TableCell>
                                                        <TableCell component="td">{l.noContact}</TableCell>
                                                    </React.Fragment>
                                                    }
                                            </TableRow>
                                        }
                                    )}
                                    {console.log(sumatory(s.stats))}
                                    {sumatory(s.stats).map((l, k) => {
                                            return <TableRow className="my-stands-total-results" key={k}>
                                                <TableCell component="th">Total
                                                </TableCell>
                                                <TableCell component="td">  {l.totalLeads} </TableCell>
                                                    {campaingType == "kcrm"?
                                                        <TableCell component="td"> {l.management} </TableCell>
                                                    :
                                                    <React.Fragment>
                                                        <TableCell component="td"> {l.ok} </TableCell>
                                                        <TableCell component="td"> {l.ko} </TableCell>
                                                    </React.Fragment>
                                                    }
                                                    <TableCell component="td"> {l.sales} </TableCell>
                                                    <TableCell component="td"> {l.closed} </TableCell>
                                                    {campaingType == "kcrm"?
                                                    null
                                                    :
                                                    <TableCell component="td"> {l.closes_percentage} </TableCell>
                                                    }
                                                    <TableCell component="td"> {getConversion(l)} </TableCell>
                                                    {campaingType == "kcrm"?
                                                    <React.Fragment>
                                                        
                                                        <TableCell component="td"> {l.permanence} </TableCell>
                                                        <TableCell component="td"> {l.price} </TableCell>
                                                        <TableCell component="td"> {l.outOfTarget} </TableCell>
                                                        <TableCell component="td"> {l.badExperience} </TableCell>
                                                        <TableCell component="td"> {l.offerAlreadyHired} </TableCell>
                                                        <TableCell component="td"> {l.noDefiniteReason} </TableCell>
                                                        <TableCell component="td"> {l.tries} </TableCell>
                                                        <TableCell component="td"> {l.wrongLead} </TableCell>
                                                        <TableCell component="td"> {l.robinson} </TableCell>
                                                        <TableCell component="td"> {l.machine} </TableCell>
                                                        <TableCell component="td"> {l.agent} </TableCell>
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <TableCell component="td"> {l.totalHoursPercentage} </TableCell>
                                                        <TableCell component="td"> {l.totalhours} </TableCell>
                                                        <TableCell component="td">{l.wrongPhone}</TableCell>
                                                        <TableCell component="td">{l.tries}</TableCell>
                                                        <TableCell component="td">{l.robinson}</TableCell>
                                                        <TableCell component="td">{l.deceasedTitular}</TableCell>
                                                        <TableCell component="td">{l.noInterest}</TableCell>
                                                        <TableCell component="td">{l.noContact}</TableCell>
                                                    </React.Fragment>
                                                    }
                                            </TableRow>
                                        }
                                    )}

                                </TableBody>
                            </Table>

                            

                        </div>

                            
                    </div>
                })}
            </div>
        })}

           {/*
            <div>
                    <Button style={{width: 'fit-content'}} size="medium"
                            variant="contained" color="secondary" type="submit" onClick={filterDataExcel}> Descargar
                    </Button>
             </div>
             */}

    </div>);
}
const schema = Yup.object().shape({});

StandStats = withFormik(
    {
        validationSchema: schema,
        mapPropsToValues: (props) => {

            return {
                stand: {id: 0, mall: 'Ninguno'}, city: {id: 0, name: 'Ninguno'},
            }
        },
        handleSubmit: async (vals, props) => {
        },
        displayName: 'Buscar Stats de Stands',
    }
)(StandStats);

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onStandStat: (data) => {
            return dispatch(getStandStat(data));
        },

        onStandStatExcel: (data) => {
            return dispatch(getStandStatExcel(data));
        }
    }
}

StandStats = connect(mapStateToProps, mapDispatchToProps)(StandStats)
