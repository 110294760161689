import React, {useState} from 'react';
import {Field, withFormik} from "formik";
import Button from "@material-ui/core/Button";
import * as Yup from "yup";
import {connect} from "react-redux";
import MaterialTextField from "../../comun/Forms/MaterialTextField";
import CheckboxField from "../../comun/Forms/CheckboxField";
import {Grid} from "../../comun/Grid/Grid";


export let ManageUserFilters = ({user, handleSubmit, manage=false, ...props}) => {
    const [teamLeaders, setTeamLeaders] = useState([]);
    const [commercialPromoters, setCommercialPromoters] = useState([]);
    const selectAreaCoordinator = value => {
        if (value === null) {
            return;
        }
        if (value.childrens) {
            setTeamLeaders(value.childrens);
        }
    }
    const selectTeamLeader = (value) => {
        if (value === null) {
            return;
        }
        if (value.childrens) {
            setCommercialPromoters(value.childrens);
        }
    }
    return(
        <React.Fragment>
            <form className="form-filters" onSubmit={handleSubmit}>
                <h3>Filtros</h3>
                <Grid columns="1fr 1fr" mobileColumns="1fr">
                    <Field className="form-filters-text" size="small" component={MaterialTextField} name="name"
                           variant="outlined" label="Nombre" />
                    <Field className="form-filters-text" size="small" component={MaterialTextField} name="username"
                           variant="outlined" label="Nombre de Usuario" />
                    <Field className="form-filters-text" size="small" component={MaterialTextField} name="dni"
                           variant="outlined" label="DNI" />
                    <Field className="form-filters-text" size="small" component={MaterialTextField} name="email"
                           variant="outlined" label="Email" />
                    <Field className="form-filters-text" size="small" component={MaterialTextField} name="code"
                           variant="outlined" label="Código" />
                    <Field className="form-filters-text" size="small" component={MaterialTextField} name="m4"
                           variant="outlined" label="Código M4" />

                    <Button  variant="contained" className="form-control-child-input-height" color="secondary" type="submit">Filtrar</Button>
                </Grid>

            </form>
        </React.Fragment>
    )
}

const schema = Yup.object().shape({
    name: Yup.string(),
    document: Yup.string(),
    email: Yup.string(),
    code: Yup.string(),
    m4: Yup.string(),
    username: Yup.string(),
});

ManageUserFilters = withFormik(
    {
        validationSchema: schema,
        mapPropsToValues: (props) => {
            return {
                name: '', document: '', email: '', code: '', m4: '', username: ''
            }
        },
        handleSubmit: async (vals, props) => {
            const data = Object.assign({}, vals);
            props.setSubmitting(false);
            props.props.onSubmit(data);
        },
        displayName: 'Filtrar Usuarios',
    }
)(ManageUserFilters);
const mapPropsToState = state => {
    return {
        user: state.user
    }
}

ManageUserFilters = connect(mapPropsToState, null)(ManageUserFilters);
