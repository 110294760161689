import React, {useState} from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Grid} from "../../../comun/Grid/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {connect} from "react-redux";
import {getRegisterLeadConfigDispatch} from "../LeadAction";
import moment from "moment";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import "./LeadDetails.scss";
import HasRole from "../../../../auth/components/HasRole/HasRole";
import Button from "@material-ui/core/Button";
import {Icon} from "../../../comun/Icon/Icon";
import {LeadDetailsPdf} from "./LeadDetailsPdf/LeadDetailsPdf";
import {LeadDetailsDentalPdf} from "./LeadDetailsPdf/LeadDetailsDentalPdf";
import {ListActions} from "../../../comun/List/List";
import InputAdornment  from '@mui/material/InputAdornment';

export let LeadDetailsDental = ({dialogData, onRegisterLeadConfig, ...props}) => {
    let lead = {};
    const [documents, setDocuments] = useState([]);
    const [clauses, setClauses] = useState([]);
    React.useEffect(() => {
        onRegisterLeadConfig().then((res) => {
            setDocuments(res.documentTypeList);
            setClauses(res.privacyClauses);
        })
    }, [setDocuments, setClauses, onRegisterLeadConfig]);
    if (dialogData) {
        lead = dialogData.lead;
        console.log("aqui");
        console.log(lead);
    }
    const getClauseValue = (clause) => {
        console.log(lead.privacyClauseValues)
        console.log(clause.id)
        if (lead.privacyClauseValues === null || !lead.privacyClauseValues.length ) {
            return false;
        }
        const findedClause =  lead.privacyClauseValues.find(p => p.privacyClause.id === clause.id);
        
        if (findedClause != undefined){
	        return findedClause.privacyClauseValue.value === 'SI';
        }else{
	        return [];
        }
        
    }
    return (
        <React.Fragment>
            <DialogTitle>Detalles de Lead</DialogTitle>
            <DialogContent>
                {clauses.length > 0 ? <HasRole role="FL_MANAGER,ADMIN,AREA_COORDINATOR">
                    <div>
                        <LeadDetailsDentalPdf lead={lead} clauses={clauses} />
                    </div>
                </HasRole> : null}

                <Grid columns={2} mobileColumns="1fr">
                
                    <div>
                        <TextField variant="outlined" label="Gestor" disabled={true}
                                   value={`${lead.gestor?? ''}`}
                                   />
                    </div>
                    <div>
                        <TextField variant="outlined" label="Nombre y apellidos" disabled={true}
                                   value={`${lead.name} ${lead.surname1} ${lead.surname2}`} />
                    </div>
                    <div>
                        <TextField variant="outlined" label="Documento" disabled={true}
                                   value={`${lead.document?? ''}`} />
                    </div>
                    
                    {/*
                    <div>
                        {documents.length === 0 ? null :
                            <FormControl variant="outlined">
                                <InputLabel id="documentTypeId">Tipo de Documento</InputLabel>
                                <Select disabled={true}
                                        labelId="documentTypeId"
                                        value={lead.documentType}
                                        label="Tipo de Documento"
                                >
                                    {documents.map((documentType) => {
                                        return <MenuItem key={documentType.name}
                                                         value={documentType.name}>{documentType.showName}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        }
                    </div>
                    */}
                     
                    <div>
                        <TextField variant="outlined" label="Paciente interesado" disabled={true}
                                   value={`${lead.pacientesInteresados?? ''}`}
                                   />
                    </div>
                    
                    {lead.namePaciente1.length > 0 && lead.surNamePaciente1.length > 0 ?
                    <div>
                        <TextField variant="outlined" label="Nombre Paciente 1" disabled={true}
                                   value={`${lead.namePaciente1} ${lead.surNamePaciente1} `} />
                    </div>
                    :null}
                    
                    {lead.namePaciente2.length > 0 && lead.surNamePaciente2.length > 0 ?
                    <div>
                        <TextField variant="outlined" label="Nombre Paciente 2" disabled={true}
                                   value={`${lead.namePaciente2} ${lead.surNamePaciente2} `} />
                    </div>
                    :null}
                    
                    {lead.namePaciente3.length > 0 && lead.surNamePaciente3.length > 0 ?
                    <div>
                        <TextField variant="outlined" label="Nombre Paciente 3" disabled={true}
                                   value={`${lead.namePaciente3} ${lead.surNamePaciente3} `} />
                    </div>
                    :null}
                    
                    {lead.namePaciente4.length > 0 && lead.surNamePaciente4.length > 0 ?
                    <div>
                        <TextField variant="outlined" label="Nombre Paciente 4" disabled={true}
                                   value={`${lead.namePaciente4} ${lead.surNamePaciente4} `} />
                    </div>
                    :null}
                    
                    {lead.namePaciente5.length > 0 && lead.surNamePaciente5.length > 0 ?
                    <div>
                        <TextField variant="outlined" label="Nombre Paciente 5" disabled={true}
                                   value={`${lead.namePaciente5} ${lead.surNamePaciente5} `} />
                    </div>
                    :null}
                    
                    {lead.namePaciente6.length > 0 && lead.surNamePaciente6.length > 0 ?
                    <div>
                        <TextField variant="outlined" label="Nombre Paciente 6" disabled={true}
                                   value={`${lead.namePaciente6} ${lead.surNamePaciente6} `} />
                    </div>
                    :null}
                    
                    {lead.namePaciente7length > 0 && lead.surNamePaciente7.length > 0 ?
                    <div>
                        <TextField variant="outlined" label="Nombre Paciente 7" disabled={true}
                                   value={`${lead.namePaciente7} ${lead.surNamePaciente7} `} />
                    </div>
                    :null}
                    
                    {lead.namePaciente8.length > 0 && lead.surNamePaciente8.length > 0 ?
                    <div>
                        <TextField variant="outlined" label="Nombre Paciente 8" disabled={true}
                                   value={`${lead.namePaciente8} ${lead.surNamePaciente8} `} />
                    </div>
                    :null}
                    
                    {lead.namePaciente9.length > 0 && lead.surNamePaciente9.length > 0 ?
                    <div>
                        <TextField variant="outlined" label="Nombre Paciente 9" disabled={true}
                                   value={`${lead.namePaciente9} ${lead.surNamePaciente9} `} />
                    </div>
                    :null}
                    
                    <div>
                        <TextField variant="outlined" label="Teléfono" disabled={true}
                                   value={`${lead.phone?? ''}`} />
                    </div>
                    
                    
                    
                    <div>
                        <TextField variant="outlined" label="Fecha de alta" disabled={true}
                                   value={`${moment(lead.signUpDate, "DD-MM-YYYY").format('DD-MM-YYYY')}`} />
                    </div>
                    <div>
                        <TextField variant="outlined" label="Hora Desde" disabled={true}
                                   value={`${lead.contactHour?? ''}`} />
                    </div>
                    <div>
                        <TextField variant="outlined" label="Hora Hasta" disabled={true}
                                   value={`${lead.contactHourLast ?? ''}`} />
                    </div>
                    
                    <div>
                        <TextField variant="outlined" label="Comercial" disabled={true}
                                   value={`${lead.user ? lead.user.name : ''}`} />
                    </div>
                    <div></div>
                </Grid>
                <div style={{marginTop: '30px'}}>
                    <TextField variant="outlined" label="Observaciones" disabled={true}
                               value={lead.observations?? ''} rows={4} multiline={true} />
                </div>
                <div>
                    <h4>Cláusulas</h4>
                    <FormGroup className="lead-details-disabled">
                        {clauses.map((clause, index) => {
                            return (<FormControlLabel key={index} label={clause.description}  control={<Checkbox  checked={getClauseValue(clause)} /> } />
                            )
                        })}
                    </FormGroup>
                </div>
                {lead.sign ?
                    <div>
                        <h2>Firma</h2>
                        <img  className="sign-image" alt="Firma del Lead" width={300} height={200} src={'data:image/png;base64,' + lead.sign} />
                    </div>
                 : null }

            </DialogContent>
        </React.Fragment>
    )
}


const mapRegisterLeadDispatchToProps = dispatch => {
    return {
        onRegisterLeadConfig: async () => {
            return await getRegisterLeadConfigDispatch()(dispatch);
        }
    }
}

LeadDetailsDental = connect(null, mapRegisterLeadDispatchToProps)(LeadDetailsDental)
