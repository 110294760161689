import {loadingDispatcher} from "../../comun/Loading/loadingAction";
import axios from "axios";
import {openAlertAction} from "../../comun/AlertMessage/AlertAction";
import {EDIT_STAND, LOAD_STAND} from "../../../types/types";
import {envLoader} from "../../../env/envLoader";
import {log} from "../../../util/utilLog";


const baseUrl = envLoader().base_url;
export const loadStandAction = values => {
    return {
        type: LOAD_STAND,
        payload: values
    }
}


export const createStandAction = values => {
    return {
        type: LOAD_STAND,
        payload: values
    }
}

export const editStandAction = values => {
    return {
        type: EDIT_STAND,
        payload: values
    }
}

export const getStandsStatus = async () => {
    return await axios.get(`${baseUrl}stand/status`).then(res => res.data.data);
}

export const filterStands = async (data) => {
    if (!data.cleaned) {
        data.cleaned = true;
    }
    log("Cargando Stands", "info");
    return await axios.post(`${baseUrl}stand/filter`, data).then(res => {
        log(`Stands Cargados, tamaño: ${JSON.stringify(res).length}`, "info");
        return res.data.data
    });
}

export const getStands = (values) => {
    return async (dispatch) =>  {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
        return await filterStands(values)
            .then( response => {
                // dispatch(loadStandAction(response));
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));
                return response;
            }).catch(reason => {
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true}));
                dispatch(openAlertAction({
                    id: 'error-register-lead', severity: 'error',
                    message: "Hubo un error al intentar cargar los stands, por favor inténtalo de nuevo", title:'Error'
                }));
            })
    }
}

export const createStand = (values) => {
    return async (dispatch) =>  {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
        return await axios.post(`${baseUrl}stand/create`, values)
            .then( async response => {
                dispatch(createStandAction(response.data.data));
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));
                await (await getStands({page: 0, size: 10}))(dispatch);
                return response;
            }).catch(reason => {
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true}));
                dispatch(openAlertAction({
                    id: 'error-register-lead', severity: 'error',
                    message: "Hubo un error al intentar crear el stand", title:'Error'
                }));
                throw Error(reason);
            })
    }
}
export const editStand = (values) => {
    return async (dispatch) =>  {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
        return await axios.post(`${baseUrl}stand/edit`, values)
            .then( async response => {
                dispatch(editStandAction(response.data.data));
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));
                await (await getStands({page: 0, size: 10}))(dispatch);
            }).catch(reason => {
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true}));
                dispatch(openAlertAction({
                    id: 'error-register-lead', severity: 'error',
                    message: "Hubo un error al intentar crear  el stand", title:'Error'
                }));
            })
    }
}

export const deleteStand = (value) => {
    return async (dispatch) =>  {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
        return await axios.get(`${baseUrl}stand/${value}/delete`)
            .then( async response => {
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));
                await (await getStands({page: 0, size: 10}))(dispatch);
            }).catch(reason => {
                console.log(reason);
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true}));
                dispatch(openAlertAction({
                    id: 'error-delete-stand', severity: 'error',
                    message: "Hubo un error al intentar borrar el stand", title:'Error'
                }));
            })
    }
}

export const assignLeadStand = (values) => {
    return async (dispatch) =>  {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
        return await axios.get(`${baseUrl}stand/${values.stand == null ? 0 : values.stand}/assign-lead/${values.lead}`).then(res => res.data.data)
            .then( response => {
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));
                return response;
            }).catch(reason => {
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true}));
                dispatch(openAlertAction({
                    id: 'error-asign-stand-lead', severity: 'error',
                    message: "Hubo un error al intentar asignar el stand al lead", title:'Error'
                }));
                throw reason;
            })
    }
}

export const assignUserStand = (values) => {
    return async (dispatch) =>  {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
        return await axios.get(`${baseUrl}stand/${values.stand}/assign-user/${values.user}`).then(res => res.data.data)
            .then( response => {
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));
                return response;
            }).catch(reason => {
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true}));
                dispatch(openAlertAction({
                    id: 'error-asign-stand-user', severity: 'error',
                    message: "Hubo un error al intentar asignar el promotor al stand", title:'Error'
                }));
                throw reason;
            })
    }
}

