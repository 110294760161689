import {CLOSE_DIALOG, OPEN_DIALOG, REMOVE_DIALOG} from "../types/types";


export  default function DialogManagerReducer (state  = {},  action) {
    let res;
    switch (action.type) {
        case OPEN_DIALOG:
            res =  Object.assign({}, state);
            res[action.payload.id] = action.payload;
            return res;
        case CLOSE_DIALOG:
            res =  Object.assign({}, state);
            res[action.payload.id].closed = true;

            return res;
        case REMOVE_DIALOG:
            res =  Object.assign({}, state);
            res[action.payload.id] = undefined;

            return res;
        default:
            return state;
    }
}
