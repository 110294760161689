import moment from "moment";

export const cachedResults = {};
let timer = null;

export const cacheResult = (key, value, data) => {
    cachedResults[key] = {value, data, time: moment()};
}
export const isCached = (key, value) => {
    if (!cachedResults[key]) {
        return false;
    }
    const val = cachedResults[key];
    const duration = moment.duration(moment().diff(val.time));
    return val.value === value;
}

export const cache = (key, value, promise) => {
    if (isCached(key, value)) {
        cachedResults[key].time = moment();
        return new Promise(resolve => {
            resolve(cachedResults[key].data);
        });
    }
    if (value === '' || !value) {
        return new Promise(resolve => {
            resolve(true);
        });
    }
    return promise(value).then((res) => {
        cacheResult(key, value, res);
        return cachedResults[key].data;
    }).catch((res) => {
        cacheResult(key, value, res);
        return cachedResults[key].data;
    });
}
