import {CREATE_STAND, EDIT_STAND, LOAD_STAND} from "../types/types";


export  default function CityReducer (state  = {content: [], totalPages: 1},  action) {
    let res;
    switch (action.type) {
        case LOAD_STAND:
            res = {...state, ...action.payload};
            return res;
        case CREATE_STAND:
            res = {...state, ...action.payload};
            return res;
        case EDIT_STAND:
            res = {...state, ...action.payload};
            return res;
        default:
            return state;
    }
}
