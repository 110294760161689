import {LOGIN, LOGIN_ERROR, LOGIN_SUCCESS} from "../types/types";


export  default function loginReducer (state  = {},  action) {
    let res;
    switch (action.type) {
        case LOGIN:
            res =  Object.assign({}, state, action.payload);
            res.error = false;
            return res;
        case LOGIN_SUCCESS:
            res =  Object.assign({}, state, action.payload);
            res.error = false;
            return res;
        case LOGIN_ERROR:
            const result =  Object.assign({}, state, action.payload);
            result.error = true;
            return result;
        default:
            return state;
    }
}
