import {CREATE_ZONE, EDIT_ZONE, LOAD_ZONES} from "../types/types";


export  default function CityReducer (state  = {content: [], totalPages: 1},  action) {
    let res;
    switch (action.type) {
        case LOAD_ZONES:
            res = {...state, ...action.payload};
            return res;
        case CREATE_ZONE:
            res = {...state, ...action.payload};
            return res;
        case EDIT_ZONE:
            res = {...state, ...action.payload};
            return res;
        default:
            return state;
    }
}
