import React, {useEffect, useRef, useState} from "react";
import {DateRangePicker} from "../../../comun/DateRangeWeekPicker/DateRangePicker";
import {getSummary, summary} from "../statsAction";
import {getSummaryExcel, summaryExcel} from "../statsAction";
import {Grid} from "../../../comun/Grid/Grid";
import "./Summary.scss";
import TextField from "@material-ui/core/TextField";
import Popper from "@material-ui/core/Popper";
import {Button, Paper, Table, TableBody, TableHead, TableRow} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import {connect} from "react-redux";
import {StatDateFilter} from "../StatDateFilter/StatDateFilter";


export let Summary = ({onGetSummaries,onGetSummariesExcel,...props}) => {
    const [summaries, setSummaries] = useState({rankingEquipoGenerado: 0, rankingEquipoConvertido: 0,
        rankingEspanaGenerados: 0, rankingEspanaConvertidos:0, ventas: 0, leadsok: 0, leadsko: 0, total: 1});

    const [summariesExcel, setSummariesExcel] = useState({rankingEquipoGenerado: 0, rankingEquipoConvertido: 0,
            rankingEspanaGenerados: 0, rankingEspanaConvertidos:0, ventas: 0, leadsok: 0, leadsko: 0, total: 1});

    const [startFilter, setStartFilter] = useState(undefined);
    const [endFilter, setEndFilter] = useState(undefined);
    const getConversion = () => {
        if (summaries.total  <= 0) {
            return 0;
        }
        return Math.round(summaries.ventas / summaries.total * 100) / 100;
    }
    useEffect(() => {
        filterData();
        //filterDataExcel();
        console.log('ererw');
    }, [startFilter, endFilter]);
    const filterData = () => {
        const firstStr = (startFilter) ? startFilter.moment.format('DD-MM-YYYY') : undefined;
        const lastStr =  (endFilter) ? endFilter.moment.format('DD-MM-YYYY') : undefined;
        onGetSummaries({first: firstStr, last: lastStr}).then(res => {
            setSummaries(res);
        })
        
    }

    const filterDataExcel = () => {
        const firstStr = (startFilter) ? startFilter.moment.format('DD-MM-YYYY') : undefined;
        const lastStr =  (endFilter) ? endFilter.moment.format('DD-MM-YYYY') : undefined;
        onGetSummariesExcel({first: firstStr, last: lastStr}).then(res => {
            //setSummaries(res);
        })
        
    }

    


    return (<div className="box">
        <h1>Resumen</h1>
        <h2>Filtros</h2>
        <div className="summary-filters">
            <Grid columns={2} mobileColumns="1fr">
                <div>
                    <StatDateFilter updateFirstInterval={setStartFilter} updateSecondInterval={setEndFilter} />
                </div>
                <Button style={{width: 'fit-content'}} size="medium"
                         variant="contained" color="secondary" type="submit" onClick={filterData}> Filtrar
                </Button>

                

            </Grid>            

        </div>
        <h2>Estadisticas</h2>
            <Table className="summary-stats-table">
            <TableBody>
                <TableRow>
                    <TableCell component="th">Total Leads</TableCell>
                    <TableCell component="td">{summaries.total} leads</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th">Total ventas</TableCell>
                    <TableCell component="td">  {summaries.ventas} ventas</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th">Conversion <small>(Ventas / Total Leads)</small></TableCell>
                    <TableCell component="td">    {getConversion()} tanto por uno</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th">Total Leads Nulos <small>(Total - Ventas)</small></TableCell>
                    <TableCell component="td">  {summaries.total - summaries.ventas} leads</TableCell>
                </TableRow>
            </TableBody>
        </Table>
        <h2>Ranking</h2>
        <Table className="summary-stats-table">
            <TableBody>
                <TableRow>
                    <TableCell component="th"></TableCell>
                    <TableCell component="th">Ranking Equipo</TableCell>
                    <TableCell component="th">Ranking España</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th">Leads Generados</TableCell>
                    <TableCell component="td">   {summaries.rankingEquipoGenerado}º</TableCell>
                    <TableCell component="td">        {summaries.rankingEspanaGenerados}º</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th">Leads Convertidos</TableCell>
                    <TableCell component="td">  {summaries.rankingEquipoConvertido}º</TableCell>
                    <TableCell component="td">    {summaries.rankingEspanaConvertidos}º</TableCell>
                </TableRow>
            </TableBody>
        </Table>
        
        {/*
        <div>
               <Button style={{width: 'fit-content'}} size="medium"
                         variant="contained" color="secondary" type="submit" onClick={filterDataExcel}> Descargar
                </Button>
        </div>
        */}
    </div>)
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetSummaries: async (data) => {
            return await getSummary(data)(dispatch);
        },
        
        onGetSummariesExcel: async (data) => {
            return await getSummaryExcel(data)(dispatch);
        }
        
    }
}

Summary = connect(null, mapDispatchToProps)(Summary);

