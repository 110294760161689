import {defaultStyles} from "./defaultStyles";
import {createContext} from "react";
import {connect} from "react-redux";
import {mergeStyles} from "../../../util/utilCampaign";
import React from "react";

export const CampaignContext = createContext(defaultStyles);

export let CampaignStyleContext = ({campaign, ...props}) => {
    return (<CampaignContext.Provider value={mergeStyles(campaign, defaultStyles)}>
            {props.children}
        </CampaignContext.Provider>
    )
}


const mapStateToProps = (state) => {
    return {campaign: state.campaign}
}

CampaignStyleContext = connect(mapStateToProps)(CampaignStyleContext);
