import React from "react";
import {StandList} from "../Stand/StandList/StandList";
import {connect} from "react-redux";
import {CityList} from "../City/CityList/CityList";


export let MyStandsCity = ({user, ...props}) => {
    const filter = {teamLeader: user.id};
    return (<React.Fragment>
        <StandList listTitle="Mis Stands" dialogData={filter} />
        <CityList dialogData={filter} listTitle="Mis Ciudades" />
    </React.Fragment>)
}

const mapStateToProps = (state) => {
    return {user: state.user};
}

MyStandsCity = connect(mapStateToProps)(MyStandsCity);
