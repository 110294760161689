import axios from "axios";
import {envLoader} from "../../../env/envLoader";
import {loadingDispatcher} from "../../comun/Loading/loadingAction";
import {openAlertAction} from "../../comun/AlertMessage/AlertAction";
import {CREATE_LEAD, LOAD_LEADS} from "../../../types/types";

const baseUrl = envLoader().base_url;

export const getRegisterLeadConfig = () => {
    return axios.get(`${baseUrl}lead/create-lead-config`);
}

export const leadAction = values => {
    return {
        type: CREATE_LEAD,
        payload: values
    }
}
export const loadLeadAction = values => {
    return {
        type: LOAD_LEADS,
        payload: values
    }
}



export const filterLeads = async (data) => {
    return await axios.post(`${baseUrl}lead/filter`, data).then(res => res.data.data);
}

export const leadsParentTeamLeader = async (data) => {
    return await axios.post(`${baseUrl}lead/parent-team-leader`, data).then(res => res.data.data);
}

export const verifyCardbonoImage = async(values) => {
    return await axios.post(`${baseUrl}lead/verify-cardbono-image`, values).then(res => res.data.data);
}

export const verifyCardCheckImage = async(values) => {
    return await axios.post(`${baseUrl}lead/verify-cardcheck-image`, values).then(res => res.data.data);
}

export const getRegisterLeadConfigDispatch = () => {
    return async (dispatch) => {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false, id:'get-register-lead-configuration'}));
        return axios.get(`${baseUrl}lead/create-lead-config`)
            .then(res => res.data.data).then(res => {
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true, id:'get-register-lead-configuration'}));
                return res;
            }).catch(reason => {
                dispatch(openAlertAction({
                    id: 'error-lead-config', severity: 'error',
                    message: "Hubo un error al intentar cargar la configuración para añadir Leads", title:'Error'
                }));
            })
    }

}

export const getLeads = (values) => {
    return async (dispatch) =>  {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false, id:'get-leads'}));
        return await filterLeads(values)
            .then( response => {
                dispatch(loadLeadAction(response));
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true, id:'get-leads'}));
                if (values.descarga){
	                getLeadsExcel(values);
                }                
            }).catch(reason => {
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true, id:'get-leads'}));
                dispatch(openAlertAction({
                    id: 'error-load-lead', severity: 'error',
                    message: "Hubo un error al intentar cargar los Leads, por favor inténtalo de nuevo", title:'Error'
                }));
            })
    }
}

export const getLeadsExcel = (values) => {
    axios.post(`${baseUrl}lead/filterExcel`, values,{ responseType: 'arraybuffer' })
            .then(res => {
                //Process the returned file stream
                const content = res.data;
                const blob = new Blob([content], { type: 'application/vnd.ms-excel;charset=utf-8' });
                
                const fileName = "informeLeads" + "." + "xls";
                if ("download" in document.createElement("a")) {
                    //Non ie Download
                    const elink = document.createElement("a");
                    elink.download = fileName;
                    elink.style.display = "none";
                    elink.href = URL.createObjectURL(blob);
                    document.body.appendChild(elink);
                    elink.click();
                    URL.revokeObjectURL(elink.href); // release the URL object
                    document.body.removeChild(elink);
                } else {
                    //Ie10 + download
                    navigator.msSaveBlob(blob, fileName);
                }
                
                return res;
            });

    //}
};



export const registerLead = (values) => {
    return async (dispatch) =>  {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
        return await axios.post(`${baseUrl}lead/register`, values)
            .then( response => {
                console.log(response);
                dispatch(leadAction(response.data.data));
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));

                if(response.data.data === 'OK'){
                    dispatch(openAlertAction({
                        id: 'register-lead', severity: 'success', message: "Se ha dado de alta el Lead con éxito", title: 'Lead dado de alta'
                    }));
                
                }else{
                    dispatch(openAlertAction({
                        id: 'register-lead', severity: 'error', 
                        message: "Tiene que seleccionar un stand, si no le aparece ninguno pongáse en contacto con el administrador", title: 'Error'
                    }));
                }
                
                return response;
            }).catch(reason => {
                console.log("Ver mensaje de error");
                console.log(reason);
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true}));

                dispatch(openAlertAction({
                        id: 'error-register-lead', severity: 'error',
                        message: "Hubo un error al intentar dar de alta el Lead, por favor inténtalo de nuevo", title:'Error'
                    }));
                
                throw reason;
            })
    }
}

export const registerLeadAdamo = (values) => {
    return async (dispatch) =>  {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
        return await axios.post(`${baseUrl}lead/register-adamo`, values)
            .then( response => {
                dispatch(leadAction(response.data.data));
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));
                dispatch(openAlertAction({
                    id: 'register-lead', severity: 'success', message: "Se ha dado de alta el Lead con éxito", title: 'Lead dado de alta'
                }));
                return response;
            }).catch(reason => {
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true}));
                dispatch(openAlertAction({
                    id: 'error-register-lead', severity: 'error',
                    message: "Hubo un error al intentar dar de alta el Lead, por favor inténtalo de nuevo", title:'Error'
                }));
                throw reason;
            })
    }
}

export const registerLeadDental = (values) => {
	
	 //console.log("lead/register-dental");
	 //console.log(values.clauses);
	 //console.log(values.clauses[27]);
	 //console.log(values.clauses[28]);
	 
	 //----------------------------------------------------------------------------------
	 //Las clausulas para las campañas dentales son la 27 y 28 en la tabla privacy_clause
	 //----------------------------------------------------------------------------------
	 
	 //console.log(values.clauses[27].privacyClauseValue.value);
	 //console.log(values.clauses[28].privacyClauseValue.value);	 
	 
    return async (dispatch) =>  {
	
	    /*
		if (values.clauses[27] == undefined){
			dispatch(openAlertAction({
                    id: 'error-register-lead', severity: 'error',
                    message: "Hay que aceptar al menos la primera cláusula de privacidad", title:'Error'
                }));
            return;
		 }else{
			 if (values.clauses[27].privacyClauseValue.value !== 'SI'){
				dispatch(openAlertAction({
                    id: 'error-register-lead', severity: 'error',
                    message: "Hay que aceptar al menos la primera cláusula de privacidad", title:'Error'
                }));
            return;
			}
		 }
		 */
		 
		 //-------------------------------------------------------------
		 //La clausula 28 se comenta, porque no debería ser obligatoria
		 //-------------------------------------------------------------
		 
		 /*
		 if (values.clauses[28] == undefined){
			dispatch(openAlertAction({
                    id: 'error-register-lead', severity: 'error',
                    message: "Hay que aceptar las cláusulas de privacidad", title:'Error'
                }));
            return;
		 }else{
			 if (values.clauses[28].privacyClauseValue.value !== 'SI'){
				dispatch(openAlertAction({
                    id: 'error-register-lead', severity: 'error',
                    message: "Hay que aceptar las cláusulas de privacidad", title:'Error'
                }));
            return;
			}
		 }
		 */
	
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));                
        return await axios.post(`${baseUrl}lead/register-dental`, values)            
            .then( response => {
                dispatch(leadAction(response.data.data));
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));
                dispatch(openAlertAction({
                    id: 'register-lead', severity: 'success', message: "Se ha dado de alta el Lead con éxito", title: 'Lead dado de alta'
                }));
                return response;
            }).catch(reason => {
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true}));
                dispatch(openAlertAction({
                    id: 'error-register-lead', severity: 'error',
                    message: "Hubo un error al intentar dar de alta el Lead, por favor inténtalo de nuevo", title:'Error'
                }));
                throw reason;
            })
    }
}



export const registerLeadSicor = (values) => {
	
   return async (dispatch) =>  {
   
   
       dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));                
       return await axios.post(`${baseUrl}lead/register-sicor`, values)            
           .then( response => {
               dispatch(leadAction(response.data.data));
               dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));
               dispatch(openAlertAction({
                   id: 'register-lead', severity: 'success', message: "Se ha dado de alta el Lead con éxito", title: 'Lead dado de alta'
               }));
               return response;
           }).catch(reason => {
               dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true}));
               dispatch(openAlertAction({
                   id: 'error-register-lead', severity: 'error',
                   message: "Hubo un error al intentar dar de alta el Lead, por favor inténtalo de nuevo", title:'Error'
               }));
               throw reason;
           })
   }
}


export const getParentTeamLeader = (values) => {
    return async (dispatch) =>  {
        dispatch(loadingDispatcher({isLoading: true, isError: false, isLoaded: false}));
        return await filterLeads(values)
            .then( response => {
                dispatch(loadLeadAction(response));
                dispatch(loadingDispatcher({isLoading: false, isError: false, isLoaded: true}));
            }).catch(reason => {
                dispatch(loadingDispatcher({isLoading: false, isError: true, isLoaded: true}));
                dispatch(openAlertAction({
                    id: 'error-load-lead', severity: 'error',
                    message: "Hubo un error al intentar cargar los Leads, por favor inténtalo de nuevo", title:'Error'
                }));
            })
    }
}
