import {EDIT_USER, USER_REGISTER} from "../types/types";


export  default function RegisterUserReducer (state  = {},  action) {
    let res;
    switch (action.type) {
        case USER_REGISTER:
            res =  Object.assign({}, state, action.payload);
            return res;
        case EDIT_USER:
            res =  Object.assign({}, state, action.payload);
            return res;
        default:
            return state;
    }
}
