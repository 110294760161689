import {envLoader} from "../env/envLoader";


export const baseUrl = () => {
    return envLoader().base_url;
}
export const sleep = (delay = 0) => {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}
