import _ from 'lodash'


export const getValueOrDefault = (key, campaign, defaultValue = undefined) => {
    if (!existsKeyInCampaign(key, campaign)) {
        return defaultValue;
    }
    if (campaign.values[key]) {
        return campaign.values[key].value;
    }
    return defaultValue;
}

export const existsKeyInCampaign = (key, campaign) => {
    if (!campaign.values) {
        return false;
    }
    return campaign.values[key] !== undefined;
}

export const getBase64ImageOrDefaultUrl = (key, campaign, defaultValue) => {
    if (existsKeyInCampaign(key, campaign)) {
        const b64Image = getValueOrDefault(key, campaign, defaultValue);
        return `data:image/png;base64, ${b64Image}`;
    }
    return defaultValue;
}

export const formatCampaignValues = (values = {})  => {
    const res = {};

    for (let i in values) {
        if (values.hasOwnProperty(i)) {
            let curr = res;
            const value = values[i];
            const aux = i.split('.');
            aux.forEach((s, k) => {
                if (!curr[s]) {
                    if (k + 1 === aux.length) {
                        curr[s] = value.value;
                    } else {
                        curr[s] = {};
                    }
                }
                curr = curr[s];
            })
        }
    }
    return res;
}

export const campaignValuesToMap = (campaign) => {
    if (campaign.values) {
        const res = {};
        const campaignValues = formatCampaignValues(campaign.values)
        return campaignValues;
    }
    return {};
}

export const mergeStyles = (campaign, defaultStyles = {}) => {
    const campaingValues = campaignValuesToMap(campaign);

    return _.merge({}, defaultStyles, campaingValues);

}

