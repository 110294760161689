import React, {useState} from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import {connect} from "react-redux";
import {Field, withFormik} from "formik";
import * as Yup from "yup";
import {createCity, editCity} from "../CityAction";
import DialogContent from "@material-ui/core/DialogContent";
import MaterialTextField from "../../../comun/Forms/MaterialTextField";
import Button from "@material-ui/core/Button";
import "./CreateCity.scss";
import {closeDialogAction} from "../../../comun/DialogManager/DialogManagerAction";
import Spinner from "../../../comun/Spinner/Spinner";
import {AutocompleteUser} from "../../../comun/AutocompleteUser/AutocompleteUser";
import {filterTeamLeaders} from "../../TeamLeader/TeamLeaderAction";
import {getRoleForm, hasRole} from "../../../../auth/util";



export let CreateCity = ({setFieldValue, user, isSubmitting, handleSubmit, dialogData, isValid, ...props}) => {
    const [loaded, setLoaded] = useState(0);
    const loadParams = () => {
        const loadFilter = {};
        if (!hasRole(user, 'ADMINISTRATOR')) {
            loadFilter[getRoleForm(user)] = user.id;
        }
        return loadFilter;
    }
    React.useEffect(  () => {
        const load = async () => {
            for(let key in dialogData){
                if (dialogData.hasOwnProperty(key)){
                    let aux =dialogData[key];
                    if (aux === null) {
                        aux = '';
                    }
                    setFieldValue(key, aux);
                }
            }
            setLoaded( 2);
        };
        load();
    }, [setLoaded, dialogData, setFieldValue]);
    if (loaded < 2 ) {
        return (<div style={{minWidth: '30vw', minHeight:'20vh'}}><h1>Cargando</h1><Spinner /></div>)
    }
    return (
        <div>
            {dialogData.editting === true ?   <DialogTitle>Editar Ciudad</DialogTitle> :
                <DialogTitle>Añadir Ciudad</DialogTitle>}
            <DialogContent>
                <form onSubmit={handleSubmit}>
                        <Field name="name"
                               label="Nombre" className="form-control" component={MaterialTextField} variant="outlined"/>
                        <Field name="locality"
                               label="Localidad" className="form-control" component={MaterialTextField} variant="outlined"/>
                        <Field name="province"
                               label="Provincia" className="form-control" component={MaterialTextField} variant="outlined"/>
                    <Field name="teamLeader"
                           label="Asignar Jefe de Equipo"
                           component={AutocompleteUser}
                           load={filterTeamLeaders}
                           loadParams={loadParams}
                           variant="outlined"
                           className="form-control"
                    />
                        <Button className="form-control"
                                variant="contained" color="secondary" type="submit" disabled={isSubmitting || !isValid}
                        >Enviar</Button>
                </form>
            </DialogContent>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        onCityRegister: async vals => {
            await (await createCity(vals))(dispatch)
        },
        onDialogClose: () => {
            dispatch(closeDialogAction({id: "create-city-dialog"}));
        },
        onCityEdit:  async vals => {
            await (await editCity(vals))(dispatch)
        },
    }
}


const schema = Yup.object().shape({
    name: Yup.string().required('Obligatorio'),
    locality: Yup.string().required('Obligatorio'),
    province: Yup.string().required('Obligatorio'),
    teamLeader: Yup.object().nullable(),
});

CreateCity = withFormik(
    {
        validationSchema: schema,
        mapPropsToValues: (props) => {

            return {
                name: '', locality: '', province: '', teamLeader: {id: 0, name: 'Ninguno'}
            }
        },
        handleSubmit: async (vals, props) => {
            props.setSubmitting(false);
            const data = Object.assign({}, vals);
            vals.id = props.props.dialogData.id;
            if (data.teamLeader){
                data.teamLeader = data.teamLeader.id;
            }
            if (props.props.dialogData.editting) {
                await props.props.onCityEdit(data).then((res) => {
                    props.resetForm();
                    props.props.onDialogClose();
                });
                return;
            }
            await props.props.onCityRegister(data).then((res) => {
                props.resetForm();
                props.props.onDialogClose();
            });
        },
        displayName: 'Crear Ciudad',
    }
)(CreateCity);

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

CreateCity = connect(mapStateToProps, mapDispatchToProps)(CreateCity);

