import React from "react";
import {ManageUser} from "../ManageUsers/ManageUsers";
import {getTeamLeaders} from "./TeamLeaderAction";
import {connect} from "react-redux";
import {getRoleForm, hasRole} from "../../../auth/util";
import {AssignAreaCoordinator} from "./AssignAreaCoordinator/AssignAreaCoordinator";
import {openDialogAction} from "../../comun/DialogManager/DialogManagerAction";

export let ManageTeamLeader = ({dialogData, onDialogOpen, user, ...props}) => {
    const loadFilter = {};
    if (hasRole(user, 'AREA_COORDINATOR')) {
        loadFilter['teamLeader'] = user.id;
    }
    const assignText = "Asignar";
    const assignAction = (userAction, callback) => {
        onDialogOpen({
            component: AssignAreaCoordinator, data: {parent: userAction.parent, user:userAction},
            opened: true, id: "assign-user-dialog", onClose: () => {
                callback();
            }
        });
    }
    return (
        <ManageUser title="Gestionar Jefes de equipo"  assignText={assignText} assignAction={assignAction}
                    loadFilter={loadFilter} action={getTeamLeaders} assignCity={true} />
    )
}
const mapDispatchToProps = dispatch => {
    return {
        onDialogOpen: data => {
            dispatch(openDialogAction(data));
        },
    }
}



const mapStateToProps = (state) => {
    return {user: state.user};
}
ManageTeamLeader = connect(mapStateToProps, mapDispatchToProps)(ManageTeamLeader);
