import {CREATE_CITY, EDIT_CITY, LOAD_CITIES} from "../types/types";


export  default function CityReducer (state  = {content: [], totalPages: 1},  action) {
    let res;
    switch (action.type) {
        case LOAD_CITIES:
            res = {...state, ...action.payload};
            return res;
        case CREATE_CITY:
            res = {...state, ...action.payload};
            return res;
        case EDIT_CITY:
            res = {...state, ...action.payload};
            return res;
        default:
            return state;
    }
}
