import React, {useState} from 'react';
import {Grid} from "../../../comun/Grid/Grid";
import {Field, withFormik} from "formik";
import MaterialTextField from "../../../comun/Forms/MaterialTextField";
import CheckboxField from "../../../comun/Forms/CheckboxField";
import Button from "@material-ui/core/Button";
import * as Yup from "yup";
import {connect} from "react-redux";
import {AutocompleteUser} from "../../../comun/AutocompleteUser/AutocompleteUser";
import {filterTeamLeaders} from "../../TeamLeader/TeamLeaderAction";
import {filterCommercialPromoters} from "../../CommercialPromoter/CommercialPromoterAction";
import {filterStands} from "../../Stand/StandAction";


export let AreaCoordinatorLeadFilters = ({user, handleSubmit, manage=false, ...props}) => {
    const [teamLeader, setTeamLeader] = useState({id: null});
    const [commercialPromoters, setCommercialPromoters] = useState([]);
    const selectTeamLeader = (value) => {
        if (value === null) {
            return;
        }
        setTeamLeader(value);
        filterCommercialPromoters({page: 0, size: 20, parent: value.id}).then(r => setCommercialPromoters([{id: 0, name: 'Ninguno'}, ...r.content]));
    }
    return(
        <React.Fragment>
            <form className="form-filters" onSubmit={handleSubmit}>
                <h3>Filtros</h3>
                <Grid columns="1fr 1fr" mobileColumns="1fr">
                    <Field className="form-filters-text" size="small" component={MaterialTextField} name="name" variant="outlined" label="Nombre completo" />
                    <Field className="form-filters-text" size="small" component={MaterialTextField} name="document" variant="outlined" label="Documento de Identidad" />
                    <Field className="form-filters-text" size="small" component={MaterialTextField} name="phone" variant="outlined" label="Teléfono" />
                    <Field className="form-filters-text" size="small" component={MaterialTextField} name="company" variant="outlined" label="Compañia" />
                    <Field name="stand"
                           label="Stand"
                           component={AutocompleteUser}
                           loadParams={() => {return {areaCoordinator: user.id, cleaned: true}}}
                           load={filterStands}
                           labelAttr="mall"
                           variant="outlined"
                           className="form-control"
                    />
                    <Field name="teamLeader"
                           label="Filtrar Jefe de Equipo"
                           component={AutocompleteUser}
                           loadParams={() => {return {teamLeader: user.id}}}
                           load={filterTeamLeaders}
                           variant="outlined"
                           onChange={selectTeamLeader}
                           className="form-control"
                    />
                    <Field name="commercialPromoter"
                           label="Filtrar Promotor Comercial"
                           component={AutocompleteUser}
                           options={commercialPromoters}
                           variant="outlined"
                           className="form-control"
                           disabled={commercialPromoters.length === 0}
                    />
                    <Field className="form-filters-text" size="small" component={CheckboxField} name="sale" variant="outlined" label="Venta" />

                    <Button  variant="contained" className="form-control-child-input-height" color="secondary" type="submit">Filtrar</Button>
                </Grid>

            </form>
        </React.Fragment>
    )
}

const schema = Yup.object().shape({
    name: Yup.string(),
    document: Yup.string(),
    phone: Yup.string(),
    company: Yup.string(),
    status: Yup.string(),
    sale: Yup.boolean(),
    teamLeader: Yup.object().nullable(),
    commercialPromoter: Yup.object().nullable(),
    stand: Yup.object().nullable()
});

AreaCoordinatorLeadFilters = withFormik(
    {
        validationSchema: schema,
        mapPropsToValues: (props) => {
            return {
                name: '', document: '', phone: '', company: '', status: '', sale: false,
                teamLeader: {id:0, name: 'Ninguno'}, commercialPromoter: {id: 0, name: 'Ninguno'},
                stand: {id: 0, mall: 'Ninguno'}
            }
        },
        handleSubmit: async (vals, props) => {
            const data = Object.assign({}, vals);
            if (data.teamLeader) {
                if (data.teamLeader.id > 0) {
                    data.teamLeader = data.teamLeader.id;
                } else {
                    data.teamLeader = null;
                }
            }
            if (data.stand) {
                if (data.stand.id > 0) {
                    data.stand = data.stand.id;
                } else {
                    data.stand = null;
                }
            }
            if (data.commercialPromoter) {
                if (data.commercialPromoter.id > 0) {
                    data.commercialPromoter = data.commercialPromoter.id;
                } else {
                    data.commercialPromoter = null;
                }
            }
            props.setSubmitting(false);
            props.props.onSubmit(data);
        },
        displayName: 'Filtrar Lead',
    }
)(AreaCoordinatorLeadFilters);
const mapPropsToState = state => {
    return {
        user: state.user
    }
}

AreaCoordinatorLeadFilters = connect(mapPropsToState, null)(AreaCoordinatorLeadFilters);
