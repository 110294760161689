import React from 'react';
import './LeftPanel.scss';
import {Icon} from "../../comun/Icon/Icon";
import {NavLink, withRouter} from "react-router-dom";
import HasRole from "../../../auth/components/HasRole/HasRole";
import {signoff} from "../../../auth/auth";
import {connect} from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItem from "@material-ui/core/ListItem";
import {getAuthoritiesShowText} from "../../../auth/util";
import {getValueOrDefault} from "../../../util/utilCampaign";

let LeftPanel = (props) =>  {
    const getNavLinkClass = path => {
        console.log(path, props.location.pathname);
        return props.location.pathname.indexOf(path) !== -1
            ? "left-panel-subpanel-active"
            : "";
    };
    const exit = $event => {
        $event.preventDefault();
        props.signoff(() => {
            props.history.push("/login");
        });
    }

    const campaingType = getValueOrDefault('campaign.type', props.user.campaign, 'event');
    
    console.log("Estamos en leftPanel.js");
    console.log(props.user.campaign);
    console.log(props.user);
    console.log(props.user.code); //gestor
    console.log(props.user.campaign.name);
       

    return (
        <div className="left-panel-container">
            <div className="left-panel">
                <div className="left-panel-user">
                    <ListItemAvatar>
                        <Avatar>
                        </Avatar>
                    </ListItemAvatar>
                    <div className="left-panel-user-info">
                        <h1>{props.user.name}</h1>
                        <h4>{getAuthoritiesShowText(props.user).join(", ")}
                        </h4>
                    </div>

                </div>

                <ul className="left-panel-menu">
                    <li>
                        <NavLink exact to="/" activeClassName="left-panel-active">
                            <Icon>home</Icon>   Inicio
                        </NavLink>
                    </li>
                                        
                    <HasRole role="TEAM_LEADER,AREA_COORDINATOR, COMMERCIAL_PROMOTER,ADMIN,FL_MANAGER">
                    <li className={getNavLinkClass("stats")}>
                    
                        
                        <HasRole role="TEAM_LEADER,COMMERCIAL_PROMOTER">
                        
                        {props.user.campaign.name === "Vivanta" || props.user.campaign.name === "Vitaldent" || props.user.campaign.name === "KonectaDental" || props.user.campaign.name === "Sicor" ?
                        (props.user.campaign.name === "Sicor"?
                        <NavLink exact to="/stats/summarySicor">
                            <Icon>bar_chart</Icon>   Estadísticas
                        </NavLink>
                        :
                        <NavLink exact to="/stats/summaryDental">
                            <Icon>bar_chart</Icon>   Estadísticas
                        </NavLink>
                        ):
                        <NavLink exact to="/stats/summary">
                            <Icon>bar_chart</Icon>   Estadísticas
                        </NavLink>
                        }
                        
                        </HasRole>
                       
                        
                        {props.user.campaign.name === "Vivanta" || props.user.campaign.name === "Vitaldent" || props.user.campaign.name === "KonectaDental" || props.user.campaign.name === "Sicor" ?
                        (props.user.campaign.name === "Sicor"?
                        <HasRole role="AREA_COORDINATOR,ADMIN,FL_MANAGER">
                        <NavLink exact to="/stats/standsSicor">
                            <Icon>bar_chart</Icon>   Estadísticas
                        </NavLink>
                    </HasRole>
                    :
                        <HasRole role="AREA_COORDINATOR,ADMIN,FL_MANAGER">
                            <NavLink exact to="/stats/standsDental">
                                <Icon>bar_chart</Icon>   Estadísticas
                            </NavLink>
                        </HasRole>
                        ):
                        <HasRole role="AREA_COORDINATOR,ADMIN,FL_MANAGER">
                            <NavLink exact to="/stats/stands">
                                <Icon>bar_chart</Icon>   Estadísticas
                            </NavLink>
                        </HasRole>
                        }
                       
                        
                        
                        <ul className="left-panel-submenu">
                            <HasRole role="COMMERCIAL_PROMOTER,TEAM_LEADER">
                            
                            {props.user.campaign.name === "Vivanta" || props.user.campaign.name === "Vitaldent" || props.user.campaign.name === "KonectaDental" || props.user.campaign.name === "Sicor" ?
                            (props.user.campaign.name === "Sicor"?
                            <li>
                                <NavLink to="/stats/summarySicor" activeClassName="left-subpanel-child-active">
                                    <Icon>view_headline</Icon>  Resumen
                                </NavLink>
                            </li>
                            :
                            <li>
                                <NavLink to="/stats/summaryDental" activeClassName="left-subpanel-child-active">
                                    <Icon>view_headline</Icon>  Resumen
                                </NavLink>
                            </li>
                            ):
                            <li>
                                <NavLink to="/stats/summary" activeClassName="left-subpanel-child-active">
                                    <Icon>view_headline</Icon>  Resumen
                                </NavLink>
                            </li>
                            }

                            
                            {props.user.campaign.name === "Vivanta" || props.user.campaign.name === "Vitaldent" || props.user.campaign.name === "KonectaDental" || props.user.campaign.name === "Sicor" ?
                            (props.user.campaign.name === "Sicor"?
                            <li>
                            <NavLink to="/stats/activitySicor" activeClassName="left-subpanel-child-active">
                                <Icon>signal_cellular_alt</Icon>  Mi actividad
                            </NavLink>
                            </li>
                            :
                            <li>
                                <NavLink to="/stats/activityDental" activeClassName="left-subpanel-child-active">
                                    <Icon>signal_cellular_alt</Icon>  Mi actividad
                                </NavLink>
                            </li>
                            ):
                            <li>
                                <NavLink to="/stats/activity" activeClassName="left-subpanel-child-active">
                                    <Icon>signal_cellular_alt</Icon>  Mi actividad
                                </NavLink>
                            </li>
                            }
                            
                            
                            </HasRole>
                            <HasRole role="TEAM_LEADER,AREA_COORDINATOR">
                            <li>
                                <NavLink to="/stats/stands" activeClassName="left-subpanel-child-active">
                                    <Icon>local_mall</Icon>  Mis Stands
                                </NavLink>
                            </li>
                            </HasRole>
                        </ul>
                        
                        
                    </li>
                    </HasRole>
                   
                    
                    {props.user.campaign.name != "Vivanta" && props.user.campaign.name != "Vitaldent"  && props.user.campaign.name != "KonectaDental" && props.user.campaign.name != "Sicor" ?
                    <HasRole role="AREA_COORDINATOR,ADMIN, FL_MANAGER">
                        <li>
                            <NavLink to="/register-user" activeClassName="left-panel-active">
                                <Icon>person_add</Icon>  Registrar Usuario
                            </NavLink>
                        </li>
                    </HasRole>
                    :

                    (props.user.campaign.name == "Sicor"?
                    <HasRole role="AREA_COORDINATOR,ADMIN, FL_MANAGER">
                        <li>
                            <NavLink to="/register-user-sicor" activeClassName="left-panel-active">
                                <Icon>person_add</Icon>  Registrar Usuario
                            </NavLink>
                        </li>
                    </HasRole>
                    :
                    <HasRole role="AREA_COORDINATOR,ADMIN, FL_MANAGER">
                        <li>
                            <NavLink to="/register-user-dental" activeClassName="left-panel-active">
                                <Icon>person_add</Icon>  Registrar Usuario
                            </NavLink>
                        </li>
                    </HasRole>
                    )}
                    
                    
                    <HasRole role="FL_MANAGER,TEAM_LEADER,COMMERCIAL_PROMOTER,CLINICA,ADMIN">
                    <li>
                        <NavLink to="/my-leads" activeClassName="left-panel-active">
                            <Icon>business_center</Icon>   Mis Leads
                        </NavLink>
                    </li>
                    </HasRole>
                    <HasRole role="TEAM_LEADER,AREA_COORDINATOR,FL_MANAGER,ADMIN">
                    
                     {props.user.campaign.name != "Vivanta" && props.user.campaign.name != "Vitaldent" && props.user.campaign.name != "KonectaDental" && props.user.campaign.name !== "Sicor" ?
                    <li>
                        <NavLink to="/manage-leads" activeClassName="left-panel-active">
                            <Icon>business_center</Icon>   Gestionar Leads
                        </NavLink>
                    </li>
                    :
                    null
                    }
                    
                    </HasRole>
                    {props.user.campaign.name === "Adamo" ?
                        <HasRole role="TEAM_LEADER,COMMERCIAL_PROMOTER,ADMIN,FL_MANAGER">
                        <li>
                            <NavLink to="/register-lead-adamo" activeClassName="left-panel-active">
                                <Icon>add</Icon>  Dar de Alta Lead Adamo
                            </NavLink>
                        </li>
                        </HasRole>
                        :
                        null
                     }
                        
                        {props.user.campaign.name != "Vivanta" && props.user.campaign.name != "Vitaldent" && props.user.campaign.name != "KonectaDental" && props.user.campaign.name != "Sicor" ?
                        <HasRole role="TEAM_LEADER,COMMERCIAL_PROMOTER,ADMIN,FL_MANAGER">
                        <li>
                            <NavLink to="/register-lead" activeClassName="left-panel-active">
                                <Icon>add</Icon>  Dar de Alta Lead
                            </NavLink>
                        </li>
                        </HasRole>
                        :
                        null
                        }
                    
                    
                    {props.user.campaign.name === "Vivanta" || props.user.campaign.name === "Vitaldent" ?
                    <HasRole role="TEAM_LEADER,COMMERCIAL_PROMOTER,ADMIN,FL_MANAGER">
                        <li>
                        {/*
                            <NavLink to="/register-lead-dental/" activeClassName="left-panel-active">
                                <Icon>add</Icon>  Dar de Alta Lead dental
                            </NavLink>
                        */}
                            
                            <NavLink 
                                                                    to={{
                                                                        pathname:"/register-lead-dental/" +  `${ props.user.code}`,
                                                                        aboutProps:{
                                                                        gestor: `${ props.user.code}`
                                                                            
                                                                        }
                                                                    }}
                                                                    exact
                                                                    >
                                                                    {" "}
                                                                    <Icon>add</Icon>  Dar de Alta Lead dental
                            </NavLink>
                            
                            
                            
                            
                        </li>
                        </HasRole>
                     :
                     null
                    }




                    {props.user.campaign.name === "Sicor"?
                    <HasRole role="TEAM_LEADER,COMMERCIAL_PROMOTER,ADMIN,FL_MANAGER">
                        <li>
                        {/*
                            <NavLink to="/register-lead-dental/" activeClassName="left-panel-active">
                                <Icon>add</Icon>  Dar de Alta Lead dental
                            </NavLink>
                        */}
                            
                            <NavLink 
                                                                    to={{
                                                                        pathname:"/register-lead-sicor/" +  `${ props.user.code}`,
                                                                       state:{
                                                                            gestor: `${ props.user.code}`
                                                                            
                                                                        }
                                                                    }}
                                                                    exact
                                                                    >
                                                                    {" "}
                                                                    <Icon>add</Icon>  Dar de Alta Lead Sicor
                            </NavLink>
                            
                        </li>
                        </HasRole>
                     :
                     null
                    } 
                            

                    {props.user.campaign.name === "KonectaDental" ?
                    <HasRole role="TEAM_LEADER,COMMERCIAL_PROMOTER,ADMIN,FL_MANAGER">
                        <li>
                            <NavLink 
                                    to={{
                                        pathname:"/register-lead-dental/" +  `${ props.user.code}`,
                                        aboutProps:{
                                        gestor: `${ props.user.code}`                                        
                                        }
                                    }}
                                    exact
                            >
                            {" "}
                            <Icon>add</Icon>  Dar de Alta Lead
                            </NavLink>                            
                        </li>
                        </HasRole>
                     :
                     null
                    }
                    
                    
                    <HasRole role="AREA_COORDINATOR,FL_MANAGER,ADMIN">
                    <li>
                        <NavLink to="/manage-commercial-promoter" activeClassName="left-panel-active">
                            <Icon>person_add</Icon>   Gestionar Promotores Comerciales
                        </NavLink>
                    </li>                                                                                
                    </HasRole>
                    
                    {props.user.campaign.name === "Vivanta" || props.user.campaign.name === "Vitaldent" || props.user.campaign.name === "KonectaDental"?
                    <HasRole role="AREA_COORDINATOR,FL_MANAGER,ADMIN">
                    <li>
                        <NavLink to="/manage-clinica" activeClassName="left-panel-active">
                            <Icon>person_add</Icon>   Gestionar Clinicas
                        </NavLink>
                    </li>                                                                                
                    </HasRole>
                    :null
                    }
                    
                    
                    <HasRole role="AREA_COORDINATOR,FL_MANAGER,ADMIN">
                    <li>
                        <NavLink to="/manage-team-leader" activeClassName="left-panel-active">
                            <Icon>person_add</Icon>   Gestionar Jefes de Equipos
                        </NavLink>
                    </li>
                    </HasRole>
                    <HasRole role="FL_MANAGER,ADMIN">
                        <li>
                            <NavLink to="/manage-area-coordinator" activeClassName="left-panel-active">
                                <Icon>person_add</Icon>     Gestionar Coordinadores de zona
                            </NavLink>
                        </li>
                    </HasRole>
                    <HasRole role="AREA_COORDINATOR,FL_MANAGER,ADMIN">
                    {props.user.campaign.name === "Vivanta" || props.user.campaign.name === "Vitaldent" || props.user.campaign.name === "KonectaDental" || props.user.campaign.name === "Sicor" ?
                    (props.user.campaign.name === "Sicor"?
                    <li>
                    <NavLink to="/citiesSicor" activeClassName="left-panel-active">
                        <Icon>location_city</Icon>   Gestionar Ciudades
                    </NavLink>
                    </li>
                    :
                    <li>
                        <NavLink to="/citiesDental" activeClassName="left-panel-active">
                            <Icon>location_city</Icon>   Gestionar Ciudades
                        </NavLink>
                    </li>
                    ):
                    <li>
                        <NavLink to="/cities" activeClassName="left-panel-active">
                            <Icon>location_city</Icon>   Gestionar Ciudades
                        </NavLink>
                    </li>
                    }
                    
                    
                    </HasRole>
                    <HasRole role="TEAM_LEADER">
                        <li>
                            <NavLink to="/my-stands-city" activeClassName="left-panel-active">
                                <Icon>add_business</Icon>  <Icon>location_city</Icon> Mis Stands y Ciudades
                            </NavLink>
                        </li>
                    </HasRole>
                    <HasRole role="AREA_COORDINATOR,FL_MANAGER,ADMIN">

                    
                     {props.user.campaign.name === "Vivanta" || props.user.campaign.name === "Vitaldent" || props.user.campaign.name === "KonectaDental" || props.user.campaign.name === "Sicor"?
                     (props.user.campaign.name === "Sicor"?
                     <li>
                        <NavLink to="/standsSicor" activeClassName="left-panel-active">
                            <Icon>add_business</Icon>     Gestionar Centros Comerciales
                        </NavLink>
                    </li>
                    :

                    <li>
                        <NavLink to="/standsDental" activeClassName="left-panel-active">
                            <Icon>add_business</Icon>     Gestionar Acciones
                        </NavLink>
                    </li>
                     ):
                    <li>
                        <NavLink to="/stands" activeClassName="left-panel-active">
                            <Icon>add_business</Icon>     Gestionar Stands
                        </NavLink>
                    </li>
                    }

                    {props.user.campaign.name === "Iberdrola"?
                    <li>
                        <NavLink to="/zones" activeClassName="left-panel-active">
                            <Icon>add_business</Icon> Gestionar Zonas
                        </NavLink>
                    </li>
                    : null}
                    
                    </HasRole>
                    {props.user.parent != null ?
                    <li>
                        <NavLink to="/superior" activeClassName="left-panel-active">
                            <Icon>supervised_user_circle</Icon> Mi superior
                        </NavLink>
                    </li>
                     : null}
                    
                     
                    <HasRole role="COMMERCIAL_PROMOTER,AREA_COORDINATOR,TEAM_LEADER,FL_MANAGER,ADMIN">
                    <li>
                        <NavLink to="/update-profile" activeClassName="left-panel-active">
                            <Icon>security</Icon> Actualizar Contraseña
                        </NavLink>
                    </li>
                    </HasRole>
                    
                    
                    <li>
                        <a href="/signoff" onClick={exit}><Icon>exit_to_app</Icon>Salir</a>
                    </li>
                </ul>
            </div>
        </div>

    )
}
const mapDispatchToProps = dispatch => {
    return {
        signoff: (callback) => {
            signoff(callback)(dispatch);
            // setLoading({isLoading: false, isError: false, isLoaded: true})(dispatch);
        },
    }
}

const mapStateToProps = state => {
    return {user: state.user}
}

LeftPanel = connect(mapStateToProps, mapDispatchToProps)(LeftPanel);
export default withRouter(LeftPanel);
