import {LOADING} from "../../../types/types";


export  default function loadingReducer (state = {}, action) {
    switch (action.type) {
        case LOADING:
            const result =  Object.assign({}, state, action.payload);
            return result;
        default:
            return state;
    }
}
