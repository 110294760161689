import React, {useEffect, useRef, useState} from "react";
import {Grid} from "../../../comun/Grid/Grid";
import TextField from "@material-ui/core/TextField";
import Popper from "@material-ui/core/Popper";
import {DateRangePicker} from "../../../comun/DateRangeWeekPicker/DateRangePicker";
import {Button} from "@material-ui/core";



export let StatDateFilter = ({updateFirstInterval =  () => {}, updateSecondInterval =  () => {}, component = DateRangePicker, setEndFilter,...props}) => {
    const [openFilter, setOpenFilter] = useState(false);
    const [first, setFirst] = useState(undefined);
    const [last, setLast] = useState(undefined);
    const [textField, setTextField] = useState('');
    const Component = component;
    const onOpenFilter = () => {
        setOpenFilter(!openFilter);
    }
    const ref = useRef();

    const onChose = (first, last) => {
        if (!first ||!last) {
            setFirst(first);
            setLast(last);
            setTextField('');
            updateFirstInterval(undefined);
            updateSecondInterval(undefined);
            return;
        }
        setFirst(first);
        setLast(last);
        updateFirstInterval(first);
        updateSecondInterval(last);
        setTextField(`${first.moment.format('DD-MM-YYYY')} - ${last.moment.format('DD-MM-YYYY')}`);
    }

    return (<React.Fragment>
            <div>
                <TextField label="Elige un intervalo de fecha"  variant="outlined" onClick={onOpenFilter}  ref={ref}
                           value={textField}/>
                <Popper open={openFilter} anchorEl={ref.current}>
                    <Component onChange={onChose} onClose={onOpenFilter} first={first} last={last}  />
                </Popper>
            </div>

    </React.Fragment>)
}
