import React, {useEffect, useState} from 'react';
import Button from "@material-ui/core/Button";
import {Icon} from "../../../../comun/Icon/Icon";
import {Document, Image, Page, pdf, StyleSheet, Text, View} from '@react-pdf/renderer'
import "./LeadDetailsPdf.scss";
import moment from "moment";


export let LeadDetailsDentalPdf = ({lead, clauses = [], ...props}) => {
    const styles = StyleSheet.create({
        page: {backgroundColor: 'white', flexDirection: "column", margin: '15pt'},
        section: {
            color: 'black', textAlign: 'center', margin: '10pt', display: "flex",
            flexDirection: "column", justifyContent: "flex-start",
            alignContent: "stretch",
            flexWrap: "nowrap",
        },
        section2: {
            color: 'black', textAlign: 'left', margin: '15pt', display: "flex",
            flexDirection: "column", justifyContent: "flex-start",
            alignContent: "stretch",
            flexWrap: "nowrap",
        },
        row: {
            display: "flex",
            textAlign: 'left',
            flexDirection: "row",
            justifyContent: "space-around",
            alignContent: "stretch",
            flexWrap: "nowrap",
            alignItems: "stretch",
            flexGrow: 0,
            flexShrink: 0,
            flexBasis: 0
        },
        cell: {
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: 0,
            alignSelf: "stretch"
        },
        content: {textAlign: 'left'},
        headline1: {fontSize: '28pt', margin: '10pt'},
        headline2: {fontSize: '16pt', margin: '5pt'},
        text: {fontSize: '10pt', margin: '5pt'},
        inlinetext: {fontSize: '10pt', margin: '5pt', display: 'inline'},
        sign: {width: '150', margin: '5pt', alignContent: 'center', alignText: 'center'},
        falseclause: {width: '10pt', height: '10pt', backgroundColor: 'white', border: '2pt solid black', color: 'black', display: 'inline'},
        trueclause: {width: '10pt', height: '10pt', backgroundColor: 'black', border: '2pt solid black', color: 'black', display: 'inline'}
    });
    const getClauseValue = (clause) => {
        if (lead.privacyClauseValues === null || !lead.privacyClauseValues.length) {
            return false;
        }
        const findedClause =  lead.privacyClauseValues.find(p => p.privacyClause.id === clause.id);
        
        if (findedClause != undefined){	        
	        return findedClause.privacyClauseValue.value === 'SI';
        }else{
	        return [];
        }
        
    }
    const MyDoc = (factory, deps) =>
        (<Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Text style={styles.headline1}>Resumen Lead {lead.document}</Text>
                    <View style={styles.row}>
                        <View style={styles.cell}>
                            <Text style={styles.headline2}>Nombre y apellidos</Text>
                            <Text style={styles.text}> {lead.name + lead.surname1 + lead.surname2}</Text>
                        </View>
                        <View style={styles.cell}>
                            <Text style={styles.headline2}>Tipo de Documento</Text>
                            <Text style={styles.text}> {lead.documentType}</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.cell}>
                            <Text style={styles.headline2}>Documento</Text>
                            <Text style={styles.text}> {lead.document}</Text>
                        </View>
                        <View style={styles.cell}>
                            <Text style={styles.headline2}>Teléfono</Text>
                            <Text style={styles.text}> {lead.phone}</Text>
                        </View>
                    </View>
                    
                    <View style={styles.row}>
                        
                        <View style={styles.cell}>
                            <Text style={styles.headline2}>Fecha de Alta</Text>
                            <Text style={styles.text}> {`${moment(lead.signUpDate, "DD-MM-YYYY").format('DD-MM-YYYY')}`}</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.cell}>
                            <Text style={styles.headline2}>Hora desde</Text>
                            <Text style={styles.text}> {lead.contactHour}</Text>
                        </View>
                        <View style={styles.cell}>
                            <Text style={styles.headline2}>Hora hasta</Text>
                            <Text style={styles.text}> {lead.contactHourLast}</Text>
                        </View>
                        
                    </View>
                    
                    <View style={styles.row}> 
                        {lead.namePaciente1 !== null ?
                        <View style={styles.cell}>
                            <Text style={styles.headline2}>Paciente interesado 1</Text>
                            <Text style={styles.text}> {lead.namePaciente1+ '  '+lead.surNamePaciente1}</Text>
                        </View>:null}
                        
                        {lead.namePaciente2 !== null ?
                        <View style={styles.cell}>
                            <Text style={styles.headline2}>Paciente interesado 2</Text>
                            <Text style={styles.text}> {lead.namePaciente2+ '  '+lead.surNamePaciente2}</Text>
                        </View>:null}
                        
                        {lead.namePaciente3 !== null ?
                        <View style={styles.cell}>
                            <Text style={styles.headline2}>Paciente interesado 3</Text>
                            <Text style={styles.text}> {lead.namePaciente3+ '  '+lead.surNamePaciente3}</Text>
                        </View>:null}
                    </View>
                    
                    <View style={styles.row}>
                    
                        {lead.namePaciente4 !== null ?                        
                        <View style={styles.cell}>
                            <Text style={styles.headline2}>Paciente interesado 4</Text>
                            <Text style={styles.text}> {lead.namePaciente4+ '  '+lead.surNamePaciente4}</Text>
                        </View>:null}
                        
                        {lead.namePaciente5 !== null ?
                        <View style={styles.cell}>
                            <Text style={styles.headline2}>Paciente interesado 5</Text>
                            <Text style={styles.text}> {lead.namePaciente5+ '  '+lead.surNamePaciente5}</Text>
                        </View>:null}
                        
                        {lead.namePaciente6 !== null ?
                        <View style={styles.cell}>
                            <Text style={styles.headline2}>Paciente interesado 6</Text>
                            <Text style={styles.text}> {lead.namePaciente6+ '  '+lead.surNamePaciente6}</Text>
                        </View>:null}
                    </View>
                    
                    <View style={styles.row}>      
                    
                        {lead.namePaciente7 !== null  ?                  
                        <View style={styles.cell}>
                            <Text style={styles.headline2}>Paciente interesado 7</Text>
                            <Text style={styles.text}> {lead.namePaciente7+ '  '+lead.surNamePaciente7}</Text>
                        </View>:null}
                        
                        {lead.namePaciente8 !== null ?
                        <View style={styles.cell}>
                            <Text style={styles.headline2}>Paciente interesado 8</Text>
                            <Text style={styles.text}> {lead.namePaciente8+ '  '+lead.surNamePaciente8}</Text>
                        </View>:null}
                        
                        {lead.namePaciente9 !== null ?
                        <View style={styles.cell}>
                            <Text style={styles.headline2}>Paciente interesado 9</Text>
                            <Text style={styles.text}> {lead.namePaciente9+ '  '+lead.surNamePaciente9}</Text>
                        </View>:null}
                    </View>
                    
                    {/*}
                    <View style={styles.row}>
                        <View style={styles.cell}>
                            <Text style={styles.headline2}>Estado</Text>
                            <Text style={styles.text}> {lead.status}</Text>
                        </View>
                        <View style={styles.cell}>
                            <Text style={styles.headline2}>Stand</Text>
                            <Text style={styles.text}>{`${lead.stand ? lead.stand.mall : ''}`}</Text>
                        </View>
                    </View>
                    */}
                    
                    <View style={styles.row}>
                        <View style={styles.cell}>
                            <Text style={styles.headline2}>Comercial</Text>
                            <Text style={styles.text}>{`${lead.user ? lead.user.name : ''}`}</Text>
                        </View>
                        
                    </View>
                </View>
                <View style={styles.section2}>
                    <Text style={styles.headline2}>Observaciones</Text>
                    <Text style={styles.text}>{lead.observations}</Text>
                    <View>
                        <Text style={styles.headline2}>Clausulas</Text>
                            {clauses.map((clause, index) => {
                                return (<View key={index}>
                                        <View>
                                            {getClauseValue(clause) ?   <Text style={styles.trueclause}></Text>
                                                :   <Text style={styles.falseclause}></Text>}
                                        </View>
                                       <View>
                                           <Text style={styles.inlinetext}>{clause.description}</Text>
                                       </View>
                                </View>
                                )
                            })}
                    </View>
                    <Text style={styles.headline2}>Firma</Text>
                    <Image src={'data:image/png;base64,' + lead.sign} style={styles.sign} />
                </View>
            </Page>
        </Document>);

    const [pdfData, setPdfData] = useState({loaded: false})
    useEffect(() => {
        ;(async () => {
            const blob = await pdf(<MyDoc/>).toBlob()
            const url = URL.createObjectURL(blob)

            setPdfData({
                loaded: true,
                url,
                blob,
            })
        })()
    }, [])

    return (
        <React.Fragment>
            <a className='lead-details-pdf-button-download' href={pdfData.url}
               download={'Lead-' + lead.document + '.pdf'}
               type="application/pdf">
                <Button color="primary" style={{margin: ' 10px'}}>
                    <Icon>picture_as_pdf</Icon>
                    DESCARGAR PDF
                </Button>
            </a>
            <div style={{clear: 'both'}}></div>
        </React.Fragment>

    )
}

